import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component, ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AuthService, CmsService, CompanyInfoService } from '../../../services';
import { translateProgrammingString } from '../../../utilities';
import { BuildingBlock } from '../buildingBlocks';
import { LayoutComponent } from '../layout.component';
import { LayoutDirective } from '../layout.directive';





interface Alert {
  type: string;
  message: string;
}

let ALERTS: Alert[] = [{
  type: 'danger',
  message: 'ACHTUNG! Texte, Bilder und alle sonstigen Inhalte müssen unbedingt auch auf dem eigenen PC abgespeichert werden. Es gibt zur Zeit noch keine Funktion zur Wiederherstellung!',
}];

@Component({
  selector: 'app-markets-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filterRHThree = false;
  @Input() reportPanelMode = false;
  @Input() public buildingBlocks: Array<BuildingBlock> = [];
  @Input() public companyName: string;
  @Input() public pageType: string;
  @Input() public blockType: string;
  @Input() public companyData: string;
  @Input() public mapping: string;
  @Input() public expanded: boolean;

  @Input() public archiveDate: any = null;
  @Input() public archiveTitle: any = null
  @Output() publishedDate = new EventEmitter<any>();
  @ViewChild(LayoutDirective, { static: true }) public layoutHost: LayoutDirective;

  public currentAdIndex = -1;
  public dropdownOpen = false;
  public userIsAdmin = false;
  public timeoutId: any;
  public bloombergTicker
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private authService: AuthService,
    private companyInfoService: CompanyInfoService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private cmsService: CmsService,
    private cdr: ChangeDetectorRef
  ) {
    this.resetAlerts();
  }

  public ngOnInit(): void {
    this.loadComponent();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (Array.isArray(changes?.buildingBlocks?.currentValue)) {
      this.buildingBlocks = changes.buildingBlocks.currentValue;
    } else if (changes?.buildingBlocks?.currentValue?.blocks) {
      this.buildingBlocks = changes.buildingBlocks.currentValue.blocks as Array<BuildingBlock> || [];
    }

    this.bloombergTicker = this.companyInfoService.getBloombergTicker(this.companyName)
    if (this.buildingBlocks) {
      this.companyInfoService.loadingBool$.pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          console.log('ERR', err);

          return EMPTY;
        }),
      ).subscribe((cmsStatus) => {
        const foundComp = cmsStatus.companiesEditing.find(item => item.bloombergTicker === this.bloombergTicker);

        if (foundComp !== undefined) {
          this.userIsAdmin = this.authService.isAllowedAction(this.bloombergTicker, "write")
            && this.authService.isAuthenticated()
            && foundComp[this.pageType].isEditing;
        }
        this.cdr.detectChanges()
      });

      this.loadComponent();
    }
  }

  loadComponent() {
    this.deleteLayout();
    this.currentAdIndex = this.buildingBlocks?.length
      ? (this.currentAdIndex + 1) % this.buildingBlocks.length
      : this.currentAdIndex;

    const generateNewBlock = (blockItem: BuildingBlock): BuildingBlock => {
      const {
        blockType,
        data,
        block_id,
        adminMode,
      } = blockItem;

      return new BuildingBlock(
        this.cmsService.selectBlockType(blockType),
        blockType,
        data,
        block_id
          ? block_id
          : null,
        adminMode,
      );
    };


    if (this.buildingBlocks?.length) {

      if (this.reportPanelMode) {
        this.sendPublishedDatetoParent()
      }

      if (this.pageType === 'valuation' && this.buildingBlocks?.length === 4 && this.buildingBlocks[1].blockType === 'dcfModel') { //attention hardcode switching block order
        let multiples = this.buildingBlocks.splice(this.buildingBlocks.length - 1, 1)[0];
        this.buildingBlocks.splice(1, 0, multiples);
        this.buildingBlocks.splice(0, 1);
      }

      for (let i = 0; i < this.buildingBlocks.length; i++) {
        let block = this.buildingBlocks[i];
        if (!this.filterBlockRHThree(i, block)) {
          if (!this.reportPanelMode || ((this.reportPanelMode && block.blockType === "editor") || (this.reportPanelMode && block.blockType === "pdf-research"))) {
            const newBlock = generateNewBlock(block);

            const componentFactory = this.componentFactoryResolver
              .resolveComponentFactory(newBlock.component) as ComponentFactory<LayoutComponent>;

            const viewContainerRef = this.layoutHost.viewContainerRef;
            const componentRef = viewContainerRef.createComponent<LayoutComponent>(componentFactory);
            newBlock.adminMode = this.userIsAdmin;

            newBlock.adminMode = this.reportPanelMode ? true : this.userIsAdmin

            if (this.archiveDate && this.archiveTitle && newBlock.blockType === "editor") {
              componentRef.instance.archiveDate = this.archiveDate
              componentRef.instance.archiveTitle = this.archiveTitle
            }

            componentRef.instance.reportPanelMode = this.reportPanelMode
            componentRef.instance.data = newBlock.data;
            componentRef.instance.block_id = newBlock.block_id;
            componentRef.instance.adminMode = newBlock.adminMode;
            componentRef.instance.blockType = newBlock.blockType;
            componentRef.instance.metaCompanyName = this.companyName;
            componentRef.instance.metaBloombergTicker = this.companyInfoService.getBloombergTicker(this.companyName);
            componentRef.instance.metaPageType = this.pageType;
            componentRef.instance.metaCompanyData = this.companyData;
            componentRef.instance.metaMapping = this.mapping;
            componentRef.instance.expanded = this.expanded;
            componentRef.instance.deleteEmitter = newBlock.deleteEmitter;
            componentRef.instance.deleteEmitter = newBlock.deleteEmitter.subscribe(event => this.listenToBlock(event));
          }
        }
      }
    }
    this.cdr.detectChanges()
  }

  filterBlockRHThree(index, block): boolean {
    if (!this.filterRHThree) {
      return false;
    } else {
      if (index === 0 && block.blockType === 'largeImage') {
        return true;
      }

      if (block.blockType === 'editor') {
        block.data['truncate'] = true;
      }

      if (this.pageType === 'investment-case') {
        if (this.expanded) {
          if (block.data.blockTag !== '#keyfacts' && block.data.blockTag !== '#KeyFigures') {
            return true;
          }else{
            return false;
          }
        } else {
          if (index === 1) {
            return false;
          } else {
            return true;
          }
        }
      }

      if (this.pageType === 'swot') {
        if (block.blockType === 'editor') {
          block.data['truncate'] = false;
          return false;
        } else {
          return true;
        }
      }

      if (this.pageType === 'valuation') {
        if (block.data.blockTag !== '#Multiples') {
          block.data['collapse'] = true;
        }
      }

      if (this.pageType === 'research') {
        if (block.blockType === 'editor' || block.blockType === 'button') {
          return false;
        } else {
          return true;
        }
      }
      if (this.pageType === 'snapshot') {
        //(block)
        if (block.blockType === 'charting' && block.data.blockTag === "#Shareholder") {
          return false;
        } else {
          return true;
        }
      }

      if (this.pageType === 'financials') {
        if (block.blockType === 'financialtable' || block.blockType === 'table-tabs') {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  sendPublishedDatetoParent() {
    //  as pubished date the data.date from the "latest pdf upload button" is used
    let publishedDateBlock = this.buildingBlocks.find(block => block.blockType === "button")
    if (publishedDateBlock) {
      //console.log("button date ", publishedDateBlock.data.date)

      this.publishedDate.emit(publishedDateBlock.data.date)
    }
  }


  addNewBlock(blockType: string) {
    const part = this.cmsService.createNewBlock(blockType, "#" + blockType);
    const newPart = this.checkIfBloombergTickerIsGiven(part);

    this.buildingBlocks.push(newPart);
    this.companyInfoService.addNewBlock(this.bloombergTicker, this.pageType, blockType, newPart).then(() => {
      clearTimeout(this.timeoutId);

      this.loadNewComponent().then(() => {
        this.timeoutId = setTimeout(() => { }, 300);
      });
    });
  }

  checkIfBloombergTickerIsGiven(part: BuildingBlock): BuildingBlock {
    if (part.data.bloombergTicker !== undefined && part.data.bloombergTicker !== null) {
      if (part.data.bloombergTicker === 'Eingabe erforderlich') {
        this.activatedRoute.params.pipe(
          takeUntil(this.unsubscribe$),
        ).subscribe(params => {
          if (params.companyName !== undefined && params.companyName !== null) {
            part.data.bloombergTicker = this.companyInfoService.getBloombergTicker(params.companyName);
          } else {
            part.data.bloombergTicker = this.companyInfoService.getBloombergTicker(this.companyName);
          }

        });
      }
    }
    this.cdr.detectChanges()
    return part;
  }

  loadNewComponent(): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.buildingBlocks[this.buildingBlocks.length - 1] = new BuildingBlock(
        this.cmsService.selectBlockType(this.buildingBlocks[this.buildingBlocks.length - 1].blockType),
        this.buildingBlocks[this.buildingBlocks.length - 1].blockType,
        this.buildingBlocks[this.buildingBlocks.length - 1].data,
        this.buildingBlocks[this.buildingBlocks.length - 1].block_id
          ? this.buildingBlocks[this.buildingBlocks.length - 1].block_id
          : null,
        this.buildingBlocks[this.buildingBlocks.length - 1].adminMode,
      );
      const componentFactory = this.componentFactoryResolver
        .resolveComponentFactory(this.buildingBlocks[this.buildingBlocks.length - 1].component) as ComponentFactory<LayoutComponent>;
      const viewContainerRef = this.layoutHost.viewContainerRef;
      const componentRef = viewContainerRef.createComponent<LayoutComponent>(componentFactory);

      this.buildingBlocks[this.buildingBlocks.length - 1].adminMode = this.userIsAdmin;

      componentRef.instance.data = this.buildingBlocks[this.buildingBlocks.length - 1].data;
      componentRef.instance.block_id = this.buildingBlocks[this.buildingBlocks.length - 1].block_id;
      componentRef.instance.adminMode = this.buildingBlocks[this.buildingBlocks.length - 1].adminMode;
      componentRef.instance.blockType = this.buildingBlocks[this.buildingBlocks.length - 1].blockType;
      componentRef.instance.metaCompanyName = this.companyName;
      componentRef.instance.metaBloombergTicker = this.companyInfoService.getBloombergTicker(this.companyName);
      componentRef.instance.metaPageType = this.pageType;

      componentRef.instance.deleteEmitter = this.buildingBlocks[this.buildingBlocks.length - 1].deleteEmitter;
      componentRef.instance.deleteEmitter =
        this.buildingBlocks[this.buildingBlocks.length - 1].deleteEmitter
          .subscribe(event => this.listenToBlock(event));
      this.cdr.detectChanges()
      resolve(true);
    });
  }

  close(alert: Alert) {
    ALERTS.splice(ALERTS.indexOf(alert), 1);
  }

  resetAlerts() {
    ALERTS = Array.from(ALERTS);
  }

  saveNewOrderedParts() {
    this.companyInfoService.saveNewOrderOfParts(this.bloombergTicker, this.pageType, this.buildingBlocks).then(() => {
      clearTimeout(this.timeoutId);
      this.loadComponent();
      this.timeoutId = setTimeout(() => { }, 200);
    });
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.buildingBlocks, event.previousIndex, event.currentIndex);
    this.cdr.detectChanges()
  }

  deleteLayout(): void {
    this.layoutHost.resetLayout();
  }

  listenToBlock(event): void {
    if (event.blockId !== undefined && event.delete === true) {
      this.deleteBlock(event);
    } else if (event.blockId !== undefined && event.delete === false && event.reload === true) {
      this.loadComponent();
    }
  }

  deleteBlock(event): void {
    if (event.blockId !== undefined && event.delete === true) {
      const index = this.buildingBlocks.findIndex(block => block.block_id === event.blockId);
      this.buildingBlocks.splice(index, 1);
      this.companyInfoService.deleteBlock(this.bloombergTicker, this.pageType, event.blockId).then(() => {
        this.loadComponent();
      });
    }
  }

  translate(s: string): string {
    return translateProgrammingString(s);
  }
}
