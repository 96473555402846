<ng-template #noScoreBadData>
  <td>
    <div class="py-2">n/a</div>
    </td>
</ng-template>
<ng-template #noMeterBadData>
  <span style="color:#145389 !important">
    n/a</span>
</ng-template>
<div class="col-md-12 col-12 border esgScoreBorder rounded pt-4 mt-5 mb-4">
  <div>
    <strong>Sustainability:
      <span style="color:#145389 !important"
        *ngIf="stock.rating.total !==undefined && stock.rating.total !==null && stock.rating.total !== 11.0177; else noMeterBadData">
        {{stock.rating.total | number: '1.0-0' }} / 100</span>
  
    </strong>
    <div class="score-progress ">
      <meter name="gesamtBewertung" value="{{stock.rating.total || -120 }}" min="-120" low="-1" optimum="100" high="66"
        max="120"></meter>
  </div>
    <table class="table table-sm table-responsive score-table">
      <tbody>

        <tr>
          <th scope="row" class="contentTooltip"><div class="py-2">Social-Score</div></th>
          <td *ngIf="stock.rating.Social !==undefined && stock.rating.Social !==null; else noScoreBadData">
            <div class="py-2">{{stock.rating.Social | number: '1.0-0' }}</div>
          </td>
        </tr>
        <!-- <tr>
          <th scope="row" class="contentTooltip">Tax rate</th>

          <td style="white-space: nowrap;"
            *ngIf="stock.rating.taxRate !==undefined && stock.rating.taxRate !==null; else noScoreBadData">
            {{stock.rating.taxRate | number: '1.1-1' }} %</td>
        </tr> -->
        <tr>
          <th scope="row" class="contentTooltip"><div class="py-2">Environment-Score</div></th>
          <td class="contentTooltip"
            *ngIf="stock.rating.Environment !==undefined && stock.rating.Environment !==null; else noScoreBadData">
            <div class="py-2">{{stock.rating.Environment | number: '1.0-0' }}</div>
          </td>
        </tr>
        <!-- <tr>
          <th scope="row" class="contentTooltip">Emission reduction</th>

          <td style="white-space: nowrap;"
            *ngIf="stock.rating.emissionReduction !==undefined && stock.rating.emissionReduction !==null; else noScoreBadData">
            {{stock.rating.emissionReduction | number: '1.1-1' }}
            % p.a.
          </td>
        </tr> -->
        <tr>
          <th scope="row" class="contentTooltip"><div class="py-2">Governance-Score</div></th>
          <td class="contentTooltip"
            *ngIf="stock.rating.Governance !==undefined && stock.rating.Governance !==null; else noScoreBadData">
            <div class="py-2">{{stock.rating.Governance | number: '1.0-0' }}</div></td>
        </tr>
        <!-- <tr>
          <th scope="row" class="contentTooltip">CEO-compensation vs. Cashflow</th>
          <td style="white-space: nowrap;"
            *ngIf="stock.rating.ceoPayRatio !==undefined && stock.rating.ceoPayRatio !==null; else noScoreBadData">
            {{stock.rating.ceoPayRatio | number: '1.0-0' }} x
          </td>
        </tr> -->


      </tbody>
    </table>
  </div>
</div>
