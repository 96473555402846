<div class="row">
  <div class="col-10"><h5>Attention: </h5> </div>
  <div class="col2 fingerFix"><span (click)="activeModal.dismiss('Cross click')"><strong>X</strong></span></div>
</div>

<p>Another user is currently changing contents on this site or has not ended the change mode correctly. 
  If you change content now, changes of the other user might be lost.
</p>
<button class="btn  blockButton btn-std" (click)="activeModal.dismiss('ok')">Start change mode</button>

<button class="ml-2 btn blockButton  btn-std" (click)="activeModal.dismiss('deny')">Cancel</button>



