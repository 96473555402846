import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { leewayAPI_URL } from '../app-settings/api-urls';

@Injectable({
  providedIn: 'root'
})
export class UserHandlingService {

  constructor(private httpClient: HttpClient) { }
  private readonly customerId = '5eed24ff4fe2bd337ccab5d3';
  private readonly apiToken = 'cqt1bii3tq5fuzo0c5id';

  sendSubscriptionRequest(companies, userData, userMail, emailMode): Promise<any> {



    let endpoint = ""

    let body = {
      userMail,
      userData: userData
    }
    if (emailMode === "subscription") { endpoint = "newsubscription", body["companies"] = companies }
    else if (emailMode === "companyintro") { endpoint = "newcompanyintro", body["message"] = companies }
    else if (emailMode === "requestcoverage") { endpoint = "requestcoverage", body["message"] = companies }
    else if (emailMode === "subscribetoevent") { endpoint = "subscribetoevent", body["events"] = companies }


    const url = leewayAPI_URL+'tools/' + endpoint + '?apitoken=' + this.apiToken + '&customerId=' + this.customerId;



    return this.httpClient.post(url, body).toPromise();

  }

}
