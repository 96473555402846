import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '../app-routing.module';
import { CmsModule } from '../cms/cms.module';
import { SharedModule } from '../sharedModule/shared.module';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { BackupdownloaderComponent } from './modals/backupdownloader/backupdownloader.component';
import { CreateCompanyComponent } from './modals/create-company/create-company.component';
import { CreateEventComponent } from './modals/create-event/create-event.component';
import { NewsAllComponent } from './modals/news-all/news-all.component';
import { RatingHistoryComponent } from './modals/rating-history/rating-history.component';
import { SaveNewsletterFormComponent } from './modals/save-newsletter-form/save-newsletter-form.component';
import { TvChartComponent } from './modals/tv-chart/tv-chart.component';
import { CPageComponent } from './pages/c-page/c-page.component';
import { EditorHeaderComponent } from './pages/editor-header/editor-header.component';
import { ReportPanelComponent } from './report-panel/report-panel.component';
import { CompanyGridComponent } from './sharedParts/company-grid/company-grid.component';
import { CompanyListComponent } from './sharedParts/company-list/company-list.component';
import { CompanySliderComponent } from './sharedParts/company-slider/company-slider.component';
import { EventArchiveComponent } from './sharedParts/event-archive/event-archive.component';
import { EventGridComponent } from './sharedParts/event-grid/event-grid.component';
import { LargeTileGridComponent } from './sharedParts/large-tile-grid/large-tile-grid.component';
import { NavigationSidePanelComponent } from './sharedParts/navigation-side-panel/navigation-side-panel.component';
import { SubPageSelectorComponent } from './sharedParts/subPageSelector/subpage-selector.component';
import { SubscribeBannerComponent } from './sharedParts/subscribe-banner/subscribe-banner.component';
import { TileWrapperGridComponent } from './sharedParts/tiles/tile-wrapper-grid/tile-wrapper-grid.component';
import { TileComponent } from './sharedParts/tiles/tile/tile.component';
import { FigureGraphComponent } from './sharedParts/tiles/tileparts/figure-graph/figure-graph.component';
import { FigureSliderComponent } from './sharedParts/tiles/tileparts/figure-slider/figure-slider.component';
import { SidebarsComponent } from './sharedParts/tiles/tileparts/sidebars/sidebars.component';
import { TableComponent } from './sharedParts/tiles/tileparts/table/table.component';
import { VerticalBarsComponent } from './sharedParts/tiles/tileparts/vertical-bars/vertical-bars.component';
import { ShareholdersDiagramComponent } from './modals/shareholders-diagram/shareholders-diagram.component';
import { HorizontalNavPanelComponent } from './sharedParts/horizontal-nav-panel/horizontal-nav-panel.component';
import { FigureComponent } from './sharedParts/tiles/tileparts/figure/figure.component';
import { BookAMeetingComponent } from './modals/book-a-meeting/book-a-meeting.component';
import { TableChangeComponent } from './sharedParts/tiles/tileparts/table-change/table-change.component';
import { TemplateChartComponent } from './sharedParts/tiles/tileparts/template-chart/template-chart.component';
import { TableSliderComponent } from './sharedParts/tiles/tileparts/table-slider/table-slider.component';
import { TextComponent } from './sharedParts/tiles/tileparts/text/text.component';
import { EventListLpComponent } from './sharedParts/event-list-lp/event-list-lp.component';
import { RouterModule } from '@angular/router';
import { AdminPanelSettingsModalComponent } from './modals/admin-panel-settings-modal/admin-panel-settings-modal.component';


let declaredComponents = [
  CPageComponent,
  CompanyListComponent,
  CompanyGridComponent,
  SubPageSelectorComponent,
  AdminPanelComponent,
  EditorHeaderComponent,
  CreateEventComponent,
  SubscribeBannerComponent,
  SaveNewsletterFormComponent,
  EventGridComponent,
  BackupdownloaderComponent,
  EventArchiveComponent,
  TileWrapperGridComponent,
  TileComponent,
  EventListLpComponent
]

@NgModule({
  declarations: [
    ...declaredComponents,
    CreateCompanyComponent,
    ReportPanelComponent,
    CompanySliderComponent,
    LargeTileGridComponent,
    NavigationSidePanelComponent,
    NewsAllComponent,
    RatingHistoryComponent,
    TvChartComponent,
    FigureSliderComponent,
    FigureGraphComponent,
    SidebarsComponent,
    VerticalBarsComponent,
    TableComponent,
    ShareholdersDiagramComponent,
    HorizontalNavPanelComponent,
    FigureComponent,
    BookAMeetingComponent,
    TableChangeComponent,
    TemplateChartComponent,
    TableSliderComponent,
    TextComponent,
    AdminPanelSettingsModalComponent,
  ],
  imports: [
    CommonModule,
    CmsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule // having AppRoutingModule here causes a circular dependency. To keep the routerLink function etc. 
                 // working, we need to import RouterModule here. 
  ],
  exports: [...declaredComponents],
})
export class CompanyInfoModule { }
