import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Tables } from '../../../../../utilities';

@Component({
  selector: 'app-vertical-bars',
  templateUrl: './vertical-bars.component.html',
  styleUrls: ['./vertical-bars.component.scss']
})
export class VerticalBarsComponent implements OnInit, AfterViewInit {

  @ViewChild ('chartWrapperVerticalBars') chartWrapper: ElementRef;

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: { headline: string, type: string, content: string, height: number, width: number, mobile: boolean, useResizeHandler, style: any };
  @Input() decimals = 0;
  @Input() lang = 'eng';

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  index: number = 0;
  labelsize = 20;
  chart = null;
  chartTemplate: { data: any, layout: any, config: any } = {
    data: [],
    layout: {
      annotations: [],
      showlegend: false,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        tickangle: 0,
        showgrid: false,
        zeroline: false,
        tickfont: {
          // family: 'Arial',
          size: 16,
          color: '#e2e8f0'
        },
        fixedrange: true, 
      },
      yaxis: {
        showgrid: false,
        visible: false,
        fixedrange: true, 
      },
      margin: {
        autoexpand: true,
        t: 0,
        b: 30,
        l: 0,
        r: 0
      }
    },
    config: {
      displayModeBar: false
    },
    useResizeHandler: true,
    style: {position:'absolute', height:'100%', width: '100%'} 
  } as any;

  constructor(private tableUtils: Tables, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.prepareData();
  }

  ngAfterViewInit(){
    //this.prepareData();
  }
  ngOnChanges(): void {
    if(this.chartWrapper){
      this.prepareData();
    }
    
  }

  prepareData() {
    this.chart = null;
    if (this.mapping && this.companyData && this.companyData.base) {
      let trace = {
        x: [],
        y: [],
        text: [],
        textangle: '0',
        textposition: 'auto',
        insidetextanchor: 'start',
        insidetextfont: {
          'size': this.labelsize
        },
        textfont: {
          'size': this.labelsize
        },
        hoverinfo: 'none',
        marker: {
          color: [],
        },
        type: 'bar'
      };

      for (let key of this.config.content) {
        if (this.mapping[key]) {
          let mappingEntry = this.mapping[key];

          trace.x.push(mappingEntry[this.lang]);
          let na = false;
          if (mappingEntry.type === 'VALUE') {
            let val = this.tableUtils.getValue(this.companyData, mappingEntry, this.lang, this.decimals)[0];
            if (val.includes('-')) { val = 'n/a'; na = true; }
            trace.text.push(val);
            let matches = val.match(/\d+(.\d+)?/g);
            if (matches) {
              trace.y.push(matches[0]);
            } else {
              trace.y.push(val)
            }
          } else if (mappingEntry.type === 'TIMESERIES') {
            let val = this.tableUtils.getOneFromTimeseries(this.companyData, mappingEntry, 1 + this.index, 'value', this.lang, this.decimals)[0];
            if (val.includes('-')) { val = 'n/a'; na = true; }
            trace.text.push(val);
            if (val === 'n/a') {
              trace.y.push(1)
            } else {
              let matches = val.match(/\d+(.\d+)?/g);
              if (matches) {
                trace.y.push(matches[0]);
              } else {
                trace.y.push(val)
              }
            }
          }
          trace.marker.color.push('#e2e8f0');

          // if (na) {
          //   this.chart.layout.annotations.push({
          //     x: mappingEntry[this.lang],
          //     y: 0,
          //     text: 'n/a',
          //     showarrow: false,
          //     xanchor: 'center',
          //     yanchor: 'center',
          //     font: {
          //       size: this.labelsize,
          //     },
          //   });
          // }

        }
      }
      this.chart = JSON.parse(JSON.stringify(this.chartTemplate));
      this.chart.data = [trace];
      this.chart.style.height = this.chartWrapper?this.chartWrapper.nativeElement.offsetHeight + 'px':'100%';
      this.cdr.detectChanges();
    }
  }

  navigate(step: number) {
    let maxIndex = 2;
    if (this.index + step > maxIndex) {
      this.index = -2;
    } else {
      this.index = Math.min(maxIndex, Math.max(-2, this.index + step));
    }
    this.prepareData();
    this.tileEvent.emit({ type: 'year-change', change: { year: this.baseyear + this.index } })
  }
}
