<div class="modal-header">
  <h4 class="modal-title">Cookie permission</h4>
</div>
<div class="modal-body">
  <p style="font-size: 1.5rem;"><i>AlsterResearch uses cookies to track and improve site usage
      patterns. For more information, see the <a href="https://www.research-hub.de/datenschutz"> Privacy Policy </a>.
       You can revoke your consent at any time in the footer.</i></p>
</div>
<div class="modal-footer">
  <button ngbAutofocus type="button"
          class="btn btn-outline-light"
          (click)="ngbModal.close('Accepted')">Accept All</button>
  <button type="button"
          class="btn btn-outline-light"
          (click)="ngbModal.dismiss()">Deny cookies</button>
</div>
