<div [attr.id]="findAndAddJumpmark()" class="fingerFix border-top border-light-blue py-2 full-width collapseBlockHeadline" (click)="collapse = !collapse">
  <i class="fa fa-chevron-up px-2" *ngIf="!collapse"></i>
  <i class="fa fa-chevron-down px-2" *ngIf="collapse"></i>
  {{headline}}
</div>
<div class="table-wrapper container py-0 my-0 customFont"  [ngClass]="{'is-hidden': collapse}">
  <div class="row py-0 my-0">
    <div class="table-responsive mx-auto mt-2">
      <div>
        <table class="table table-sm table-hover bilanzkennzahlen">
          <tbody>
            <tr *ngFor="let row of tableLayout[0].data">
              <th [attr.colspan]="row.colspan" [ngClass]="{
                    rowHighlight: row.highlight,
                    rowHighlight2: row.highlight2,
                    tabHeader: row.headline
                  }">
                {{ row.key }}
              </th>
              <td *ngFor="let datapoint of row.data" [ngClass]="{
                    rowHighlight: row.highlight,
                    rowHighlight2: row.highlight2,
                    tabHeader: row.headline
                  }">
                {{ datapoint }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="text-left">Source: {{ dataSource.eng }}</p>
    </div>
  </div>
</div>
