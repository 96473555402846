import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyInfoService } from 'src/app/services';

@Component({
  selector: 'app-save-newsletter-form',
  templateUrl: './save-newsletter-form.component.html',
  styleUrls: ['./save-newsletter-form.component.scss']
})
export class SaveNewsletterFormComponent implements OnInit {

  @Input() formMode: String;

  newURL: any;
  constructor(public activeModal: NgbActiveModal, private companyInfoService: CompanyInfoService) { }

  ngOnInit(): void {
  }


  sendURL() {
    let urlIsGood = false;

    urlIsGood = this.checkURL(this.newURL)
    if (urlIsGood) {
      this.companyInfoService.saveNewFormURL(this.newURL, this.formMode).then((res) => {

        this.activeModal.close()
      }, (err) => {
        console.log(err); this.activeModal.close()
      })
    }
  }

  checkURL(url) {
    if (url !== null && url !== undefined && this.validURL(this.newURL)) {
      return true
    } else {
      return false;
    }

  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

}
