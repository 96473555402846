import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { alsterAPI_URL, leewayAPI_URL } from 'src/app/app-settings/api-urls';
import { EventService } from 'src/app/events/services/event.service';
import { IssuerUser } from '../models/issuer.model';

@Injectable({
  providedIn: 'root'
})
export class CorporateDashboardService {
  private readonly customerId = '5eed24ff4fe2bd337ccab5d3';
  private readonly apiToken = 'cqt1bii3tq5fuzo0c5id';
  private readonly apiUserURL = leewayAPI_URL+'cms/alsteruser/';
  constructor(
    private httpClient: HttpClient,
    private eventService: EventService
    ) { }


  getIssuerPageData(currentUsersCompanyBloombergticker){
    //BAT:SS
    // load filtered events by cloomberg ticker
      // -> events
      // GET	http://localhost:3000/api/v1/cms/event/all?apitoken=cqt1bii3tq5fuzo0c5id

    // load logo by cloomberg ticker
    // load company by cloomberg ticker?
    // load alster contant person (hardcode for now)
    return this.eventService.getEventsWithSIBContext(currentUsersCompanyBloombergticker)

  }

  getCurrentCompanyCurrentResearch(currentUsersCompanyBloombergticker:string){
    const url = alsterAPI_URL+'companyinfo/research/'+currentUsersCompanyBloombergticker;

    return this.httpClient.get(url);
  }

  getCurrentCompanyPresentation(currentUsersCompanyBloombergticker:string){

    const url = alsterAPI_URL+'companyinfo/companypresentation/'+currentUsersCompanyBloombergticker;

    return this.httpClient.get(url);
  }

  getIssuerUsers() {
    // get all users but filter for issuer
    
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const url = this.apiUserURL+'issuer/all?' + apiTokenparam;

    return this.httpClient.get(url);
  }

  addIssuerUser(issuerUser:IssuerUser) {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const url = this.apiUserURL+'issuer/add?' + apiTokenparam;
    issuerUser['type'] = 'ISSUER';
    const payload = {newUser:issuerUser}
    return this.httpClient.post(url, payload);
  }

  deleteIssuerUser(issuerUser:IssuerUser) {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const url = this.apiUserURL+'issuer/delete?' + apiTokenparam; 
    const payload = {userId:issuerUser.userId}
    return this.httpClient.post(url,payload );
  }

  updateIssuerUser(issuerUser:IssuerUser) {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const url = this.apiUserURL+'issuer/update?' + apiTokenparam;
    const payload = {userId:issuerUser.userId, newUserData:issuerUser}
    return this.httpClient.post(url, payload);
  }

  updateIssuerUserPassword(issuerUser:IssuerUser, newPassword) {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const url = this.apiUserURL+'issuer/updatepassword?' + apiTokenparam;
    const payload = {userId:issuerUser.userId, newPassword}

    return this.httpClient.post(url, payload);
  }
}
