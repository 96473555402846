<div class="d-none d-lg-block" [ngClass]="{'border border-dark rounded' : adminMode}" id="{{data.blockTag}}">
  <div id="valuation-dcf-model" class="fingerFix border-top border-light-blue py-2 full-width collapseBlockHeadline" (click)="data.collapse = !data.collapse">
    <i class="fa fa-chevron-up px-2" *ngIf="!data.collapse"></i>
    <i class="fa fa-chevron-down px-2" *ngIf="data.collapse"></i>
    {{data.blockHeadline || "DCF Model"}}
  </div>
  <app-dcf-model [dcfType]="data.dcfType" [companyData]="metaCompanyData" [ngClass]="{'is-hidden': data.collapse}"></app-dcf-model>
  <div class="row" *ngIf="adminMode">
    <div class="col-12">
      <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
        [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName" [metaBloombergTicker]="metaBloombergTicker"
        [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData"></app-block-handling>
    </div>
  </div>
</div>
