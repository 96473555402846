import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsletterSubscribeComponent } from 'src/app/basic-pages/modals/newsletter-subscribe/newsletter-subscribe.component';

@Component({
  selector: 'app-subscribe-banner',
  templateUrl: './subscribe-banner.component.html',
  styleUrls: ['./subscribe-banner.component.scss']
})
export class SubscribeBannerComponent implements OnInit {

  constructor(
    private modalService: NgbModal,

  ) { }

  emailInput = null
  ngOnInit(): void {
  }



  openSubscription() {
    const modalRef = this.modalService.open(NewsletterSubscribeComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.emailMode = "subscription" // delete this when new forms are used in production
    modalRef.componentInstance.emailInput = this.emailInput // delete this when new forms are used in production
    modalRef.componentInstance.formMode = 'newsletter'

  }
}
