import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataServiceService } from '../../../services/data-service.service';
import { Tables } from '../../../utilities';


@Component({
  selector: 'app-table-template-creation',
  templateUrl: './table-template-creation.component.html',
  styleUrls: ['./table-template-creation.component.scss']
})
export class TableTemplateCreationComponent implements OnInit {
  @Input() templateName = '';
  @Input() tabs = false;
  @Input() updateMode = false;

  defaultTemplate = this.tableUtil.defaultTemplate;
  languages = this.tableUtil.languages;
  datatypes = this.tableUtil.datatypes;
  highlights = this.tableUtil.highlights;
  mappingKeys: Array<string> = [];

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.mappingKeys.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  constructor(public activeModal: NgbActiveModal, public dataService: DataServiceService, public tableUtil: Tables) {
  }

  ngOnInit(): void {
    this.dataService.getMapping().then((mapping) => {
      this.mappingKeys = Object.keys(mapping);
    });

    if (this.templateName !== '') {
      this.dataService.getTableTemplate(this.templateName).subscribe((res: any) => {
        this.defaultTemplate = res
      })
    } else {
      this.defaultTemplate.settings.tabs = this.tabs;
    }
  }

  closeWindow() {

    this.activeModal.close(this.dataService.putTableTemplate(this.updateMode, this.defaultTemplate).subscribe(res => {
    }))
  }

  setTemplateTitel(lang: string, name: string) {
    this.defaultTemplate.settings.titel[lang] = name
  }

  setLanguage(lang: string, tabNum: number) {
    this.defaultTemplate.template[tabNum].settings.language = lang;
  }

  setDatatype(datatype: string, tabNum: number, index: number) {
    this.defaultTemplate.template[tabNum].data[index].type = datatype;
  }

  setHighlight(highlight: string, tabNum: number, index: number) {
    if (highlight === 'highlight') {
      this.defaultTemplate.template[tabNum].data[index].highlight = true;
      this.defaultTemplate.template[tabNum].data[index].highlight2 = false;
      this.defaultTemplate.template[tabNum].data[index].headline = false;
    } else if (highlight === 'highlight2') {
      this.defaultTemplate.template[tabNum].data[index].highlight = false;
      this.defaultTemplate.template[tabNum].data[index].highlight2 = true;
      this.defaultTemplate.template[tabNum].data[index].headline = false;
    } else if (highlight === 'headline') {
      this.defaultTemplate.template[tabNum].data[index].highlight = false;
      this.defaultTemplate.template[tabNum].data[index].highlight2 = false;
      this.defaultTemplate.template[tabNum].data[index].headline = true;
    } else {
      this.defaultTemplate.template[tabNum].data[index].highlight = false;
      this.defaultTemplate.template[tabNum].data[index].highlight2 = false;
      this.defaultTemplate.template[tabNum].data[index].headline = false;
    }
  }

  addTab() {
    this.defaultTemplate.template.push(JSON.parse(JSON.stringify(this.tableUtil.defaultTemplate.template[0])));
  }

  deleteTab(tab: any) {
    for (let i = 0; i < this.defaultTemplate.template.length; i++) {
      if (this.defaultTemplate.template[i] === tab) {
        this.defaultTemplate.templates.splice(i, 1);
      }
    }
  }

  addRow(tabNum: number) {
    this.defaultTemplate.template[tabNum].data.push(JSON.parse(JSON.stringify(this.tableUtil.defaultTemplate.template[0].data[1])));
  }

  deleteRow(tabNum: number, index: number) {
    this.defaultTemplate.template[tabNum].data.splice(index, 1);
  }
}
