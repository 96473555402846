import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublishwarningComponent } from 'src/app/cms/publishwarning/publishwarning.component';
import { SessionwarningComponent } from 'src/app/cms/sessionwarning/sessionwarning.component';
import { DeleteWarningComponent } from "../../../cms/delete-warning/delete-warning.component";
import { AuthService, CompanyInfoService } from '../../../services';


@Component({
  selector: 'app-editor-header',
  templateUrl: './editor-header.component.html',
  styleUrls: ['./editor-header.component.scss']
})
export class EditorHeaderComponent implements OnInit {
  @Input() companyName = '';
  @Input() companyId = 'XXX:XX';
  @Input() pageType = '';
  @Input() archiveDate = null;
  @Input() archiveTitle = null;

  @Output() editModeChanged: EventEmitter<any> = new EventEmitter();


  showTitleInput: boolean = false;
  transactionMode = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    public companyInfoService: CompanyInfoService,
    private authService: AuthService,
    private modalService: NgbModal,
  ) {




    this.companyInfoService.loadingBool$.subscribe((cmsStatus) => {

    }
    );
  }




  ngOnInit(): void {

    if (this.companyInfoService.getTransactionMode()) {
      this.transactionMode = true;
    }
    if (this.pageType === "weeklyScreener") {
      this.companyId = 'weekly:screener';

    }
  }


  isEditing() {
    return this.companyInfoService.isCurrentlyEditingMode(this.companyId, this.pageType);
  }
  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  isAllowedCompany() {
    return this.authService.isAllowedAction(this.companyId, "write")
  }

  startEditing() {

    this.companyInfoService.startEditingMode(false/**false */, this.companyId, this.pageType, this.archiveDate, this.archiveTitle).then(
      result => {

        if (result === false) {
          this.openSessionWarning();
        }else{
          this.editModeChanged.emit(true);
        }
      }
    );
  }

  forceStartEditing() {
    this.companyInfoService.startEditingMode(true, this.companyId, this.pageType, this.archiveDate, this.archiveTitle).then(
      result => {
        this.editModeChanged.emit(true);
      }
    );
  }

  endEditing() {
    this.companyInfoService.endEditingMode(this.companyId, this.pageType).then(result => {
      this.editModeChanged.emit(false);
    });
  }

  removeChangesAndEndEditing() {
    this.companyInfoService.deleteChangesAndEndEditorMode(this.companyId, this.pageType).then(result => {
      this.editModeChanged.emit(false);
    });
  }

  publishChangesForPage() {
    return new Promise((resolve, reject) => {
      this.companyInfoService.publishChangesForPage(this.companyId, this.pageType, this.archiveDate, this.archiveTitle).then(res => {
        setTimeout(() => {
          resolve(true)
        }, 200);
      },
        (err) => { reject(false) });
    })
  }

  openPublishWarning() {
    const modalRef = this.modalService.open(PublishwarningComponent, { centered: true, size: 'lg' }).result.then((result) => {

    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {
        this.publishChangesForPage().then(() => { this.editModeChanged.emit(false); });
      } else {
        // do nothing
      }
    });
  }

  openSessionWarning() {

    const modalRef = this.modalService.open(SessionwarningComponent, { centered: true, size: 'lg' }).result.then((result) => {

    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        this.companyInfoService.forceEndUserEditing(this.companyId, this.pageType);
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.companyInfoService.forceEndUserEditing(this.companyId, this.pageType);
      } else if (reason === 'deny') {
        this.companyInfoService.forceEndUserEditing(this.companyId, this.pageType);
      } else if (reason === 'ok') {
        this.forceStartEditing();
      } else {
        this.companyInfoService.forceEndUserEditing(this.companyId, this.pageType);
      }
    });
  }


  archivePublishedPage() {
    this.showTitleInput = false;
    this.publishChangesForPage().then(
      el => {
        return new Promise((resolve, reject) => {
          this.companyInfoService.archivePublishedPage(this.companyId, this.pageType, this.archiveTitle, null).then(res => {
            setTimeout(() => {
              resolve(true)
            }, 200);
          },
            (err) => { reject(false) });
        })
      }
    )
  }



  openDeletionWarning() {

    //    this.companyInfoService.openDeletionWarning(this.companyName, this.pageType, null)
    const modalRef = this.modalService.open(DeleteWarningComponent, { centered: true, size: 'lg' })

    modalRef.componentInstance.companyName = this.companyName
    modalRef.componentInstance.pageType = this.pageType
    modalRef.componentInstance.blocks = []
    modalRef.result.then((result) => {

    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {
        this.removeChangesAndEndEditing()
      } else {
        // do nothing
      }
    });
  }
}
