<div>
  <!--  DONT FORGET TO USE THE DOWNLOAD RESEARCH STATISTICS BUTTON-->
  <div class="tile-title row">
    <div class="col-10">Book a meeting</div>

    <div class="col-2 text-right fingerFix" (click)="closeWindow()">
      <div>X</div>
    </div>

  </div>
  <div class="row form">
    <div class="col-12">
      <p class="my-2">If you are interestd in a meeting with {{companyName}} please complete the data.</p>

      <div class="form-row mt-2">
        <div class="col-12">
          <input required class="form-control" [(ngModel)]="firstname" type="text" placeholder="First Name*" />
          <label *ngIf="requiredFieldMissingInput">This field must be filled.</label>
        </div>
      </div>
      <div class="form-row mt-2">
        <div class="col-12">
          <input required class="form-control" [(ngModel)]="lastname" type="text" placeholder="Last Name*" />
          <label *ngIf="requiredFieldMissingInput">This field must be filled.</label>
        </div>
      </div>
      <div class="form-row mt-2">
        <div class="col-12">
          <input required class="form-control" [(ngModel)]="email" type="text" placeholder="Email*" />
          <label *ngIf="requiredFieldMissingInput">This field must be filled.</label>
        </div>
      </div>
      <div class="form-row mt-2">
        <div class="col-12">
          <input required class="form-control" [(ngModel)]="requesterscompany" type="text" placeholder="Your Company*" />
          <label *ngIf="requiredFieldMissingInput">This field must be filled.</label>
        </div>
      </div>
      <div class="form-row mt-2">
        <div class="col-12">
          <input class="form-control" [(ngModel)]="phonenumber" type="text" placeholder="Phone" />
        </div>
      </div>
      <div class="form-row mt-2">
        <div class="col-12 text-center">
          <button [disabled]="requestPending" class="btn btn-std" (click)="sendNotification()">Send a request</button>
        </div>
        <div *ngIf="requestSent" class="col-12 text-center mt-2">
          <label >Your request has been sent. We will get in touch with you as soon as possible.</label>
        </div>
      </div>
    </div>
  </div>
</div>
