import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { translateProgrammingString } from 'src/app/utilities';
@Component({
  selector: 'app-block-esg-all',
  templateUrl: './block-esg-all.component.html',
  styleUrls: ['./block-esg-all.component.scss']
})
export class BlockEsgAllComponent implements OnInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: any;
  @Input() blockType: any;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;

  realfillerText = `<h3 style="text-align: justify;">The Leeway ESG Methodology</h3>
  <p style="text-align: justify;">The Leeway ESG score is based on quantifiable data, which is collected from public sources and analyzed with the help of machine learning tools. Major sources of information are sustainability reports and other corporate disclosures. Missing data is rated negatively in order to encourage companies to be more transparent and accessible in their reporting.</p>
  <p style="text-align: justify;">The <strong>environmental score (E) </strong>is based on up to 50 individual factors, including greenhouse gas emissions, water consumption and waste management. These factors are evaluated in relation to the size of the company.</p>
  <p style="text-align: justify;">The <strong>social score (S) </strong>builds on the analysis of up to 35 separate criteria, for example human capital development, R&amp;D spending, community relations, executive and employee compensation, and board diversity.</p>
  <p style="text-align: justify;">The <strong>governance score (G) </strong>considers up to 70 individual components, like supply chain standards, health &amp; safety standards, product safety, and worker representation.</p>
  <p style="text-align: justify;">The <strong>overall ESG score</strong> is calculated relative to peers and adjusted for company size.</p>
  <p style="text-align: justify;">The development of the Leeway methodology was co-funded by the German Federal Ministry for Economic Affairs and the European Social Fund. Leeway&rsquo;s mission is a cleaner, more transparent and more accessible stock market and that mission starts with easy access to comparable data. To learn more about Leeway, <a href="https://www.leeway.tech/" target="_blank" rel="noopener">click here</a>.</p>`
  showSaveWarning = false;
  uploaderMode = false;
  isEditorMode = false;
  loadingEditor = false;
  changeMode = false;
  noData = false;
  showSaveErrMsg = false;
  showLoadingIndicator = false;
  // tmp mock data
  stock: any = {
    companyName: "",
    labels: {
      negativeLabels: [],
      positiveLabels: []
    },
    rating: {
      taxRate: 0,
      Social: 0,
      total: 0,
      Environment: 0,
      emissionReduction: 0,
      Governance: 0,
      ceoPayRatio: 0
    }
  }

  constructor(
    private companyInfoSerice: CompanyInfoService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
  ) {
  }
  ngOnInit(): void {
    if (this.metaBloombergTicker === undefined || this.metaBloombergTicker === null || this.metaBloombergTicker) {
      this.metaBloombergTicker = this.data.bloombergTicker
    }

    //if (this.data.blockTag === null || this.data.blockTag === undefined) { this.data.blockTag = "#" + this.blockType }
    if (this.data.text === undefined || this.data.text === null || this.data.text === "") {
      this.data.text = this.realfillerText
    }
  }

  setLWComppanyName(event) {
    this.stock.companyName = event.companyName
  }
  public startEditorMode() {
    this.isEditorMode = true;
    this.loadingEditor = true;
    setTimeout(() => {
      this.loadingEditor = false;
    }, 100);
    this.cdr.detectChanges()
  }
  public endEditorMode() {
    this.isEditorMode = false;
    this.showLoadingIndicator = false;
    this.cdr.detectChanges()
  }
  editorClosedByChildEvent(event) {
    this.showLoadingIndicator = true;
    this.data.text = event.data;
    if (event.close === true) {
      this.endEditorMode();
    } else {
      this.updateBlockData(false, true).then(() => {
        this.showSaveErrMsg = false;
        this.cdr.detectChanges()
      }, (err) => {
        this.showSaveErrMsg = true;
        this.cdr.detectChanges()
      })

    }

  }
  updateBlockData(withreload, keepEditModeOpen): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.companyInfoSerice.updateBuildingBlock(this.metaBloombergTicker, this.metaPageType, false, this.block_id, this.blockType, this.data, null, null)
        .then(res => {
          if (!keepEditModeOpen) {
            this.endEditorMode();
          }
          this.showLoadingIndicator = false;

          if (withreload) {
            setTimeout(() => {
              this.deleteEmitter.next({ blockId: this.block_id, 'delete': false, 'reload': true });
            }, 300);
          }
          resolve(true)
        }, (err) => {
          _reject(false)
        });
    })

  }
  deleteBlock() {
    this.deleteEmitter.next({ blockId: this.block_id, 'delete': true });
    // this.companyInfoSerice.deleteBlock(this.block_id).then(res => {
    //   setTimeout(() => {
    //    // window.location.reload();
    //   }, 300);
    // });
  }
  startChangeMode() {
    this.changeMode = true;
  }
  endChangeMode() {
    this.changeMode = false;
    this.isEditorMode = false;
  }

  translate(s: string): string {
    return translateProgrammingString(s);
  }

}
