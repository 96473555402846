<div *ngIf="!onlyLeft && !onlyRight" class="container-fluid bg-bluearea ">
  <div class="row limit-pagewidth">
    <div class="col-12 content-wrapper">
      <div class="pt-3 d-flex justify-content-between">
        <div *ngIf="listOfCompanies[currentCompanyIndex-1];else overflowLeft" class="btn-link fingerFix prev-next-name"
          [routerLink]="['/companies/'+listOfCompanies[currentCompanyIndex-1].name]"><i class="fas fa-arrow-left"></i>
          {{listOfCompanies[currentCompanyIndex-1].name}}
        </div>
        <ng-template #overflowLeft >
        <div  class="btn-link fingerFix"
          [routerLink]="['/companies/'+listOfCompanies[listOfCompanies.length-1].name]"><i class="fas fa-arrow-left"></i>
          {{listOfCompanies[listOfCompanies.length-1].name}}
        </div>
      </ng-template>
        <div class="text-center companyName">
          <h3>{{listOfCompanies[currentCompanyIndex].name}}</h3>
        </div>
        <div *ngIf="listOfCompanies[currentCompanyIndex+1];else overflowRight" class="btn-link fingerFix prev-next-name"
          [routerLink]="['/companies/', listOfCompanies[currentCompanyIndex+1].name]">
          {{listOfCompanies[currentCompanyIndex+1].name}} <i class="fas fa-arrow-right"></i></div>
          <ng-template #overflowRight >
        <div  class="btn-link fingerFix" [routerLink]="['/companies/'+listOfCompanies[0].name]"><i
            class="fas fa-arrow-left"></i>
          {{listOfCompanies[0].name}}
        </div>
      </ng-template>
      </div>
    </div>
  </div>
</div>


<div *ngIf="onlyLeft" style="line-height:0!important">
<div *ngIf="listOfCompanies[currentCompanyIndex-1];else overflowLeft" class="btn-link fingerFix prev-next-name pl-2 dont-wrap"
[routerLink]="['/companies/'+listOfCompanies[currentCompanyIndex-1].name]"><i class="fas fa-arrow-left"></i>
{{listOfCompanies[currentCompanyIndex-1].name | slice:0:15}}<span *ngIf="listOfCompanies[currentCompanyIndex-1].name.length > 15">...</span>
</div>
<ng-template #overflowLeft >
  <div  class="btn-link fingerFix pl-2 dont-wrap"
    [routerLink]="['/companies/'+listOfCompanies[listOfCompanies.length-1].name]"><i class="fas fa-arrow-left"></i>
    {{listOfCompanies[listOfCompanies.length-1].name | slice:0:15}} <span *ngIf="listOfCompanies[listOfCompanies.length-1].name.length > 15">...</span>
  </div>
</ng-template>
</div>

<div *ngIf="onlyRight" style="line-height:0!important">
  <div *ngIf="listOfCompanies[currentCompanyIndex+1];else overflowRight" class="btn-link fingerFix prev-next-name pr-2 dont-wrap" style="text-align: end;"
  [routerLink]="['/companies/', listOfCompanies[currentCompanyIndex+1].name]">
  {{listOfCompanies[currentCompanyIndex+1].name | slice:0:15}} <span *ngIf="listOfCompanies[currentCompanyIndex+1].name.length > 15">...</span> <i class="fas fa-arrow-right"></i></div>

  <ng-template #overflowRight >
  <div  class="btn-link fingerFix pr-2 dont-wrap" [routerLink]="['/companies/'+listOfCompanies[0].name]">
    {{listOfCompanies[0].name | slice:0:15}}
    <span *ngIf="listOfCompanies[0].name.length > 15">... </span>
    <i class="fas fa-arrow-right"></i>

  </div>
</ng-template>
</div>