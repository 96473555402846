import { template } from '@angular-devkit/core';
import { EventEmitter, ChangeDetectorRef, Component, Input, Output, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { DataServiceService } from 'src/app/services/data-service.service';
import { Charting } from 'src/app/utilities';

@Component({
  selector: 'app-template-chart',
  templateUrl: './template-chart.component.html',
  styleUrls: ['./template-chart.component.scss']
})
export class TemplateChartComponent implements OnInit, AfterViewInit {

  @ViewChild ('chartWrapperTemplateCharts') chartWrapper: ElementRef;


  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: { headline: string, type: string, content: string, height: number, width: number, mobile: boolean, useResizeHandler, style: any };
  @Input() decimals = 0;
  @Input() lang = 'eng';
  @Input() noSliders = false;
  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  currentKey = '';
  index: number = 0;
  labelsize = 20;
  chart = null;
  chartTemplate: { data: any, layout: any, config: any } = {
    data: [],
    layout: {
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      barmode: 'relative',
      xaxis: {
        tickangle: 0,
        type: 'category',
        showgrid: false,
        zeroline: false,
        tickfont: {
          size: 16,
          color: '#e2e8f0'
        },
        fixedrange: true,
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        visible: true,
        tickfont: {
          size: 16,
          color: '#e2e8f0'
        },
        // tickformat: ',d',
        y2: {
          showgrid: false,
          zeroline: false,
          visible: true,
          tickfont: {
            size: 16,
            color: '#e2e8f0'
          },
          fixedrange: true, 
          // tickformat: ',d'
        },
        fixedrange: true,
      },
      legend: {
        font: {
          size: 16,
          color: '#e2e8f0'
        },
      },
      autosize: true,
      showlegend: true,
      margin: {
        autoexpand: true,
        t: 30,
        b: 30,
        l: 50,
        r: 50
      }
    },
    config: {
      displayModeBar: false
    },
    useResizeHandler: true,
    style: {height:'100%', width: '100%'} 
  } as any;


  constructor(private chartingUtil: Charting, private dataService: DataServiceService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initChart(this.config.content[this.index]);
  }

  ngAfterViewInit(){
  
  }

  prepareData(chartingLayout): Array<Partial<any>> {
    let data = [];
    let roceLineData = [];
    let waccLineData = [];

    for (let i = 0; i < chartingLayout.data.length; i++) {
      let dataset = chartingLayout.data[i];
      if (dataset.type === 'PIE') {
        this.chart.layout['showlegend'] = false;
        this.chart.layout['font'] = {
          color: '#ffffff',
          size: 14,
        };
        //process value type data

        if (data.length < 1) {
          let labels = [];
          let values = [];
          if (!dataset.data[0].length) {
            let label = '';
            if (typeof dataset['label'] === 'undefined') {
              label = dataset.key;
            } else {
              label = dataset.label;
            }
            labels = [label];
            values = dataset.data;

          } else {
            for (let entry of dataset.data[0]) {
              if (entry.date !== '#EMPTY') {
                values.push(entry.value);
                labels.push(entry.date);
              }
            }
          }

          let entry = {
            labels: labels.map(label => label.length > 20 ? label.slice(0,20)+'...' : label),
            text: labels,
            values: values,
            type: 'pie',
            textposition: "outside",
            automargin: true,
            textinfo: "label+percent",
            insidetextorientation: "radial",
            hoverinfo: 'text+percent'
          }

          data.push(entry);

        } else {
          if (dataset.data.length === 1) {
            let label = '';
            if (typeof dataset['label'] === 'undefined') {
              label = dataset.key;
            } else {
              label = dataset.label;
            }
            
            data[0].values.push(dataset.data[0]);
            data[0].labels.push(label);
          } else {
            for (let entry of dataset.data) {
              data[0].values.push(entry.value);
              data[0].labels.push(entry.date);
            }
          }
        }
        
        data[0] = this.addStyle(dataset.type, 0, data[0]);
      } else {
        //process timeseries type data
        let entry = {
          x: dataset.xAxis,
          y: dataset.data,
          name: dataset.key,
          text: dataset.key,
          type: this.getType(dataset.type),
          mode: this.getMode(dataset.type),
        }
        
        entry = this.addStyle(dataset.type, i, entry);
        
        if (typeof dataset.yaxis !== 'undefined') {
          entry['yaxis'] = 'y' + dataset.yaxis;
          entry['offsetgroup'] = i;
        } else {
          entry['yaxis'] = 'y1';
          entry['offsetgroup'] = i;
        }
        
        data.push(entry);
        
        this.chart.layout['yaxis2'] = {
          showgrid: false,
          zeroline: false,
          visible: true,
          tickfont: {
            size: 16,
            color: '#e2e8f0'
          },
          // tickformat: ',d',
          side: 'right',
        }

        this.chart.layout['yaxis'] = {
          overlaying: 'y2',
          ...this.chart.layout.yaxis,
          title: '',
          titlefont: {
            size: 20,
          },
        };
        this.chart.layout['xaxis']['fixedrange'] = true;

        this.chart.layout.legend['x'] = -0.03;
        this.chart.layout.legend['orientation'] =  'h';
        if(dataset.type === 'STACKEDBAR' || dataset.type === 'GROUPEDBAR'){
          this.chart.layout['bargap'] = 0.4;
        }
        if(dataset.type === 'LINE' && dataset.key === 'Pretax WACC'){
          waccLineData = dataset.data;
          const temp = this.calc(waccLineData, roceLineData);
          this.chart.layout['yaxis']['range'] = this.calc(waccLineData, roceLineData);
        }
        if(dataset.type === 'LINE' && dataset.key === 'ROCE'){
          roceLineData = dataset.data;
        }
        if(dataset.type === 'GROUPEDBAR' && dataset.key === 'Sales'){
          const range = this.roundMinMax(dataset.data);
          this.chart.layout['yaxis2']['range'] = [range[0], range[1]];
        }
        
        this.chart.style.height = this.chartWrapper?this.chartWrapper.nativeElement.offsetHeight + 'px':'100%';
        //this.cdr.detectChanges();
      }
    }
    return data;
  }

  private addStyle(type, index, entry) {
    const colors = ['rgb(20, 83, 137)', 'rgb(255, 214, 67)', 'rgb(131, 171, 219)', 'rgb(223, 233, 246)', 'rgb(236, 236, 236)']
    let obj = {
      color: colors[index],
      // width: 4,
    };

    if (type !== 'PIE') {
      entry['marker'] = obj;
      entry['line'] = obj;
      entry['line']['width'] = 4;
      entry['hoverinfo'] = 'text+y',
      entry['hoverlabel'] = {namelength: -1};
        if(entry.name === 'Sales' || entry.name === 'EBIT margin'){
          const colors = ['rgb(255, 214, 67)', 'rgb(20, 83, 137)'];
          const obj = {
            color: colors[index],
          };
          entry['marker'] = obj;
          entry['line'] = obj;
          entry['line']['width'] = 4;
        }
    } else {
      entry['marker'] = {
        colors: colors
      }
    }

    return entry;
  }

  private getType(type: string): string {
    if (type === 'GROUPEDBAR') {
      this.chart.layout['barmode'] = 'group';
      return 'bar';
    } else if (type === 'STACKEDBAR') {
      this.chart.layout['barmode'] = 'stack';
      return 'bar';
    } else if (type === 'LINE') {
      return 'scatter';
    } else if (type === 'SCATTER') {
      return 'scatter';
    }
  }

  private getMode(type: string): string {
    if (type === 'GROUPEDBAR') {
      return '';
    } else if (type === 'STACKEDBAR') {
      return '';
    } else if (type === 'LINE') {
      return 'lines';
    } else if (type === 'SCATTER') {
      return 'markers';
    }
  }

  private initChart(templateName: string): void {
    
    //weird behaviour get second call here with templateName = chart template object
    //cannot find source so catch with if
    if (typeof templateName === 'string') {
      this.dataService.getChartingTemplate(templateName).subscribe((res: any) => {
        this.chartingUtil.createChartingData(this.companyData, res.template, this.decimals, this.companyData.CompanyId, res.settings.type).then(chart => {
          this.initChart(chart)
          this.tileEvent.emit({ type: 'headline-change', change: { headline: chart.name[chart.settings.language] } })

          this.chart = JSON.parse(JSON.stringify(this.chartTemplate));
          this.chart.data = this.prepareData(chart);

          this.cdr.detectChanges();
        })
      });
    } else {

    }
  }

  navigate(step: number) {
    if (this.index + step > this.config.content.length - 1) {
      this.index = 0;
    } else if (this.index + step < 0) {
      this.index = this.config.content.length - 1;
    } else {
      this.index = this.index + step;
    }

    this.initChart(this.config.content[this.index]);
  }

  private roundMinMax(arr) {
    arr = arr.map(str => parseFloat(str.replace(',', '')));
    const min = Math.min(...arr);
    const max = Math.max(...arr);
  
    const roundedMin = Math.floor(min*0.5);
    const roundedMax = Math.ceil(max*1.5);
  
    return [roundedMin, roundedMax];
  }

  private calc(waccLineData, roceLineData): any {
    roceLineData = roceLineData.map(val => {
      return +val.slice(0, -1);
    })
    const avgWacc = waccLineData.map((val => {
      return !val ? 0 : +val.slice(0, -1);
    })).reduce((acc, val) => acc + val, 0)/waccLineData.length;
    const min = Math.min(...roceLineData);
    const max = Math.max(...roceLineData);
    if(!avgWacc || (avgWacc > min && avgWacc < max)){
      return [Math.floor(min), Math.ceil(max)];
    } else if(avgWacc >= max){
      return [Math.floor(min), Math.ceil(avgWacc*1.5)];
    } else if(avgWacc <= max){
      return [Math.floor(avgWacc*0.8), Math.ceil(max)];
    }
  }
}
