import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { decode as htmlDecodeUmlaute } from "he";


@Component({
  selector: 'app-markets-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorComponent implements OnInit, OnChanges {

  parsedData: any;
  parsedPreviewData: any;


  sg = false;
  vg = false;

  @Input() altTag = 'Image not displayed for better latency';
  @Input() isEditorMode = false;
  @Input() articleURL;
  @Input() data: any;
  @Input() editortouchedNOSAVE = false
  @Input() presentationType = "";
  @Output() editorClosedEventhandler = new EventEmitter();

  isPreview = false;
  swotIndex = 0;

  constructor(
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef

  ) {
  }

  ngOnInit(): void {
    this.renderData();
  }

  ngOnChanges(): void {
    this.renderData();
    this.cdr.detectChanges()
  }

  renderData() {
    if (this.data !== null && this.data !== undefined && this.presentationType !== 'SWOT' && this.presentationType !== 'SWOT_MOBILE') {
      this.data = this.data.replace(/<img /ig, '<img alt="' + this.altTag + '"');
      this.parsedData = this.domSanitizer.bypassSecurityTrustHtml(this.data);
    }else{
      this.parsedData = this.parseLegacySWOTData(this.data);
    }


    this.cdr.detectChanges()
  }

  removeLegacySwotChars(data) {

    data = data
      let regex = /(<([^>]+)>|<\/?[^>]+>|style="[^"]*")/gi;
      data = data.trim()
      data = data
        .replace(regex,'')
        .replace("span></",'')
        .replace("span>",'')
        .replace(">",'')
        .replace(" >",'')
      return data
  }

  parseLegacySWOTData(data) {
    let swotData = [
      {
        title: "Strengths",
        content: []
      },
      {
        title: "Weaknesses",
        content: []
      },
      {
        title: "Opportunities",
        content: []
      },
      {
        title: "Threats",
        content: []
      },
    ];

    data = data.replace(/<ul.*>/g,'');
    let dataArray = data.split('</ul>');

    for(let i = 0; i < dataArray.length; i++) {
      if(i < 4){
        let swotText = dataArray[i];
        let split = swotText.substring(swotText.indexOf('<li'),swotText.lastIndexOf('</li>')).split('</li>');
        for(let entry of split){
          entry = entry.replace(/\n/g,'').replace(/<.*?>/g,'');
          if(entry.length > 0){
            swotData[i].content.push(htmlDecodeUmlaute(entry));
          }
        }
      }
    }

    return swotData
  }

  saveContent() {
    this.endEditorMode(true);
  }

  startEditorMode() {
    this.isEditorMode = true;
  }

  handleEditorChangedEvent(event) {
    this.editortouchedNOSAVE = true;
    this.endEditorMode(true)
  }
  handleEditorSavedEvent(event) {
  }

  endEditorMode(save) {

    if (save === true) {
      this.editorClosedEventhandler.emit({ data: this.data, close: false });
      this.renderData();
    } else {
      this.editorClosedEventhandler.emit({ data: this.data, close: true });
    }
    this.cdr.detectChanges()
  }




}
