import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { alsterPDF_URL,alsterAPI_URL } from '../app-settings/api-urls';

@Injectable({
  providedIn: 'root'
})
export class FilehandlerService {

  constructor(private http: HttpClient) {

  }

  encoder = new HttpUrlEncodingCodec();

  encodeForURL(input) {
    return this.encoder.encodeKey(input);
  }


  uploadImages(file: File): Observable<HttpEvent<any>> {

    let url = 'http://localhost:3000';
    url = alsterPDF_URL+'api/v1/images/saveimage?apitoken=cqt1bii3tq5fuzo0c5id';
    const formData = new FormData();
    formData.append('images', file);

    const params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }


  uploadPDFs(file: File, bbTicker, companyName, mode, shownName, imageMapperID): Promise<HttpEvent<any>> {

    // mode is either  report or research
    let url = alsterPDF_URL+'api/v1/images/savesimplepdf?apitoken=cqt1bii3tq5fuzo0c5id&bloombergticker=';
    url = url + bbTicker + '&name=' + this.encodeForURL(companyName) + '&mode=' + mode + '&imagemapperid=' + imageMapperID + '&shownname=' + this.encodeForURL(shownName);
    const formData = new FormData();
    formData.append('pdfs', file);
    // formData.append('shownName', shownName);
    // formData.append('imageMapperID', imageMapperID);

    const params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req).toPromise();
  }
  uploadPDFImages(file: File, bbTicker, companyName, mode, imageMapperID): Promise<HttpEvent<any>> {

    // mode is either  report or research
    let url = alsterPDF_URL+'api/v1/images/savesimplepdfimages?apitoken=cqt1bii3tq5fuzo0c5id&bloombergticker=';
    url = url + bbTicker + '&name=' + this.encodeForURL(companyName) + '&mode=' + mode + '&imagemapperid=' + imageMapperID;
    const formData = new FormData();
    formData.append('images', file);
    // formData.append('imageMapperID', imageMapperID);

    const params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req).toPromise();
  }

  uploadToDrive(file: File, bbTicker:string){
    const url = alsterAPI_URL + "uploads/drive"
    // prepare request
    const formData = new FormData();
    formData.append('files', file);

    const params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
    };
    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req)
  }
}
