export const chartLayout = {
  barmode: 'relative',
  xaxis: {
    tickangle: -45,
    type: 'category'
  },
  legend: {
    x: -0.03,
    y: 1.15,
    orientation: 'h',
  },
  autosize: true,
  showlegend: true,
};
