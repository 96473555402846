import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shareholders-diagram',
  templateUrl: './shareholders-diagram.component.html',
  styleUrls: ['./shareholders-diagram.component.scss']
})
export class ShareholdersDiagramComponent implements OnInit {

  @Input() companyData:any
  @Input() mapping: any
  @Input() baseyear = 2022
  @Input() config: any
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeWindow() {
    this.activeModal.close()
    }

}
