import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CompanyInfoService } from '../../../services';
import { DataServiceService } from '../../../services/data-service.service';
import { formatValue } from '../../../utilities/numberFormatting.util';
@Component({
  selector: 'app-screener',
  templateUrl: './screener.component.html',
  styleUrls: ['./screener.component.scss']
})
export class ScreenerComponent implements OnInit {
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;


  @Input() noblocktitle = false;
  click$ = new Subject<string>();
  focus$ = new Subject<string>();

  public model: any; //no idea wtf this does
  loadingScreen = false;
  language = 'eng';
  completedScreen;
  currentScreen: any = { queries: [], country: 'All', sector: 'All', year: '2022E' };
  mappingArray = [];
  mapping: any = {
    'pe': false,
    'pb': false,
    'ev_ebit': false,
    'fcf_yield': false,
    'div_yield': false,
    'mc': false,
    'roce': false,
    'net_debt_ebitda': false,
    'con_pt_range': false,
    'cus_pt_range': false,
  }; // this is just an initialization for anything until the read mapping object is loaded
  companies = [];
  countries = [];
  sectors = [];
  years = [];
  listOfCMSCompanies = [];
  sortDirection = -1;
  sortIndex = 0;

  showError = false;
  /**
   *
   *
- P/E
- P/B
- EV/EBIT

- FCF Yield
- Dividend Yield
- Market Cap

- ROCE
- Net debt / EBITDA
- Up- / Downside AResearch

- Up- / Downside Cons PT

   */

  keyNamesForButtons = {
    'pe': false,
    'pb': false,
    'ev_ebit': false,
    'fcf_yield': false,
    'div_yield': false,
    'mc': false,
    'roce': false,
    'net_debt_ebitda': false,
    'con_pt_range': false,
    'cus_pt_range': false,
  }


  keysForButton = []
  allowedKeys = ['mc', 'free_float', 'region_sales_A_c', 'region_sales_B_c', 'region_sales_C_c', 'region_sales_D_c', 'sales',
    'net_pr_rep_c', 'goodwill_c', 'shareh_eq_c', 'con_pt_range', 'cus_pt_range', 'pe', 'pcf', 'pb', 'div_yield', 'fcf_yield', 'ev_sales',
    'ev_ebitda', 'ev_ebit', 'margin_gross', 'margin_ebitda', 'margin_ebit', 'roe', 'roce', 'net_gearing', 'net_debt_ebitda', 'sales_emp',
    'dcf_beta', 'dcf_share_c', 'cus_rating_b', 'con_rating_b'];

  nameOverrides = {
    'mc': 'Market Cap in EURm',
    'free_float': 'Free Float in %',
    'cus_rating': 'AlsterResearch Rating in EUR',
    'region_sales_A_c': 'Domestic sales in %',
    'region_sales_B_c': 'Europe (ex domestic) sales in %',
    'region_sales_C_c': 'The Americas sales in %',
    'region_sales_D_c': 'Asia sales %',
    'sales': 'Sales in EURm',
    'net_pr_rep_c': 'Net margin in %',
    'goodwill_c': 'Goodwill in % of total assets',
    'shareh_eq_c': 'Equity ratio in %',
    'con_pt_range': 'Up-/Downside Cons PT in %',
    'cus_pt_range': 'Up-/Downside AResearch PT in %',
    'div_yield': 'Dividend yield in %',
    'fcf_yield': 'FCF yield in %',
    'margin_gross': 'Gross margin in %',
    'margin_ebitda': 'EBITDA margin in %',
    'margin_ebit': 'EBIT margin in %',
    'roe': 'ROE in %',
    'roce': 'ROCE in %',
    'net_gearing': 'Net gearing in %',
    'sales_emp': 'Sales per Employee in EURm',
    'dcf_share_c': 'Share of PV - terminal value  in %'
  }

  constructor(private dataService: DataServiceService, private companyInfoService: CompanyInfoService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.listOfCMSCompanies = this.companyInfoService.getListOfCompanies().filter(item => item.name !== 'welcomePageStatic');
    this.dataService.getMapping().then((mapping) => {
      this.mapping = mapping;
      let mappingKeys = Object.keys(mapping);
      for (let key of mappingKeys) {
        let mappingEntry = mapping[key];
        mappingEntry['key'] = key;

        if (typeof this.nameOverrides[key] !== 'undefined') {
          mappingEntry[this.language] = this.nameOverrides[key];
        }
        this.mappingArray.push(mappingEntry);
      }


      for (let usedKey of Object.keys(this.keyNamesForButtons)) {

        let tmp = undefined
        tmp = this.mappingArray.find(v => v.key === this.keyNamesForButtons[usedKey])
        if (tmp !== undefined) {
          this.keysForButton.push(tmp)
        }
      }
    });

    this.dataService.getFinancialsNew('SRT3:GR').subscribe(res => {
      this.years = (res as any).financial_figures.profit_and_loss.dates.map(item => item.date)
    })

    this.dataService.getCompanySectors().subscribe(res => {
      this.sectors = res as any;
      this.sectors.unshift('All');
    })

    this.dataService.getCompanyCountries().subscribe(res => {
      this.countries = res as any;
      this.countries.unshift('All');
    })
  }

  getScreen() {
    this.showError = false;
    this.loadingScreen = true;
    this.validateScreen(this.currentScreen);
    this.completedScreen = JSON.parse(JSON.stringify(this.currentScreen));
    this.completedScreen.year = this.completedScreen.year.replace('E', '').replace('e', '').replace('P', '').replace('p', '');
    for (let query of this.completedScreen.queries) {
      let key = query.key;
      if (this.mapping[key].unit === 'PERCENT') {
        query.$gte = query.$gte / 100;
        query.$lte = query.$lte / 100;
      }
    }

    this.dataService.getScreen(this.completedScreen).toPromise().then(result => {
      this.companies = [];
      for (let company of result as Array<any>) {
        let cmsCompany = this.listOfCMSCompanies.find(e => e.bloombergTicker === company.CompanyId);
        if (typeof cmsCompany !== 'undefined' && !cmsCompany.hidden) {
          this.companies.push(company);
        }
      }

      for (let company of this.companies) {
        for (let query of this.completedScreen.queries) {
          company[query.key] = this.getFormattedValue(query, company);
        }
      }

      if (this.completedScreen.queries.length > 0) {
        this.sortCompanies(this.completedScreen.queries[this.sortIndex].key, this.sortIndex, true);
      }

      this.loadingScreen = false;
      this.cdr.detectChanges();
    }, rejected => {
      this.loadingScreen = false;
      this.showError = true;
    })
  }

  validateScreen(screen) {
    for (let query of screen.queries) {
      if (query.$gte === null) {
        query.$gte = 0;
      } else if (query.$lte === null) {
        query.$lte = 0;
      }
    }
  }

  sortCompanies(key, index, initial) {
    if (this.sortIndex === index && !initial) {
      this.sortDirection = -this.sortDirection;
    } else {
      this.sortIndex = index;
      this.sortDirection = -1;
    }

    this.companies.sort((a, b) => {
      let val1 = a[key].replace(/%/g, '').replace(/x/g, '').replace(/,/g, '').replace(/ /g, '');
      let val2 = b[key].replace(/%/g, '').replace(/x/g, '').replace(/,/g, '').replace(/ /g, '');

      if (key === 'cus_rating_b' || key === 'con_rating_b') {
        if (val1 === 'BUY') val1 = 1;
        else if (val1 === 'HOLD') val1 = 0;
        else if (val1 === 'SELL') val1 = -1;

        if (val2 === 'BUY') val2 = 1;
        else if (val2 === 'HOLD') val2 = 0;
        else if (val2 === 'SELL') val2 = -1;
      }

      return this.sortDirection * (val1 - val2);
    })
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(20), distinctUntilChanged());
    // const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => this.mappingArray
        .filter(v => v[this.language].toLowerCase().indexOf(term.toLowerCase()) > -1)
        .filter(v => this.allowedKeys.includes(v.key)))
      // .slice(0,10))
    );
  }

  addSearchKey(mappingEntry): void {
    //$event.preventDefault() for typeahead

    let key = mappingEntry.key;
    if (this.keyNamesForButtons[mappingEntry.key] !== undefined) {
      this.keyNamesForButtons[mappingEntry.key] = !this.keyNamesForButtons[mappingEntry.key]
    }

    let mapping = this.mapping[key];

    let duplicate = this.currentScreen.queries.map((e) => { return e.key; }).indexOf(key)
    if (duplicate >= 0) {
      this.currentScreen.queries.splice(duplicate, 1);
    } else {
      let condition = {
        name: mapping[this.language],
        key: key,
        $gte: -9999999,
        $lte: 9999999
      };

      if (key === 'cus_rating_b' || key === 'con_rating_b') {
        condition.$gte = -1;
        condition.$lte = 1;
      }
      this.currentScreen.queries.push(condition)
    }


  }

  addSearchKeyWithTypeahead($event): void {
    $event.preventDefault()
    this.addSearchKey($event.item)
  }


  deleteQueryObj(index) {
    this.currentScreen.queries.splice(index, 1)
  }

  getFormattedValue(query, company) {
    let mapping = this.mapping[query.key];
    let path = mapping.path.replace('companyData.', '');
    let value = path.split('.').reduce((o, i) => o[i], company)

    if (query.key === 'cus_rating_b' || query.key === 'con_rating_b') {
      if (value === 1) value = 'BUY';
      else if (value === 0) value = 'HOLD';
      else if (value === -1) value = 'SELL';
    }

    return formatValue(value, mapping, this.language, 0);
  }

  parseToCSV() {
    let items = this.badDeepClone(this.companies)
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here

    items = this.cleanJSON(items)

    let header = Object.keys(items[0])
    header.unshift(header[header.length - 1])
    header.pop()
    header = this.translateCSVHeader(header)

    const csv = [
      header.map((el: any) => el.name).join(','), // header row first
      ...items.map(row => header.map((fieldName: any) => JSON.stringify(row[fieldName.key], replacer)).join(','))
    ].join('\r\n')

    var blob = new Blob([csv], { type: 'text/csv' })
    saveAs(blob, "screener_" + this.completedScreen.year + '_' + this.completedScreen.country + "_" + this.completedScreen.sector + ".csv");

  }
  isNested(obj) {
    if (obj === undefined || obj === null || typeof obj === 'string') return false
    if (typeof obj !== 'string' || Object.keys(obj)[0] === "0") return true

  }

  cleanJSON(itemsArr) {

    for (let item of itemsArr) {
      delete item['_id']
      item['Bloomberg Ticker'] = item['CompanyId']
      delete item['CompanyId']
      for (const [key, value] of Object.entries(item)) {
        if (this.isNested(value)) {
          delete item[key]
        }
      }
    }

    return itemsArr
  }

  badDeepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
  }

  translateCSVHeader(headerArr) {

    for (let i = 0; i < headerArr.length; i++) {
      let tmpHeader = this.completedScreen.queries.find(el => el.key === headerArr[i])
      let oldHeader = headerArr[i]
      if (tmpHeader !== null && tmpHeader !== undefined) {
        headerArr[i] = {
          name: tmpHeader.name,
          key: oldHeader
        }
      } else {
        headerArr[i] = {
          name: oldHeader,
          key: oldHeader
        }
      }

    }

    return headerArr
  }







  searchCountry: OperatorFunction<string, readonly { id, name }[]> = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 2),
    map(term => this.countries.filter(country => new RegExp(term, 'mi').test(country)).slice(0, 25))
  )






}
