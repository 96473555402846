import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-screener',
  templateUrl: './stock-screener.component.html',
  styleUrls: ['./stock-screener.component.scss']
})
export class StockScreenerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
