import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-horizontal-nav-panel',
  templateUrl: './horizontal-nav-panel.component.html',
  styleUrls: ['./horizontal-nav-panel.component.scss']
})
export class HorizontalNavPanelComponent implements OnInit {

  @ViewChildren("DropdownNavigation") Dropdowns: any;

  lastScrollPositionAnchor = "investmentcase"
  public navigationPoints = [
    {
      name: "Overview",
      jumpmark:"overview",
      inView: true,
      subPoints: [
      ]
    },
    {
      name: "Research",
      jumpmark:"research",
      inView: false,
      subPoints: [
        {
          name: "Archive",
          jumpmark:"research-archive",
          inView: false
        },
      ]
    },
    {
      name: "Key Figures",
      jumpmark: "keyfigures",
      inView: false,
      subPoints: []
    },
    {
      name: "Investment Case",
      jumpmark: "investmentcase",
      inView: false,
      subPoints: []
    },
    {
      name: "SWOT-Analysis",
      jumpmark:"swot",
      inView: false,
      subPoints:[]
    },
    {
      name: "Valuation",
      jumpmark:"valuation",
      inView: false,
      subPoints: [
        {
          name: "DCF Model",
          jumpmark:"valuation-dcf-model",
          inView: false
        },
        {
          name: "Adj. FCF yield",
          jumpmark:"valuation-fcf-yield",
          inView: false
        },
        {
          name: "Trading Multiples",
          jumpmark:"valuation-trading-multiples",
          inView: false
        },
      ]
    },

    {
      name: "Financials",
      jumpmark:"financials",
      inView: false,
      subPoints: [
        {
          name: "Profit and loss",
          jumpmark:"financials-profit-and-loss",
          inView: false
        },
        {
          name: "Balance sheet",
          jumpmark:"financials-balance-sheet",
          inView: false
        },
        {
          name: "Cash flow statement",
          jumpmark:"financials-cash-flow-statement",
          inView: false
        },
        {
          name: "Ratios",
          jumpmark:"financials-ratios",
          inView: false
        },
        {
          name: "Regional split",
          jumpmark:"financials-regional-split",
          inView: false
        },
      ]
    }]




  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private cdr:ChangeDetectorRef
  ) {
    this.viewportScroller.setOffset([250,250])
  }


  scrollIntoView(target) {
    //this.router.navigate([], { fragment: target });
    if (target !== "overview") {
      this.viewportScroller.scrollToAnchor(target)
    } else {
      this.viewportScroller.scrollToPosition([0, 0])
    }

  }

  ngOnInit(): void {
    window.addEventListener('scroll', () => {
      setTimeout(()=>this.checkIfInView(),1)
    })
  }

  checkIfInView() {

       for (let item of this.navigationPoints) {
        let elem = document.getElementById(item.jumpmark)
        if (elem) {
          const bounding = elem.getBoundingClientRect();
          // console.log(elem)
          // console.log(bounding)
          // console.log("window.innerHeight",window.innerHeight)
          // console.log("window.innerWidth",window.innerWidth)
          // console.log("document.documentElement.clientHeight",document.documentElement.clientHeight)
          // console.log("window.innerHeight", document.documentElement.clientWidth)
          // console.log("(window.innerHeight || document.documentElement.clientHeight)", (window.innerHeight || document.documentElement.clientHeight))
          if (item.jumpmark === "overview") {
            if (
              bounding.top >= 0 &&
              bounding.left >= 0 &&
              bounding.bottom  * 0.5 <= (window.innerHeight || document.documentElement.clientHeight)
              )
            {
              this.lastScrollPositionAnchor = item.jumpmark
              item.inView = true
            } else {
              item.inView = false
            }
          } else {
            if (
              bounding.top >= 0 &&
              bounding.left >= 0 &&
              bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
              )
            {
              this.lastScrollPositionAnchor = item.jumpmark
              item.inView = true
            } else {
              item.inView = false
            }
          }
        }
       }

    if (this.navigationPoints.map((item) => item.inView).every(value => value === false)) {
      this.navigationPoints.map((item)=>{if(item.jumpmark=== this.lastScrollPositionAnchor){item.inView=true}})
    }



    this.cdr.detectChanges()
  }


  handleHoverDropdown($event, open, index) {
    $event.stopPropagation();
    if (open) {
      this.Dropdowns._results[index].open()
    } else {
      this.Dropdowns._results[index].close()
    }
    this.closeAllOtherDropdowns(index)
  }

  closeAllOtherDropdowns(index) {
    for (let i = 0; i < this.Dropdowns._results.length; i++) {
      if (i !== index) {
        this.Dropdowns._results[i].close();
      }
    }
  }

}
