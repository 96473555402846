<div class="row ">
  <div *ngIf="event" class="col-12 col-md-8 shadow mb-5 p-3 rounded bg-white">
    <div class="col-12 p-2 bottomLine">
      <div>{{event.date |date:'MMM dd, yyyy, HH:mm':timeZoneFormat(event.date)}}</div>
      <h4 class="mt-2">{{event.title}}</h4>
    </div>
    <div *ngIf="listType !== 2" class="col-12 p-2 bottomLine">
      <ng-container *ngIf="inviteLink">
        <a class="btn btn-link " href="{{inviteLink}}" target="_blank">invite your investors<i
          class="ml-1 fas fa-arrow-right"></i></a>
          <button class="ml-3 btn btn-sm btn-inverted-gray" (click)="copyTextToClipboard(inviteLink)">
            <svg style="vertical-align: text-bottom !important;" xmlns="http://www.w3.org/2000/svg" width="16"
              height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
              <path
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
              <path
                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
            </svg><span class="ml-1">copy link</span>
          </button>
      </ng-container>
    </div>
    <div *ngIf="listType !== 3" class="col-12 p-2 bottomLine">
      <ng-container *ngIf="listType === 1 && event.meetingLink">
        <a target="_blank" href="{{event.meetingLink}}" class="btn btn-link ">Join event here<i
            class="ml-1 fas fa-arrow-right"></i></a>
        <!-- <button class="btn btn-sm btn-light" (click)="copyTextToClipboard(event.meetingLink)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard"
              viewBox="0 0 16 16">
              <path
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
              <path
                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
            </svg>copy link
          </button> -->
      </ng-container>
      <ng-container *ngIf="listType === 2">
        <ng-container *ngIf="event.presentationURL; else noPresentationYet">
          <a class="btn btn-link " [href]="[event.presentationURL]" target="_blank">download presentation<i
              class="ml-1 fas fa-arrow-right" ></i>
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="listType === 2 && event.showFeedback && event.feedbackLink">
          <a class="btn btn-link " [href]="[event.feedbackLink]" target="_blank">read feedback<i
              class="ml-1 fas fa-arrow-right" ></i>
          </a>
      </ng-container>
    </div>

    <div class="col-12 p-2"></div>
    <div class="col-12 p-2">
      <span (click)="toggleExpand(0)" class="fingerFix">
        <i class="fa fa-chevron-up pr-2" *ngIf="expanded[0]"></i>
        <i class="fa fa-chevron-down pr-2" *ngIf="!expanded[0]"></i>
        <span style="font-weight:400;line-height: 1.4;"><strong>{{event.subscribers.length}} Registered
            Investors</strong></span>
      </span>
      <div class="table-responsive pt-2">
        <table class="table table-hover " [ngClass]="{'is-hidden': !expanded[0]}">
          <thead>
            <tr>
              <th (click)="sortPeople('subscribers', 'firstName')">First Name <small class="text-muted"><i
                    class="fa fa-chevron-down"></i></small></th>
              <th (click)="sortPeople('subscribers', 'lastName')">Last Name <small class="text-muted"><i
                    class="fa fa-chevron-down"></i></small></th>
              <th (click)="sortPeople('subscribers', 'company')">Company <small class="text-muted"><i
                    class="fa fa-chevron-down"></i></small></th>
              <th (click)="sortPeople('subscribers', 'investorProfile')">Profile <small class="text-muted"><i
                    class="fa fa-chevron-down"></i></small></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let subscriber of event.subscribers">
              <td>{{subscriber.firstName}}</td>
              <td>{{subscriber.lastName}}</td>
              <td>{{subscriber.company| slice:0:30}}</td>
              <td class="fingerFix text-center" *ngIf="personHasProfile(subscriber); else noProfile"
                (click)="openModal(subscriber)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  class="bi bi-file-person" viewBox="0 0 16 16">
                  <path
                    d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                  <path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="listType === 2 && event.participants && event.participants.length >0" class="col-12 p-2"></div>
    <div *ngIf="listType === 2  && event.participants && event.participants.length >0" class="col-12 p-2">
      <span (click)="toggleExpand(1)" class="fingerFix">
        <i class="fa fa-chevron-up pr-2" *ngIf="expanded[1]"></i>
        <i class="fa fa-chevron-down pr-2" *ngIf="!expanded[1]"></i>
        <span style="font-weight:400;line-height: 1.4;"><strong>{{event.participants.length}} Participants</strong></span>
      </span>
      <div class="table-responsive pt-2">
        <table class="table table-hover " [ngClass]="{'is-hidden': !expanded[1]}">
          <thead>
            <tr>
              <th (click)="sortPeople('participants', 'firstName')">First Name <small class="text-muted"><i
                    class="fa fa-chevron-down"></i></small></th>
              <th (click)="sortPeople('participants', 'lastName')">Last Name <small class="text-muted"><i
                    class="fa fa-chevron-down"></i></small></th>
              <th (click)="sortPeople('participants', 'company')">Company <small class="text-muted"><i
                    class="fa fa-chevron-down"></i></small></th>
              <th (click)="sortPeople('participants', 'investorProfile')">Profile <small class="text-muted"><i
                    class="fa fa-chevron-down"></i></small></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let participant of event.participants">
              <td>{{participant.firstName}}</td>
              <td>{{participant.lastName}}</td>
              <td>{{participant.company| slice:0:30}}</td>
              <td class="fingerFix text-center" *ngIf="personHasProfile(participant); else noProfile"
                (click)="openModal(participant)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  class="bi bi-file-person" viewBox="0 0 16 16">
                  <path
                    d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                  <path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- <div class="col-1"></div> -->
  <div *ngIf="event" class="d-none d-md-block col-12 col-md-3 mx-auto  shadow mb-5 p-3 rounded bg-white" style="max-height:227px">
    <div class="d-flex" *ngIf="listType === 2 && event.videoURL;else noVideoYet">
      <!-- Video section below-->
      <div id="modalClickerOverlay" *ngIf="event.videoURL" (click)="openVideoModal(event.videoURL)" style=" cursor: pointer; position:absolute; top:0; right:0; width:100%; height:100%;
        z-index:1049;"></div>
      <app-responsive-video class="fitVideoInTile" [videoURL]="event.videoURL"></app-responsive-video>
    </div>
  </div>
</div>



<ng-template #noVideoYet>
  <div class="center-horizontally">
  <a class="btn btn-link " style="color: black !important; pointer-events: none;">If you have opted for a video, it will
    be uploaded here soon.</a>
  </div>
</ng-template>

<ng-template #noPresentationYet>
  <a class="btn btn-link " style="color: black !important; pointer-events: none;">If you have provided a presentation,
    it will be uploaded here soon.</a>
</ng-template>

<ng-template #noProfile>
  <td></td>
</ng-template>