<div class="create-event-wrapper container-fluid">
  <div class="row py-5">
    <div class="col-11">
      <h3>Create and Download a full backup of your data</h3>
    </div>
    <div class="col-1">
      <h5 class="fingerFix" (click)="activeModal.dismiss('deny')"><strong>X</strong></h5>
    </div>
  </div>
  <p>Opens a new tab and downloads all data as a password-protected zip-file. Ask your administrator for the password
    and
    save is in a secure way.</p>
  <p>The backup includes all data from this hub and the Word/Excel-addin.</p>
  <hr>
  <div class="row py-5 mx-auto text-center ">
    <a class="btn btn-primary mx-auto " href="{{downloadLink}}" target="_blank" color="primary">Download Backup</a>
  </div>
</div>
