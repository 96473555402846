import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  @Input() status: false;
  @Input() reroute: false;
  @Input() rerouteURL: '';
  data = { userName: "", password: "" };
  showError = false;
  constructor(

    private authSerice: AuthService,
    public activeModal: NgbActiveModal,
    private router:Router
  ) {
    //console.log(this.status)
   }

  login() {
    this.showError = false;
    this.authSerice.login(this.data).then(
      () => {
        
        if(this.reroute){
          this.router.navigate([this.rerouteURL]);
          this.activeModal.dismiss('login');
        }else{
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }


      }, err => { this.showError = true; }

    );

  }

  logOff() {
    this.authSerice.logOff();
    this.activeModal.dismiss('logOff');
    this.router.navigate([''])

  }



  ngOnInit(): void {
  }

}
