import { AlsterUser } from "src/app/models";

export interface IssuerUser extends AlsterUser {
  firstName: string,
  lastName: string,
  email: string,
  bloombergTicker: string,
  companyName: string,
}


export const emptyIssuerUser = (): IssuerUser => ({
  _id: null,
  userId: null,
  userName: null,
  type: null,
  password: null,
  passwordB: null,
  addinIds: [],
  rights: null,
  editing: null,
  token: null,
  firstName: null,
  lastName: null,
  email: null,
  bloombergTicker: null,
  companyName: null,
});