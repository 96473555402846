
  <div class="uploadfilecontainer text-center p-4" (click)="fileDropRef.click()"  appDragNDropFiles (fileDropped)="addFile($event)">
    <input hidden type="file"#fileDropRef id="fileDropRef" (change)="addFile($event.target.files)" multiple>
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
      <g fill="#3B454F" fill-rule="nonzero">
        <path
          d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
        <path
          d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
        <path
          d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
      </g>
    </svg>
    <h3>Drag and drop files here</h3>
    <h3>or</h3>
    <label for="fileDropRef">click here to browse for files</label>
  </div>

  <div class="row">
    <div class="col-12" >
      <div class="row files-list p-3" *ngFor="let file of files;let i=index">
        <div class="col-3">{{file.name}}</div>
        <div class="col-2">{{ formatBytes(file?.size) }}</div>
        <div class="col-2 text-center">
          <span *ngIf="file.uploadStartet && !file.uploadComplete">uploading...</span>
          <span *ngIf="file.uploadComplete && file.statusMessage === 'ok'">upload complete!</span>
          <span *ngIf="file.uploadComplete && file.statusMessage !== 'ok'">upload canceled</span>
        </div>
        <div class="col-4  text-center">
          <span *ngIf="file.statusMessage !== 'ok'" style="color:red">{{file.statusMessage }}</span>
          
        </div>
        <div class="col-1 text-end text-right">
          <button class="delete-file" (click)="deleteSelectedFile(i)">
            <span class="fa mr-1 text-muted">
              X
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 text-center">
      <button *ngIf="files.length > 0 && !uploadsComplete" (click)="startUpload()" class="btn btn-std">Upload {{files.length}} files now</button>
      <button *ngIf="files.length > 0 && uploadsComplete" class="btn btn-std disabled notInteractive">All uploads complete</button>
      <button *ngIf="files.length < 1" class="btn btn-std disabled notInteractive">Upload files now</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <p>{{statusMessage}}</p>
    </div>
  </div>

