import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    // add default auth for alster-api
    request = request.clone({
      headers: new HttpHeaders({
        "authorization": "41bdbE1uTZADpagZWfK1",
      }),
    })
    // add authorization header with jwt token if available
    const authService = this.injector.get(AuthService);
    const currentToken = authService.currentToken;
    if (currentToken) {
      request = request.clone({
        headers: new HttpHeaders({
          'x-auth-token': currentToken as any, // TODO: remove as any
          "authorization": "41bdbE1uTZADpagZWfK1",
        }),
      });
    }

    return next.handle(request);
  }
}
