import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsletterSubscribeComponent } from 'src/app/basic-pages/modals/newsletter-subscribe/newsletter-subscribe.component';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonsComponent {
  constructor(private modalService: NgbModal) {

  }


  openSubscription(emailMode) {
    const modalRef = this.modalService.open(NewsletterSubscribeComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.emailMode = emailMode // delete this when new forms are used in production
    modalRef.componentInstance.formMode = 'newsletter'
  }

}
