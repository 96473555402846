import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoModalComponent } from 'src/app/basic-pages/modals/video-modal/video-modal.component';
import { timeZoneFormat } from 'src/app/events/utils/event.utils';
import { SingleEvent } from 'src/app/models/event.model';
import { CompanyInfoService, DataServiceService } from 'src/app/services';

@Component({
  selector: 'app-event-archive',
  templateUrl: './event-archive.component.html',
  styleUrls: ['./event-archive.component.scss']
})
export class EventArchiveComponent implements OnInit {
  timeZoneFormat = timeZoneFormat
  public more: boolean = false;

  @Input() adminMode;
  constructor(
    private modalService: NgbModal, 
    private dataService: DataServiceService,
    private companyInfoService:CompanyInfoService) { }

  events = [
    { title: "Title", date: "", videoURL: "https://youtu.be/neV3EPgvZ3g", researchURL: "", presentationURL: "" }
  ]
  myEvents = []

  ngOnInit(): void {
    this.getAllEvents()
  }

  getAllEvents() {
    this.dataService.getAllEvents().subscribe((res:any) => {
      this.myEvents = []
      this.myEvents = res.data as Array<SingleEvent>
      this.myEvents = this.myEvents.sort((b, a) => new Date(a.date).getTime() - new Date(b.date).getTime())
      this.myEvents = this.myEvents.filter(el => el.isArchived === true)
      this.myEvents.map(event=>{
        if(
          this.companyInfoService
            .getListOfCompanies()
              .map(
                company=>{
                  return company.bloombergTicker
                })
                  .includes(event.bloombergTicker)){
                    event.isCovered = true
                  }else{
                    event.isCovered = false
                  
                  }
      })
      this.events = this.myEvents
    })
  }


  openVideoModal(videoURL) {
    const modalRef = this.modalService.open(VideoModalComponent, { centered: true, size: 'lg', windowClass: 'biggerModal' });
    modalRef.componentInstance.videoURL = videoURL
  }

}
