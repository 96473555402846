import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { BaseButton } from '../base-button';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkButtonComponent extends BaseButton {
  @Input() public link = '';
}
