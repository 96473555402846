<div class="table-wrapper container py-0 my-0 customFont">
  <div class="row py-0 my-0">
    <div class="col-12 mx-0 px-0 ">
      <div class="table-responsive mx-auto mt-2">
        <div>
          <table class="table table-sm table-hover bilanzkennzahlen">
            <tbody>
              <tr>
                <th class="tabHeader">
                  DCF ({{companyData?.base?.curr}}m)<br>
                  (except per share data and beta)
                </th>
                <th class="tabHeader">
                  {{ dcfData.HL_dcf.value[1] }}
                </th>
                <th class="tabHeader">
                  {{ dcfData.HL_dcf.value[2] }}
                </th>
                <th class="tabHeader">
                  {{ dcfData.HL_dcf.value[3] }}
                </th>
                <th class="tabHeader">
                  {{ dcfData.HL_dcf.value[4] }}
                </th>
                <th class="tabHeader">
                  {{ dcfData.HL_dcf.value[5] }}
                </th>
                <th class="tabHeader" *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.HL_dcf.value[6] }}
                </th>
                <th class="tabHeader" *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.HL_dcf.value[7] }}
                </th>
                <th class="tabHeader" *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.HL_dcf.value[8] }}
                </th>
                <th class="tabHeader">
                  Terminal
                  <bR>value
                </th>
              </tr>
              <tr>
                <th>
                  {{ dcfData.RW_dcf_nopat.key }}
                </th>
                <td>
                  {{ dcfData.RW_dcf_nopat.value[1] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_nopat.value[2] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_nopat.value[3] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_nopat.value[4] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_nopat.value[5] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_nopat.value[6] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_nopat.value[7] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_nopat.value[8] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                </td>
              </tr>
              <tr>
                <th>
                  {{ dcfData.RW_dcf_depr.key }}
                </th>
                <td>
                  {{ dcfData.RW_dcf_depr.value[1] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_depr.value[2] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_depr.value[3] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_depr.value[4] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_depr.value[5] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_depr.value[6] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_depr.value[7] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_depr.value[8] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                </td>
              </tr>
              <tr>
                <th>
                  {{ dcfData.RW_dcf_chg_wc.key }}
                </th>
                <td>
                  {{ dcfData.RW_dcf_chg_wc.value[1] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_wc.value[2] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_wc.value[3] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_wc.value[4] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_wc.value[5] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_wc.value[6] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_chg_wc.value[7] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_chg_wc.value[8] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                </td>
              </tr>
              <tr>
                <th>
                  {{ dcfData.RW_dcf_chg_long_prov.key }}
                </th>
                <td>
                  {{ dcfData.RW_dcf_chg_long_prov.value[1] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_long_prov.value[2] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_long_prov.value[3] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_long_prov.value[4] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_long_prov.value[5] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_chg_long_prov.value[6] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_chg_long_prov.value[7] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_chg_long_prov.value[8] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                </td>
              </tr>
              <tr>
                <th>
                  {{ dcfData.RW_dcf_capex.key }}
                </th>
                <td>
                  {{ dcfData.RW_dcf_capex.value[1] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_capex.value[2] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_capex.value[3] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_capex.value[4] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_capex.value[5] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_capex.value[6] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_capex.value[7] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_capex.value[8] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                </td>
              </tr>
              <tr>
                <th>
                  {{ dcfData.RW_dcf_cash_flow.key }}
                </th>
                <td>
                  {{ dcfData.RW_dcf_cash_flow.value[1] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_cash_flow.value[2] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_cash_flow.value[3] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_cash_flow.value[4] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_cash_flow.value[5] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_cash_flow.value[6] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_cash_flow.value[7] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_cash_flow.value[8] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_cash_flow.value[9] }}
                </td>
              </tr>
              <tr>
                <th>
                  {{ dcfData.RW_dcf_present_value.key }}
                </th>
                <td>
                  {{ dcfData.RW_dcf_present_value.value[1] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_present_value.value[2] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_present_value.value[3] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_present_value.value[4] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_present_value.value[5] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_present_value.value[6] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_present_value.value[7] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_present_value.value[8] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_present_value.value[9] }}
                </td>
              </tr>
              <tr class="border-bottom">
                <th>
                  {{ dcfData.RW_dcf_wacc.key }}
                </th>
                <td>
                  {{ dcfData.RW_dcf_wacc.value[1] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_wacc.value[2] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_wacc.value[3] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_wacc.value[4] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_wacc.value[5] }}
                </td>
                <td>
                  {{ dcfData.RW_dcf_wacc.value[6] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_wacc.value[7] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_wacc.value[8] }}
                </td>
                <td *ngIf="dcfType !== 'Italy'">
                  {{ dcfData.RW_dcf_wacc.value[9] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row ">
    <div class="col-lg-5 col-md-12 px-0">
      <!-- <app-table-notabs [companyId]="companyData.base.CompanyId" [decimals]=1 [templateName]="dcf_left"
                [companyData]="companyData">
            </app-table-notabs> -->
      <div class="table-responsive mx-auto mt-2">
        <div>
          <table class="table table-sm table-hover bilanzkennzahlen">
            <tbody>
              <tr>
                <th class="tabHeader">
                  DCF per share derived from
                </th>
                <td class="tabHeader">
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_tpv.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_tpv.value }}
                </td>
              </tr>
              <tr *ngIf="dcfType !== 'Italy'">
                <th class="">
                  {{ dcfData.dcf_mid_yr_adj.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_mid_yr_adj.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_net_debt.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_net_debt.value }}
                </td>
              </tr>
              <tr *ngIf="dcfType !== 'Italy'">
                <th class="">
                  {{ dcfData.dcf_fin_asset.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_fin_asset.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_prov.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_prov.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_eq_value.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_eq_value.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_nosh.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_nosh.value }}
                </td>
              </tr>
              <tr class="border-bottom">
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th class="rowHighlight">
                  {{ dcfData.dcf_dcfps.key }}
                </th>
                <td class="rowHighlight">
                  {{ dcfData.dcf_dcfps.value |
                  number:'1.2-2' }}
                </td>
              </tr>
              <tr class="border-bottom">
                <th class="rowHighlight">
                  {{ dcfData.dcf_upside.key }}
                </th>
                <td class="rowHighlight">
                  {{ dcfData.dcf_upside.value }}
                </td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
              <tr style="height:21px !important"></tr>
              <tr style="margin-top:15px">
                <th class="tabHeader">
                  {{ dcfData.dcf_share_price.key }}
                </th>
                <td class="tabHeader" *ngIf="companyData?.quotes">
                  {{ companyData?.quotes[companyData?.quotes?.length-1].close_for_show |
                  number:'1.2-2' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-0 px-0"></div>
    <div class="col-lg-6 col-md-12 px-0">
      <!-- <app-table-notabs [companyId]="companyData.base.CompanyId" [decimals]=1 [templateName]="dcf_right"
                [companyData]="companyData">
            </app-table-notabs> -->
      <div class="table-responsive mx-auto mt-2">
        <div>
          <table class="table table-sm table-hover bilanzkennzahlen">
            <tbody>
              <tr>
                <th class="tabHeader">
                  DCF avg. growth and earnings assumptions
                </th>
                <td class="tabHeader">
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_plan_hor.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_plan_hor.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_term_val_gro.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_term_val_gro.value }}
                </td>
              </tr>
              <tr class="border-bottom">
                <th class="">
                  {{ dcfData.dcf_term_val_wacc.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_term_val_wacc.value }}
                </td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th class="tabHeader">
                  Terminal WACC derived from
                </th>
                <td class="tabHeader">
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_cost_borr.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_cost_borr.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_long_term_tax_rate.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_long_term_tax_rate.value }}
                </td>
              </tr>
              <tr *ngIf="dcfType !== 'Italy'">
                <th class="">
                  {{ dcfData.dcf_beta.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_beta.value }}
                </td>
              </tr>
              <tr *ngIf="dcfType !== 'Italy'">
                <th class="">
                  {{ dcfData.dcf_unlevered_beta.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_unlevered_beta.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_target_debt_equity.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_target_debt_equity.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_relevered_beta.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_relevered_beta.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_rfr.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_rfr.value }}
                </td>
              </tr>
              <tr>
                <th class="">
                  {{ dcfData.dcf_erp.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_erp.value }}
                </td>
              </tr>
              <tr class="border-bottom">
                <th class="">
                  {{ dcfData.dcf_cost_of_debt.key }}
                </th>
                <td class="">
                  {{ dcfData.dcf_cost_of_debt.value }}
                </td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 px-0">
      <div class="table-responsive mx-auto mt-2">
        <div>
          <table class="table table-sm table-hover bilanzkennzahlen">
            <tbody>
              <tr class="border-bottom">
                <th class="tabHeader">
                  Sensitivity analysis DCF
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-2 vertical no-padding">
      Change in WACC
    </div>
    <div class="col-lg-6 col-md-10 no-padding">
      <div class="text-center">
        Long term growth
        <div class="table-responsive mx-auto mt-2">
          <div>
            <table class="table table-sm table-hover bilanzkennzahlen">
              <tbody>
                <tr>
                  <td></td>
                  <td>{{dcfData.sensi_a.value}}</td>
                  <td>{{dcfData.sensi_b.value}}</td>
                  <td>{{dcfData.sensi_c.value}}</td>
                  <td>{{dcfData.sensi_d.value}}</td>
                  <td>{{dcfData.sensi_e.value}}</td>
                </tr>
                <tr>
                  <td>{{dcfData.sensi_f.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_g.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_h.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_i.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_j.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_k.value}}</td>
                </tr>
                <tr>
                  <td>{{dcfData.sensi_l.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_m.value}}</td>
                  <td class="highlight_dcf_2">{{dcfData.sensi_n.value}}</td>
                  <td class="highlight_dcf_2">{{dcfData.sensi_o.value}}</td>
                  <td class="highlight_dcf_2">{{dcfData.sensi_p.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_q.value}}</td>
                </tr>
                <tr>
                  <td>{{dcfData.sensi_r.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_s.value}}</td>
                  <td class="highlight_dcf_2">{{dcfData.sensi_t.value}}</td>
                  <td class="highlight_dcf_1">{{dcfData.sensi_u.value}}</td>
                  <td class="highlight_dcf_2">{{dcfData.sensi_v.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_w.value}}</td>
                </tr>
                <tr>
                  <td>{{dcfData.sensi_x.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_y.value}}</td>
                  <td class="highlight_dcf_2">{{dcfData.sensi_z.value}}</td>
                  <td class="highlight_dcf_2">{{dcfData.sensi_aa.value}}</td>
                  <td class="highlight_dcf_2">{{dcfData.sensi_ab.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_ac.value}}</td>
                </tr>
                <tr>
                  <td>{{dcfData.sensi_ad.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_ae.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_af.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_ag.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_ah.value}}</td>
                  <td class="highlight_dcf_3">{{dcfData.sensi_ai.value}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-0"></div>
    <div class="col-lg-4 col-md-12">
      <div class="">
        Share of present value
        <div class="table-responsive mx-auto mt-2">
          <div>
            <table class="table table-sm table-hover bilanzkennzahlen">
              <tbody>
                <tr>
                  <th>&nbsp;</th>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <th>{{start}} - {{mid}}</th>
                  <td>{{dcfData.dcf_share_a.value}}</td>
                </tr>
                <tr>
                  <th>{{mid2}} - {{end}}</th>
                  <td>{{dcfData.dcf_share_b.value}}</td>
                </tr>
                <tr>
                  <th>terminal value</th>
                  <td>{{dcfData.dcf_share_c.value}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="text-left" *ngIf="dcfType !== 'Italy'">Source: AlsterResearch</p>
  <p class="text-left" *ngIf="dcfType == 'Italy'">Source: Italian Research</p>
</div>
