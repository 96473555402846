import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { leewayAPI_URL } from 'src/app/app-settings/api-urls';
import { IssuerUser } from 'src/app/corporate-dashboard/models/issuer.model';
import { AlsterUser } from '../../models';
import { ToastService } from '../toast.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _apiUrl = leewayAPI_URL+'tools/';
  apiURL = leewayAPI_URL+"cms/alsteruser/"

  public currentUserName = ""
  private readonly apiToken = 'cqt1bii3tq5fuzo0c5id';

  constructor(private httpClient: HttpClient, private toastService: ToastService, private router:Router) { }
  currentToken = null;
  login(data): Promise<any> {
    return new Promise((resolve, _reject) => {
      this.getToken(data).then(res => {
        if (res !== null) {
          this.saveToken(res);
          resolve(res);
        } else {
          _reject(res);
        }

      }, err => {
        _reject(err);
      });
    });

  }

  logOff(): void {
    window.localStorage.removeItem('rh');
    
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  isAuthenticated(): boolean {
    if (this.CurrentUser && (this.CurrentUser.type === "ADMIN" || this.CurrentUser.type === "CONTRIBUTOR" || this.CurrentUser.type === "ISSUER")) {
      return true
    } else {
      return false;
    }
  }
  loadToken() {
    this.currentToken = window.localStorage.getItem('rh');
    // console.log("TOKEN LOADED")
    //console.log(this.currentToken)
    return this.currentToken
  }

  saveToken(newToken) {
    localStorage.setItem('rh', newToken);
  }

  get CurrentToken(): string {
    return this.currentToken;
  }

  get CurrentUser(): any { // : AlsterUser|IssuerUser
    // we should send the token to the backend and verify it there before decoding here.
    const jwtHelper = new JwtHelperService();
    try {
      let token = this.loadToken()
      if (token === undefined || token === null) {
        return null
      } else {
        let decoded =  jwtHelper.decodeToken(token)
        let tmpUser = decoded.user as AlsterUser|IssuerUser
        //console.log(tmpUser)
        this.currentUserName = tmpUser.userName
        return tmpUser
      }
    } catch (err) {
      console.log(err)
      return null
    }
  }

  getCurrentUserName(){
    return this.CurrentUser?.userName?this.CurrentUser.userName:""
  }
  getcurrentUserCompanyBloombegticker():string{
    return this.CurrentUser?.bloombergTicker?this.CurrentUser.bloombergTicker:""
  }
  getcurrentUserCompanyName():string{
    return this.CurrentUser?.companyName?this.CurrentUser.companyName:""
  }
  getToken(data) {
    return new Promise((resolve, reject) => {
      const body = { username: data.userName, password: data.password }; //
      //console.log(body)
      this.httpClient.post(this.apiURL + 'login?apitoken=cqt1bii3tq5fuzo0c5id', body).toPromise().then((res: any) => {
        const token = res.token;
        resolve(token);
      },
        err => {
          reject(err);
        });
    });
  }

  verifyToken() {
    return new Promise((resolve, reject) => {
      const body = { username: this.CurrentUser.userName }; //
      this.httpClient.post(this.apiURL + 'verify?apitoken=cqt1bii3tq5fuzo0c5id', body).toPromise().then((res: any) => {
        const valid = res.validToken
        if (valid) { resolve(valid) } else {
          this.toastService.show('You recieved new permissions. Please log out and into your account again to use your new permissions.', { classname: 'bg-danger text-light', delay: 150000 });
          resolve(valid)
        }
      })
    })

  }

  isAdmin(): boolean {
    let tmp = this.CurrentUser
    if (tmp && tmp.type === "ADMIN") {
      return true
    } else {
      return false;
    }
  }
  isIssuer(): boolean {
    let tmp = this.CurrentUser
    if (tmp && tmp.type === "ISSUER") {
      return true
    } else {
      return false;
    }
  }
  isContributor(): boolean {
    let tmp = this.CurrentUser
    if (tmp && tmp.type === "CONTRIBUTOR") {
      return true
    } else {
      return false;
    }
  }
    

  canUseAdminPanel() {
    if (this.isAdmin()) { return true; }
    let tmp = this.CurrentUser
    if (tmp && tmp.adminPanel) {
      return true
    } else {
      return false;
    }
  }

  isAllowedCompany(requestedCompanyId) {
    if (this.isAdmin()) { return true; }


    let tmp = this.CurrentUser
    // console.log(tmp)
    if (tmp && tmp.rights.companies.find(item => item.companyId === requestedCompanyId)) {
      return true
    } else {
      return false;
    }
  }

  isAllowedAction(requestedCompanyId, requestedAction) { // requestedAction must be "write", "download", "upload"

    // console.log(requestedCompanyId, requestedAction)
    if (this.isAdmin()) { return true; }
    let tmpUser = this.CurrentUser
    // console.log(tmpUser)
    if (tmpUser) {
      // console.log(tmpUser)
      let tmpCompany = null
      // legacy
      if(tmpUser.allowedCompanies){
        tmpUser.allowedCompanies.find(item => item.companyId === requestedCompanyId)
      }else{
        tmpUser.rights.companies.find(item => item.companyId === requestedCompanyId)
      }

      if (tmpCompany && tmpCompany[requestedAction]) {
        // console.log(tmpCompany)
        // console.log(tmpCompany[requestedAction])
        return true
      }
    }
    else {
      return false;
    }
  }




  getAllUsers() {
    return this.httpClient.get(this.apiURL + 'all?apitoken=cqt1bii3tq5fuzo0c5id')
  }
  changeUserRole(userId, newRole) {
    let body = { userId, newRole }
    return this.httpClient.post(this.apiURL + 'changeuserrole?apitoken=cqt1bii3tq5fuzo0c5id', body).toPromise()
  }
  setAccessToAdminPanel(userId, user) {
    let body = { userId, adminPanel: user.rights.adminPanel }
    //console.log(body)
    return this.httpClient.post(this.apiURL + 'setaccesstoadminpanel?apitoken=cqt1bii3tq5fuzo0c5id', body).toPromise()
  }

  addAllowedCompanies(userId, newAllowedCompany) {
    let body = { userId, company: newAllowedCompany }
    //console.log(body)
    return this.httpClient.post(this.apiURL + 'addallowedcompanies?apitoken=cqt1bii3tq5fuzo0c5id', body).toPromise()
  }
  deleteAllowedCompanies(userId, newAllowedCompany) {
    let body = { userId, company: newAllowedCompany }
  //  console.log(body)
    return this.httpClient.post(this.apiURL + 'deleteallowedcompanies?apitoken=cqt1bii3tq5fuzo0c5id', body).toPromise()
  }
  updateAllowedCompanies(userId, rights) {
    let body = { userId, newRights: rights }
  //  console.log(body)
    return this.httpClient.post(this.apiURL + 'updateallowedcompanies?apitoken=cqt1bii3tq5fuzo0c5id', body).toPromise()
  }

  saveDisclaimerModalToken() {
    localStorage.setItem('rhmodal', JSON.stringify({ "seenDisclaimer": true }));
  }

  checkDisclaimerModalTokenIsNotSet() {

    const token: string = window.localStorage.getItem('rhmodal');

    if (token !== null && token !== undefined) {

      let parsedToken = JSON.parse(token);
      if (parsedToken.seenDisclaimer) {
        return true
      } else {
        return false
      }

    } else {

      return false
    }
  }

}
