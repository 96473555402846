export interface SingleEvent {
  _id?: string,
  companyName: string,
  date: string | Date,
  isArchived: boolean,
  isHidden: boolean,
  isPublished: boolean,
  presentationURL: string,
  title: string,
  videoURL: string,
  sibListId: string,
  sibListIdForParticipants:string,
  meetingLink: string,
  feedbackLink?:string
  showFeedback:boolean,
  bloombergTicker: string
  subscribers?: Array<EventPerson>
  participants?: Array<EventPerson>
  speakers?: Array<EventSpeaker>
  companyLogoURL?: string
  description?: string
}

export interface EventSpeaker{
  name:string,
  imageURL:string,
  position:string
}

export interface EventPerson {
  _id?: string,
  email: string,
  firstName: string,
  lastName: string,
  company: string,
  position: string,
  investorProfile?:InvestorProfile
}

export interface InvestorProfile {
  aum:string,
  size:string,
  holdingPeriod:string,
  investmentStyle:string,
  investorType:string,
  region:string,
  ticketSize:string,
  description:string,
}

export const emptyEventPerson = (): EventPerson => ({
  _id: null,
  email: null,
  firstName: null,
  lastName: null,
  company: null,
  position: null,
  investorProfile:emptyInvestorProfile()
});

export const emptyInvestorProfile = (): InvestorProfile => ({
  aum:null,
  size:null,
  holdingPeriod:null,
  investmentStyle:null,
  investorType:null,
  region:null,
  ticketSize:null,
  description:null,
});

export const emptySingleEvent = (): SingleEvent => ({
  _id: null,
  companyName: null,
  date: null,
  isArchived: null,
  isHidden: null,
  isPublished: null,
  presentationURL: null,
  title: null,
  videoURL: null,
  sibListId: null,
  sibListIdForParticipants:null,
  meetingLink: null,
  feedbackLink:null,
  showFeedback:false,
  bloombergTicker: null,
  subscribers: [emptyEventPerson()],
  participants: [emptyEventPerson()],
  description:null
});