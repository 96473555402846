import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: "root",
})
export class CookieService {
  constructor(private GAService: GoogleAnalyticsService) {}

  public setCookieAccepted(): void {
    this.GAService.gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    });
    localStorage.setItem(
      "cookiePermission",
      JSON.stringify({ accepted: true })
    );
  }

  public clearCookiePermission(): void {
    this.GAService.gtag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
    localStorage.removeItem("cookiePermission");
  }

  public checkCookiePermission(): boolean {
    const cookiePermissionToken: string =
      localStorage.getItem("cookiePermission");
    if (cookiePermissionToken) {
      const cookiePermission = JSON.parse(cookiePermissionToken);
      if (cookiePermission.accepted) {
        return true;
      } else return false;
    } else return false;
  }
}
