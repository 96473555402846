import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-stocks',
  templateUrl: './all-stocks.component.html',
  styleUrls: ['./all-stocks.component.scss']
})
export class AllStocksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
