import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextModalComponent } from 'src/app/basic-pages/modals/text-modal/text-modal.component';
import { DataServiceService } from 'src/app/services';

@Component({
  selector: 'app-rss-feed',
  templateUrl: './rss-feed.component.html',
  styleUrls: ['./rss-feed.component.scss']
})
export class RssFeedComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  public rssFeed: any[];


  constructor(private dataService: DataServiceService, private modalService: NgbModal) { }
  
  public ngOnInit(): void {
    this.initRssFeed();
  }

  public showFullFeed(rss: any): void {
    console.log("showFullFeed ~ rss:", rss);
    const modalRef = this.modalService.open(TextModalComponent, { centered: true, size: 'xl' });
    modalRef.componentInstance.title = rss.title;
    modalRef.componentInstance.text = rss.description;
    
  }
  
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initRssFeed(): void {
    this.dataService.getRSS().pipe(takeUntil(this.destroy$)).subscribe((rss: any[])=>{
      this.rssFeed = rss.reverse().filter((rss)=>{
        return rss.lang === 'en'; // delete filter
      }).slice(0, 100);
      // console.log("this.dataService.getRSS ~ this.rssFeed:", this.rssFeed);
    })
  }
}
