<div class="container-fluid">
  <!-- TradingView Widget BEGIN -->
  <div class="containerWrapper" #chartWidthMeasureElement>
    <div class="tradingview-widget-container">
      <div id="tradingview_bac65"></div>
      <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/{{chartSymbol}}"
          rel="noopener" target="_blank"><span class="blue-text">{{chartSymbol}} Chart</span></a> by TradingView</div>
      <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
      <script type="text/javascript"></script>
    </div>

  </div>
  <!-- TradingView Widget END -->
</div>
