<ngb-carousel [interval]="10000">
  <ng-template ngbSlide>
    <div class="slider">
      <div class="pt-5 pb-5">
        <div class="d-flex col-12 justify-content-between"
             style="gap: 2rem">
          <div>
            <h4><i>Check out...</i></h4>
            <h3>{{ randomCompany?.name }}, {{ randomCompany?.rating }}</h3>
          </div>
          <div class="text-secondary text-nowrap d-none d-lg-block ">
            <h2>
              Existing Coverage
            </h2>
          </div>
        </div>
        <div class="col-12 row justify-content-center justify-content-lg-between p-0 m-0">
          <div class="col-lg-5 col-md-12 row p-0 m-0">
            <div class="bigger description-text-box text-break col-12 pt-3">
              {{ descriptionOfRandomCompany }}
            </div>
            <div class="col-12 pr-lg-5 pt-3 pt-lg-0 pb-3 pb-lg-0 d-flex align-items-center justify-content-end">
              <button class="btn btn-std uppercase"
                      [routerLink]="['/companies', randomCompany?.name]"
                      [fragment]="'X'">
                Explore
              </button>
            </div>
          </div>

          <div class="col-lg-7 d-lg-flex d-none justify-content-center align-items-center slider-image">
            <img class="mh-100 mw-100"
                 loading="lazy"
                 [src]="randomCompany?.imageURL"
                 alt="ResearchHub" />
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="slider">
      <div class="pt-5 pb-5">
        <div class="d-flex col-12 justify-content-between"
             style="gap: 2rem">
          <div>
            <h3>{{ nextEvent?.title }}</h3>
            <h4>
              <i>{{ nextEvent?.date | date : "MMM dd, yyyy, HH:mm" :timeZoneFormat(nextEvent?.date)}}</i>
            </h4>
          </div>
          <div class="text-secondary text-nowrap d-none d-lg-block ">
            <h2>
              Next Event
            </h2>
          </div>
        </div>
        <div class="col-12 row justify-content-center justify-content-lg-between p-0 m-0">
          <div class="col-lg-5 col-md-12 row p-0 m-0">
            <div class="bigger description-text-box text-break col-12 pt-3">
              {{
              nextEvent?.description
              }}
            </div>
            <div class="col-12 pr-lg-5 pt-3 pt-lg-0 pb-3 pb-lg-0 d-flex align-items-center justify-content-end">
              <button class="btn btn-std uppercase"
                      (click)="navigateToEventRegistrationPage(nextEvent)">
                Register Now
              </button>
            </div>
          </div>

          <div class="col-lg-7 d-lg-flex d-none justify-content-center align-items-center slider-image">
            <img class="mh-100 mw-100"
                 loading="lazy"
                 [src]="nextEvent?.imageURL"
                 alt="ResearchHub" />
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template *ngIf="customSlideData && customSlideData['title'] !== '#EMPTY'"
               ngbSlide>
    <div class="slider">
      <div class="pt-5 pb-5">
        <div class="d-flex col-12 justify-content-between"
             style="gap: 2rem">
          <div>
            <h3>{{ customSlideData?.title }}</h3>
            <h4>
              <i>{{ customSlideData?.subtitle }}</i>
            </h4>
          </div>
          <div class="text-secondary text-nowrap d-none d-lg-block">
            <h2>
              News
            </h2>
          </div>
        </div>
        <div class="col-12 row justify-content-center justify-content-lg-between p-0 m-0">
          <div class="col-lg-5 col-md-12 row p-0 m-0">
            <div class="bigger description-text-box text-break col-12 pt-3">
              {{
              customSlideData?.description
              }}
            </div>
            <div class="col-12 pr-lg-5 pt-3 pt-lg-0 pb-3 pb-lg-0 d-flex align-items-center justify-content-end">
              <button class="btn btn-std uppercase"
                      (click)="customSlideButtonClick()">
                {{customSlideData?.slider_button}}
              </button>
            </div>
          </div>

          <div class="col-lg-7 d-lg-flex d-none justify-content-center align-items-center slider-image">
            <img class="mh-100 mw-100"
                 loading="lazy"
                 [src]="customSlideData?.imageURL"
                 alt="ResearchHub" />
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template *ngIf="initiationCompany"
               ngbSlide>
    <div class="slider">
      <div class="pt-5 pb-5">
        <div class="d-flex col-12 justify-content-between"
             style="gap: 2rem">
          <div>
            <h4><i>Latest initiation</i></h4>
            <h3>
              {{ initiationCompany?.name }}, {{ initiationCompany?.rating }}
            </h3>
          </div>
          <div class="text-secondary text-nowrap d-none d-lg-block ">
            <h2>
              Welcome to our Coverage
            </h2>
          </div>
        </div>
        <div class="col-12 row justify-content-center justify-content-lg-between p-0 m-0">
          <div class="col-lg-5 col-md-12 row p-0 m-0">
            <div class="bigger description-text-box text-break col-12 pt-3">
              {{ descriptionOfInitiationCompany }}
            </div>
            <div class="col-12 pr-lg-5 pt-3 pt-lg-0 pb-3 pb-lg-0 d-flex align-items-center justify-content-end">
              <button class="btn btn-std uppercase"
                      [routerLink]="['/companies', initiationCompany?.name]"
                      [fragment]="'X'">
                Explore
              </button>
            </div>
          </div>

          <div class="col-lg-7 d-lg-flex d-none justify-content-center align-items-center slider-image">
            <img class="mh-100 mw-100"
                 loading="lazy"
                 [src]="initiationCompany?.imageURL"
                 alt="ResearchHub" />
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="slider">
      <div class="pt-5 pb-5">
        <div class="d-flex col-12 justify-content-between"
             style="gap: 2rem">
          <div>
            <h3>Subscribe here</h3>
            <h4><i>On time and 100% free of charge</i></h4>
          </div>
          <div class="text-secondary text-nowrap d-none d-lg-block ">
            <h2>
              Into your Inbox
            </h2>
          </div>
        </div>
        <div class="col-12 row justify-content-center justify-content-lg-between p-0 m-0">
          <div class="col-lg-5 col-md-12 row p-0 m-0"
               style="gap: 2rem">
            <div class="bigger d-none d-md-block  col-12 pt-3">
              <span>
                Get MiFID II-compliant <b>research and event</b> invitations to
                your inbox.</span>
              <br /><br />
              <span>Join <b>1,700+ professional investors</b> and do not miss out
                on hundreds of updates and events every year.</span>
            </div>
            <div class="bigger d-block d-md-none description-text-box col-12 pt-3">
              <span>
                Get MiFID II-compliant <b>research and event</b> invitations to
                your inbox.</span>
              <span> Join <b>1,700+ professional investors</b> and do not miss out
                on hundreds of updates and events every year.</span>
            </div>
            <div
                 class="col-12 pr-lg-5 pt-3 pt-lg-0 pb-3 pb-lg-0 d-lg-none d-flex flex-wrap ign-items-center justify-content-end">
              <button class="btn btn-std uppercase bigger mx-md-3"
                      (click)="openSubscription()">
                subscribe
              </button>

            </div>
            <div class="col-12 d-lg-flex d-none row align-items-end p-0 m-0 justify-content-between">
              <div class="col ">
                <div class="button-group d-flex align-items-end">
                  <a href="https://de.linkedin.com/company/srh-alsterresearch-ag">
                    <img loading="lazy"
                         src="../../../assets/linkedin.svg"
                         alt="logo linkedin"
                         class="linkedin-logo" />
                  </a>
                  <a href="https://twitter.com/alsterresearch"
                     class="mx-1">
                    <img loading="lazy"
                         src="../../../assets/twitter-logo.svg"
                         alt="logo twitter"
                         class="twitter-logo my-2" />
                  </a>
                </div>
              </div>
              <div class="col-6">
                <div class=" d-lg-flex flex-wrap d-none justify-content-center">
                  <div class="col-12 text-nowrap">
                    <span class="bigger text-muted">Cancel anytime.</span>
                  </div>
                  <div class="button-group col-12 mt-2">
                    <button class="btn btn-std uppercase bigger"
                            (click)="openSubscription()">
                      subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-7 d-lg-flex d-none justify-content-center align-items-center slider-image">
            <img class="mh-100 w-100"
                 loading="lazy"
                 src="../../../assets/subscribe-slide-image.jpg"
                 alt="ResearchHub" />
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>
