<div [ngClass]="{'border border-dark rounded' : adminMode}" id="{{data.blockTag}}">
  <div *ngIf="!notInitalized">
    <app-table-tabs [templateName]="data.currentTemplate" [companyId]="metaBloombergTicker"
      [decimals]="data.numDecimals" [companyData]="metaCompanyData"></app-table-tabs>
  </div>
  <div class="row" *ngIf="adminMode">
    <div class="col-12">
      <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
        [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName" [metaBloombergTicker]="metaBloombergTicker"
        [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData">
      </app-block-handling>
    </div>
  </div>
</div>
