import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import { CompanyInfoService } from "src/app/services";
import { DataServiceService } from "src/app/services/data-service.service";
import { createEventRegistrationLink, timeZoneFormat } from "src/app/events/utils/event.utils";
import { SingleEvent } from "src/app/models/event.model";
import { VideoModalComponent } from "src/app/basic-pages/modals/video-modal/video-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-event-list-lp",
  templateUrl: "./event-list-lp.component.html",
  styleUrls: ["./event-list-lp.component.scss"],
})
export class EventListLpComponent implements OnInit {
  @Input() isEventPage: boolean = false;
  private destroy$ = new Subject<void>();
  public listOfComingEvents: any[];
  public listOfArchiveEvents: any[];
  public more: boolean = false;
  public timeZoneFormat = timeZoneFormat;

  constructor(
    private dataService: DataServiceService,
    private companyInfoService: CompanyInfoService,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  public get isComingEventsMoreThenThree(): boolean {
    return this.listOfComingEvents?.length > 3;
  }

  public ngOnInit(): void {
    this.initArchiveEvents();
    this.initComingEvents();
  }

  public nextPage(target) {
    this.router.navigate(["/" + target]);
  }

  public navigateToEventRegistrationPage(event: SingleEvent): void {
    const route = createEventRegistrationLink(event.bloombergTicker, event.date);
    window.open(route);
  }

  public openVideoModal(videoURL): void {
    const modalRef = this.modalService.open(VideoModalComponent, { centered: true, size: 'lg', windowClass: 'biggerModal' });
    modalRef.componentInstance.videoURL = videoURL
  }

  private initComingEvents(): void {
    this.dataService
      .getAllEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe((eventsArr: any) => {
        this.listOfComingEvents = eventsArr.data
          .sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          )
          .filter((event) => {
            return event.isPublished === true;
          });
        });
  }

  private initArchiveEvents(): void {
    this.dataService
      .getAllEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe((eventsArr: any) => {
        this.listOfArchiveEvents = eventsArr.data
          .sort(
            (b, a) => new Date(a.date).getTime() - new Date(b.date).getTime()
          )
          .filter((event) => {
            return event.isArchived === true;
          })
          .map((event) => {
            if (
              this.companyInfoService
                .getListOfCompanies()
                .map((company) => {
                  return company.bloombergTicker;
                })
                .includes(event.bloombergTicker)
            ) {
              event.isCovered = true;
            } else {
              event.isCovered = false;
            }
            return event;
          });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
