<ng-container *ngIf="!multipleEvents">
<div *ngFor="let event of events; let i = index">
  <app-event [listType]="listType" [event]="event" [index]="i" [currentUsersCompanyName]="currentUsersCompanyName" [existingCompany]="existingCompany"></app-event>
</div>
</ng-container>

<ng-container *ngIf="multipleEvents">
<div *ngFor="let event of events; let i = index">
  <app-event *ngIf="i<shownEvents" [listType]="listType" [event]="event" [index]="i" [currentUsersCompanyName]="currentUsersCompanyName" [existingCompany]="existingCompany"></app-event>
</div>
<div class="text-center">
  <button class="btn btn-std" (click)="toggleMore()">
    <ng-container *ngIf="shownEvents < events.length; else lessEvents">show all events</ng-container>
   
  </button>
</div>
</ng-container>




<ng-template #lessEvents>
  show less events
</ng-template>