export function translateProgrammingString(input: string): string {
  const translations: Map<string, string> = new Map();

  translations.set("largeImage", "Large Image");
  translations.set("screener", "Screener");
  translations.set("editor", "Text-Block");
  translations.set("keyfacts", "Key Figures");
  translations.set("pdf-research", "PDF-Block");
  translations.set("financialtable", "Financial Table");
  translations.set("table-notabs", "Standardtabelle");
  translations.set("table-tabs", "Tabelle mit Tabs");
  translations.set("newsfeed", "Newsfeed");
  translations.set("charting", "Charting");
  translations.set("tv-chart", "Chart");
  translations.set("esg-all", "ESG");
  translations.set("button", "Button");
  translations.set("dcfModel", "DCF Model");

  if(!translations.get(input)){
    return mapPageTypes(input);
  }else{
    return translations.get(input) || input;
  }
}


export function mapPageTypes(rawPageType: string) {
  if (rawPageType === "investment-case") { return "Investment Case" }
  if (rawPageType === "snapshot") { return "Snapshot" }
  if (rawPageType === "risk") { return "Quality" }
  if (rawPageType === "research") { return "Research" }
  if (rawPageType === "growth") { return "Growth" }
  if (rawPageType === "valuation") { return "Valuation" }
  if (rawPageType === "financials") { return "Financials" }
  if (rawPageType === "corporate") { return "Corporate" }
  if (rawPageType === "swot") { return "SWOT" }
  if (rawPageType === "chart") { return "Chart" }
  if (rawPageType === "esg") { return "ESG" }
  if (rawPageType === "weeklyScreener") { return "Weekly Screener" }

  return rawPageType
}

export function mapPageTypeNames(pageType: string, oldToNew) {

  if (!oldToNew) {
    if (pageType === "overview") { return "snapshot" }
    if (pageType === "risk") { return "quality" }
    if (pageType === "growth") { return "growth" }
    if (pageType === "news") { return "research" }
    if (pageType === "valuation") { return "valuation" }
    if (pageType === "financials") { return "financials" }
    if (pageType === "swotanalysis") { return "swot" }
    if (pageType === "chart") { return "chart" }
    if (pageType === "downloads") { return "corporate" }
    if (pageType === "scores") { return "esg" }
    return pageType
  } else {
    if (pageType === "investment-case") { return "Investment Case" }
    if (pageType === "snapshot") { return "overview" }
    if (pageType === "quality") { return "risk" }
    if (pageType === "growth") { return "growth" }
    if (pageType === "research") { return "news" }
    if (pageType === "valuation") { return "valuation" }
    if (pageType === "financials") { return "financials" }
    if (pageType === "swot") { return "swotanalysis" }
    if (pageType === "chart") { return "chart" }
    if (pageType === "corporate") { return "downloads" }
    if (pageType === "esg") { return "scores" }
    return pageType
  }

}
