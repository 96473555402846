import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyInfoService } from '../../../../services/company-info.service';
import { translateProgrammingString } from '../../../../utilities';
@Component({
  selector: 'app-editor-block',
  templateUrl: './editor-block.component.html',
  styleUrls: ['./editor-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorBlockComponent implements OnInit, OnChanges {
  @Input() reportPanelMode = false;
  @Input() data: any;
  @Input() block_id: any;
  @Input() adminMode: any;
  @Input() blockType: any;
  @Input() deleteEmitter: any;
  @Input() truncateFlag: false;

  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;

  @Input() archiveDate = null
  @Input() archiveTitle = null

  showSaveErrMsg = false;
  isEditorMode = false;
  loadingEditor = false;
  changeMode = false;
  showLoadingIndicator = false;
  altTag: string = 'Image not displayed for better latency';
  showAll = false;
  showAllLabel = 'Read more...';
  editorContent = '';
  presentationType = '';

  truncateLength: number = 1800;


  constructor(private companyInfoService: CompanyInfoService, private modalService: NgbModal, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (window.innerWidth <= 768) {
      this.truncateLength = 600;
    }

    if(this.data.blockTag === '#SWOT'){
      if (window.innerWidth <= 768) {
        this.presentationType = 'SWOT_MOBILE';
      }else{
        this.presentationType = 'SWOT';
      }
    }

    this.truncateFlag = this.data.truncate;
    if(this.data.editorcontent){
      this.data.editorcontent = this.data.editorcontent.replace(/^<h3.+<\/h3>/,'')
      this.editorContent = this.truncate(this.data.editorcontent);
    }else{
      this.editorContent = '';
    }
    

    this.changeMode = this.reportPanelMode
    if (this.metaBloombergTicker === 'weekly:screener') {
      this.altTag = this.metaCompanyName + ': The weekly top equity picks - ' + translateProgrammingString(this.metaPageType);
    } else {
      this.altTag = this.metaCompanyName + ' (' + this.metaBloombergTicker + ') ' + translateProgrammingString(this.metaPageType);
    }

    //this.isEditorMode = this.reportPanelMode
  }

  ngOnChanges(changes: SimpleChanges): void {
   // console.log('CHANGE!!!', changes)
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
    if (this.showAll) {
      this.showAllLabel = 'Read less...';
      this.editorContent = this.data.editorcontent;
    } else {
      this.showAllLabel = 'Read more...';
      this.editorContent = this.truncate(this.data.editorcontent);
    }
  }

  truncate(input: string): string{
    if(this.truncateFlag){
      if(input.length < this.truncateLength){
        this.truncateFlag = false;
        return input;
      }else{
        let result = input.substring(0, this.truncateLength) + '...';
        return result;
      }
    } else {
      return input;
    }
  }

  public startEditorMode() {

    this.isEditorMode = true;
    this.cdr.detectChanges()
    this.loadingEditor = true;
    setTimeout(() => {
      this.loadingEditor = false;
    }, 100);
    this.cdr.detectChanges()
  }
  public endEditorMode() {
    this.isEditorMode = false;
    this.showLoadingIndicator = false;
    this.cdr.detectChanges()
  }
  editorClosedByChildEvent(event) {
    this.showLoadingIndicator = true;
    this.data.editorcontent = event.data;
    if (event.close === true) {
      this.endEditorMode();
    } else {
      this.updateBlockData(false, true).then(() => {
        this.showSaveErrMsg = false;
        this.cdr.detectChanges()
      }, (err) => {
        this.showSaveErrMsg = true;
        this.cdr.detectChanges()
      })
    }
  }

  updateBlockData(withreload, keepEditModeOpen): Promise<any> {
    return new Promise((resolve, _reject) => {



      this.companyInfoService.updateBuildingBlock(this.metaBloombergTicker, this.metaPageType, false, this.block_id, this.blockType, this.data, this.archiveDate, this.archiveTitle)
        .then(res => {
          if (!keepEditModeOpen) {
            this.endEditorMode();
          }
          this.showLoadingIndicator = false;
          if (withreload) {
            setTimeout(() => {

              this.deleteEmitter.next({ blockId: this.block_id, 'delete': false, 'reload': true });

            }, 300);
          }
          resolve(true)
        }, (err) => {
          _reject(false)
        });

    })
  }

  deleteBlock() {

    this.deleteEmitter.next({ blockId: this.block_id, 'delete': true });

    // this.companyInfoSerice.deleteBlock(this.block_id).then(res => {

    //   setTimeout(() => {
    //    // window.location.reload();
    //   }, 300);
    // });

  }

  startChangeMode() {
    this.changeMode = true;
  }
  endChangeMode() {
    this.changeMode = false;
    this.isEditorMode = false;
  }
  isEditing(){
    return this.companyInfoService.isCurrentlyEditingMode(this.metaBloombergTicker, this.metaPageType);
  }

  translate(s: string): string {
    return translateProgrammingString(s);
  }
}
