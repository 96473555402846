import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { alsterAPI_URL, leewayAPI_URL } from '../app-settings/api-urls';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})


export class DataServiceService {

  constructor(private httpClient: HttpClient, private utilitiesService: UtilitiesService) { }


  private readonly customerId = '5eed24ff4fe2bd337ccab5d3';
  private readonly apiToken = 'cqt1bii3tq5fuzo0c5id';
  private mapping;

  public getHistoricalQuotes(symbolExchange: string){
    const url = leewayAPI_URL+'public/historicalquotes/' + symbolExchange + '?apitoken=cqt1bii3tq5fuzo0c5id';

    return this.httpClient.get(url);
  }

  public getFinancialsNew(companyId: string) {
    const url = leewayAPI_URL+'addin/companydata/getcombinedexceldata?apitoken=' + this.apiToken + '&companyId=' + companyId + '&customerId=' + this.customerId + '&published=true';

    return this.httpClient.get(url);
  }

  public getQuotesAndUpDownChange(companyId: string) {
    const url = leewayAPI_URL+'addin/companydata/quotesandcalculations?apitoken=' + this.apiToken + '&companyId=' + companyId + '&customerId=' + this.customerId + '&published=true';

    return this.httpClient.get(url);
  }


  public getLiveQuote(symbolexchange: string, multi: string) {
    let multiParam = "";
    if (multi) {
      multiParam = "&multi=" + multi;
    }

    symbolexchange = this.utilitiesService.getMappedExchange(symbolexchange);

    const url = leewayAPI_URL+'public/live/' + symbolexchange + '?apitoken=' + this.apiToken + multiParam;


    return this.httpClient.get(url);
  }


  public getTiledata(companyId: string, multi) {
    let multiParam = "";
    if (multi) {
      multiParam = "&multi=" + multi;
    }

    const url = leewayAPI_URL+'addin/companydata/tiledata?apitoken=' + this.apiToken + '&companyId=' + companyId + '&customerId=' + this.customerId + multiParam;

    return this.httpClient.get(url);
  }

  public getMapping() {
    if (!this.mapping) {
      const customerIdparam = 'customerId=' + this.customerId;
      const apiTokenparam = 'apitoken=' + this.apiToken;

      const url = leewayAPI_URL+'addin/mapping/mapping?' + apiTokenparam + '&' + customerIdparam;
      let $mapping = this.httpClient.get(url).toPromise();
      $mapping.then(mapping => this.mapping = mapping);
      return $mapping;
    } else {
      //   return this.mapping as Promise<Array<any>>;
      return new Promise((resolve, reject) => { resolve(this.mapping) })
    }
  }


  public getTableTemplates(tabs: boolean) {
    const customerIdparam = this.customerId;
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const tabParam = 'tabs=' + tabs;

    const url = leewayAPI_URL+'tools/tabletemplates/' + customerIdparam + '?' + apiTokenparam + '&' + tabParam;

    return this.httpClient.get(url);
  }


  public addPDFCounter(pdfURL) {
    let body = { ref: pdfURL }
    this.httpClient.post(leewayAPI_URL+'cms/analytics/updatepdfcount?apitoken=cqt1bii3tq5fuzo0c5id', body).toPromise().then(res => { });
  }

  public getAnalytics() {
    return this.httpClient.get(leewayAPI_URL+'cms/analytics/pdfs?apitoken=cqt1bii3tq5fuzo0c5id')
  }

  public getRSS() {
    return this.httpClient.get(leewayAPI_URL+'cms/rss/rawfeed?apitoken=cqt1bii3tq5fuzo0c5id')
  }

  public updateRSS(rss) {
    return this.httpClient.post(leewayAPI_URL+'cms/rss/update?apitoken=cqt1bii3tq5fuzo0c5id', rss)
  }

  public getChartingTemplates() {
    const customerIdparam = this.customerId;
    const apiTokenparam = 'apitoken=' + this.apiToken;

    const url = leewayAPI_URL+'tools/chartingtemplates/' + customerIdparam + '?' + apiTokenparam;

    return this.httpClient.get(url);
  }


  public getTableTemplate(name: string) {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const nameparam = 'name=' + name;

    const url = leewayAPI_URL+'tools/tabletemplate/' + this.customerId + '?' + apiTokenparam + '&' + nameparam;

    return this.httpClient.get(url);
  }


  public getChartingTemplate(name: string) {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const nameparam = 'name=' + name;

    const url = leewayAPI_URL+'tools/chartingtemplate/' + this.customerId + '?' + apiTokenparam + '&' + nameparam;

    return this.httpClient.get(url);
  }

  //@update flag to show this updates existing template
  //if template name exist and no update flag return error name exists
  public putTableTemplate(update: boolean, template) {

    const apiTokenparam = 'apitoken=' + this.apiToken;

    const url = leewayAPI_URL+'tools/tabletemplate/' + this.customerId + '?' + apiTokenparam + '&update=' + update;

    return this.httpClient.post(url, template);
  }

  public putChartingTemplate(update: boolean, template) {

    const apiTokenparam = 'apitoken=' + this.apiToken;

    const url = leewayAPI_URL+'tools/chartingtemplate/' + this.customerId + '?' + apiTokenparam + '&update=' + update;

    return this.httpClient.post(url, template);
  }

  public getNews(symbolExchange: string, companyName: string, feedType: string, query: string) {

    const apiTokenparam = 'apitoken=' + this.apiToken;
    const symbolExchangeParam = 'symbolexchange=' + symbolExchange;
    const companynameParam = 'companyname=' + companyName;
    const typeParam = 'type=' + feedType;
    const queryParam = 'query=' + query;

    const url = leewayAPI_URL+'private/news/getNews?' + apiTokenparam + '&' + symbolExchangeParam + '&' + companynameParam + '&' + typeParam + '&' + queryParam;

    return this.httpClient.get(url);
  }

  public getScreen(screen) {

    const apiTokenparam = 'apitoken=' + this.apiToken;

    const url = leewayAPI_URL+'addin/companydata/screener/' + this.customerId + '?' + apiTokenparam;

    return this.httpClient.post(url, screen);
  }

  public getCompanyCountries() {

    const apiTokenparam = 'apitoken=' + this.apiToken;

    const url = leewayAPI_URL+'addin/companydata/countries/' + this.customerId + '?' + apiTokenparam;

    return this.httpClient.get(url);
  }

  public getCompanySectors() {

    const apiTokenparam = 'apitoken=' + this.apiToken;

    const url = leewayAPI_URL+'addin/companydata/sectors/' + this.customerId + '?' + apiTokenparam;

    return this.httpClient.get(url);
  }


  public getLatestPDfs() {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const url = leewayAPI_URL+'tools/latestresearch?' + apiTokenparam;

    return this.httpClient.get(url);
  }

  public changeTitle(body) {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const url = leewayAPI_URL+'tools/changearchivetitle?' + apiTokenparam;

    return this.httpClient.post(url, body);
  }

  public deleteArchive(body) {
    const apiTokenparam = 'apitoken=' + this.apiToken;
    const url = leewayAPI_URL+'tools/deletearchive?' + apiTokenparam;

    return this.httpClient.post(url, body);
  }

  public getAllEvents() {
 
    const url = alsterAPI_URL+'events/all'

    return this.httpClient.get(url);
  }

  public addEvent(newEvent) {
    const url = alsterAPI_URL+'events/create'

    return this.httpClient.post(url, newEvent);
  }

  public deleteEvent(eventForDeletion) {
    const url = alsterAPI_URL+'events/'+eventForDeletion._id

    return this.httpClient.delete(url);
  }

  public updateEvent(newEvent) {

    const url = alsterAPI_URL+'events/' + newEvent._id;

    return this.httpClient.put(url, newEvent);
  }

  public changeEventStatus(_id, selectedStatus, newStatus) {

    let body = {
      _id: _id,
      selectedStatus: selectedStatus,
      newStatus: newStatus
    }

    const url = alsterAPI_URL+'events/updatestatus';

    return this.httpClient.post(url, body);
  }

  public getFeaturedStock() {
    return this.httpClient.get(leewayAPI_URL+'tools/getfeaturedstock?apitoken=cqt1bii3tq5fuzo0c5id');
  }


  public setFeaturedStock(badge, ticker) {
    return this.httpClient.post(leewayAPI_URL+'tools/setfeatured/' + ticker + '?apitoken=cqt1bii3tq5fuzo0c5id&badge=' + badge, {});
  }

  public publishFigures(company) {
    return this.httpClient.post(leewayAPI_URL+'addin/companydata/publish/?customerId=' + this.customerId + '&companyId=' + company.bloombergTicker + '&apitoken=cqt1bii3tq5fuzo0c5id', {});
  }



}
