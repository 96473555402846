import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, OperatorFunction, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CompanyRights } from 'src/app/models/user.model';
import { AuthService, CompanyInfoService, DataServiceService } from 'src/app/services';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-report-panel',
  templateUrl: './report-panel.component.html',
  styleUrls: ['./report-panel.component.scss']
})
export class ReportPanelComponent implements OnInit {
  public researchBuildingBlocks: Observable<any>;
  public corporateBuildingBlocks: Observable<any>;

  allowedCompanies = []

  news_button_label = 'Publish news';

  newsDE = {
    title: null,
    researchTitle: null,
    description: null,
    type: 'research',
    lang: 'de'
  }

  newsEN = {
    title: null,
    researchTitle: null,
    description: null,
    type: 'research',
    lang: 'en'
  }

  message;
  latestResearchTitle;
  newResearchTitle;
  symbolExchange = "";
  companyName = "";
  isEditorMode = false
  isLoading = false;
  archiveDate = null
  showTitleInput
  publishRequestMessage = null
  tester = null
  sendingPublishRequest = false;
  publishRequestHasBeenSent = false;
  publishedDate = null //: Subject<string> = new BehaviorSubject('loading...'); // a normal cdr.detectchanges would work for this instead of behavoursubject. BUT calling cdr. can cause some bugs in our block loading logik :(
  loadingArchive = false;

  publishFiguresLabel = 'Publish Figures';
  publishPagesLabel = 'Publish Pages';

  constructor(private cdr: ChangeDetectorRef,
    private authService: AuthService,
    // private utilitiesService: UtilitiesService, 
    private companyInfoService: CompanyInfoService,
    private dataService: DataServiceService
  ) { }

  ngOnInit(): void {
    this.getAllowedCompanies()


  }

  getAllowedCompanies() {
    if (this.authService.isAdmin()) {
      this.allowedCompanies = this.companyInfoService.getListOfCompanies().filter(el => el.name !== "welcomePageStatic" && el.name !== "AAA-PDF-Company" && el.name !== "Testground").sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map(item => { return { write: true, download: true, upload: true, companyId: item.bloombergTicker } as CompanyRights })
    } else {
      this.allowedCompanies = this.authService.CurrentUser.rights.companies
    }

    this.getNamesOfCompanies(this.allowedCompanies)
  }

  readPublishedDate(publishedDate) {

    let tmpDate = publishedDate.split("-")
    let formattedDate = tmpDate[2] + "." + tmpDate[1] + "." + tmpDate[0]
    this.publishedDate = formattedDate
    //this.publishedDate.next(formattedDate)
    //this.cdr.detectChanges()
  }

  selectCompanyForEditing(company) {

    this.isLoading = true;
    this.companyName = company.name
    //this.symbolExchange = company.companyId.replace("XETRA", "GR").replace(".", ":")
    this.symbolExchange = company.companyId //this.utilitiesService.getMappedExchange(company.companyId, false)
    this.latestResearchTitle = this.companyInfoService.getListOfCompanies().find(item => item.bloombergTicker === this.symbolExchange).latestResearchTitle;
    this.endEditorMode()
    this.startEditorMode()
  }

  getNamesOfCompanies(companies) {
    // this directly changes this.allowedCompanies
    companies.map(item => {
      // item["name"] = this.companyInfoService.getCompanyNameByBloombergTicker(item.companyId.replace("XETRA", "GR").replace(".", ":"));
      item["name"] = this.companyInfoService.getCompanyNameByBloombergTicker(item.companyId); // (this.utilitiesService.getMappedExchange(item.companyId, false))
      return item
    })
    this.allowedCompanies = companies.filter(item => item.name !== "" && item.name !== undefined && item.name !== null)

  }

  startEditorMode() {
    let includedBlockTags = ['#pdf-presentation'];
    this.isEditorMode = true;
    this.companyInfoService.startEditingMode(true, this.symbolExchange, 'research', null, null).then(
      result => {
        this.researchBuildingBlocks = this.companyInfoService.getLayoutForCompany(this.symbolExchange, 'research', null, null, "started editor mode, loading blocks");

        this.companyInfoService.startEditingMode(true, this.symbolExchange, 'corporate', null, null).then(
          result => {
            this.companyInfoService.getLayoutForCompany(this.symbolExchange, 'corporate', null, null, "")
              .subscribe(corporateBlocks => {
                let cBlocks = [];
                for (let cBlock of corporateBlocks.blocks) {
                  if (includedBlockTags.includes(cBlock.data.blockTag.trim())) {
                    cBlocks.push(cBlock);
                  }
                }

                this.corporateBuildingBlocks = of(cBlocks);

                this.cdr.detectChanges();
                this.isLoading = false;
              });
          });

      }
    );

  }

  public endEditorMode() {
    this.isEditorMode = false;

    this.cdr.detectChanges()
  }

  public sendPublishRequest() {
    this.sendingPublishRequest = true
    let date = new Date(Date.now())
    let companyName = this.companyName
    let companyTicker = this.symbolExchange
    let msg = this.publishRequestMessage

    this.companyInfoService.sendPublishRequestForNewResearch(date, companyName, companyTicker, msg).then((res) => {
      this.sendingPublishRequest = false
      this.publishRequestHasBeenSent = true
      setTimeout(() => {
        this.publishRequestHasBeenSent = false;
      }, 10000)
    })
  }

  publishNews(language: string) {
    let news = this.newsDE;
    if (!this.newResearchTitle) {
      this.message = 'Enter research title';
      return;
    } else {
      this.newsDE.researchTitle = this.newResearchTitle;
    }

    if (language === 'EN') {
      news = this.newsEN
      news['researchTitle'] = this.newsDE.researchTitle;
    }

    if (news.title && news.title.length > 0) {
      news['link'] = 'https://research-hub.de/companies/research/' + encodeURIComponent(this.companyName);
      this.companyInfoService.addRSSEntry(news).then(res => { this.news_button_label = 'Finished' });
    }
  }

  publishFigures() {
    this.dataService.publishFigures({ bloombergTicker: this.symbolExchange }).subscribe(result => {
      this.publishFiguresLabel = 'Published'
    });
  }

  publishPages() {
    if (this.newResearchTitle) {
      this.companyInfoService.updateCompanyProperty(this.symbolExchange, 'latestResearchTitle', this.newResearchTitle);

      this.companyInfoService.publishAllChangesForCompany(this.symbolExchange).then(result => {
        this.publishPagesLabel = 'Published'
      });
    } else {
      this.message = 'Enter research title!'
    }
  }

  archivePublishedPage() {
    this.loadingArchive = true;
    this.publishChangesForPage().then(
      el => {
        return new Promise((resolve, reject) => {
          this.companyInfoService.archivePublishedPage(this.symbolExchange, 'research', this.publishedDate + " " + this.latestResearchTitle, null).then(res => {
            setTimeout(() => {
              resolve(true)
              this.loadingArchive = false;
              this.startEditorMode()
            }, 500);
          },
            (err) => { reject(false) });
        })
      }
    )
  }

  publishChangesForPage() {
    return new Promise((resolve, reject) => {
      this.companyInfoService.publishChangesForPage(this.symbolExchange, 'research', this.archiveDate, this.latestResearchTitle).then(res => {
        setTimeout(() => {
          resolve(true)
        }, 200);
      },
        (err) => { reject(false) });
    })
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.allowedCompanies.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
}
