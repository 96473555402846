import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';
@Component({
  selector: 'app-block-keyfacts',
  templateUrl: './block-keyfacts.component.html',
  styleUrls: ['./block-keyfacts.component.scss']
})
export class BlockKeyfactsComponent implements OnInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: any;
  @Input() blockType: any;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: any;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: any;
  @Input() metaCompanyData: any;
  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()
  notInitalized = true; //TODO: DELETE here and make a check in handler
  lang = "ger" //TODO: DELETE here and make a check in handler
  constructor() { }

  ngOnInit(): void {
    //TODO: this should be done in handler
    this.notInitalized = this.data && (this.data.bloombergTicker === 'Eingabe erforderlich' ) ? true : false
    if (this.notInitalized) {
      if (this.metaBloombergTicker) { this.data.bloombergTicker = this.metaBloombergTicker }
      this.notInitalized = this.data && (this.data.bloombergTicker === 'Eingabe erforderlich') ? true : false
    }
  }

}
