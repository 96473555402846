<ng-template #rt let-r="result" let-t="term">
  <!--bla-->
  <a style="color:black!important;" class="underlineonhover">
    {{r.name}}
  </a>
</ng-template>

<nav class="navbar fixed-top header mh-100" style="z-index:9999 !important">
  <div class="container-fluid limit-pagewidthX">
    <div class="row">
      <div class="col-12 navbar-wrapper justify-content-end justify-content-md-between">
        <a class="logo navbar-brand " href="/">
          <img loading="lazy" src="../../../assets/ResearchHubLogo.svg" alt="logo image"
            class="logo__img d-none d-md-block">
          <span *ngIf="!navbarOpen" class="d-block d-md-none" style="color:black">ResearchHub</span>
        </a>



        <div class="button-group button-group-wrapper text-md-center "
          [ngClass]="{ 'd-block': navbarOpen, 'd-none':!navbarOpen }">

          <input class="textinput" type="text" [resultTemplate]="rt" (focus)="focus$.next($any($event).target.value)"
            (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead" placeholder="FIND COMPANY"
            (selectItem)="goToCompanyPage($event)" [resultFormatter]="formatter" [ngbTypeahead]="search" />

          <button class="btn btn-transparent d-block d-md-none" [routerLink]="['/']">Home</button>
          <!-- <button class="btn btn-transparent" (click)="nextPage('all-stocks')">All Stocks</button> -->
          <button class="btn btn-transparent" (click)="nextPage('screener')">Screener</button>
          <!-- <button class="btn btn-transparent" [routerLink]="['/']" [fragment]="'eventgrid'">Events</button> -->
          <button class="btn btn-transparent" (click)="nextPage('events')">Events</button>

          <button class=" btn btn-transparent" (click)="nextPage('blog/weeklyScreener')">Featured Ideas</button>
          <button class=" btn btn-transparent" (click)="nextPage('disclaimer')">Disclaimer</button>
          <button class=" btn btn-std uppercase" (click)="openSubscription()">Subscribe</button>

          <a href="/admin" *ngIf="canUseAdminPanel()"> <button class="btn btn-std ml-2"> Admin
              Panel</button>
          </a>
          <a href="/reportpanel" *ngIf="isAdmin() || isContributor()"> <button class="btn btn-std ml-2">Upload <span
                *ngIf="!canUseAdminPanel()"> Research</span>
            </button>
          </a>
          <a href="/corporate/dashboard"> <button class=" btn btn-inverted-gray-no-bg ml-2">
            <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-easel" viewBox="0 0 16 16">
                <path d="M8 0a.5.5 0 0 1 .473.337L9.046 2H14a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1.85l1.323 3.837a.5.5 0 1 1-.946.326L11.092 11H8.5v3a.5.5 0 0 1-1 0v-3H4.908l-1.435 4.163a.5.5 0 1 1-.946-.326L3.85 11H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4.954L7.527.337A.5.5 0 0 1 8 0zM2 3v7h12V3H2z"/>
             </svg>
            </span> 
        </button>
        </a>

        </div>

        <button class="navbar-toggler btn btn-transparent border-dark d-block d-md-none" type="btn "
          (click)="toggleNavbar()">
          <i class="fas fa-bars"></i>
        </button>
        <app-transactionbanner *ngIf="transactionMode && mobileHeader && !navbarOpen"></app-transactionbanner>
      </div>
    </div>
  </div>
</nav>
<app-transactionbanner *ngIf="transactionMode && !mobileHeader"></app-transactionbanner>
