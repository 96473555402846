import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteWarningComponent } from 'src/app/cms/delete-warning/delete-warning.component';
import { FileUploaderComponent } from 'src/app/cms/file-uploader';
import { ChartingTemplateCreationComponent } from 'src/app/cms/modals/charting-template-creation/charting-template-creation.component';
import { TableTemplateCreationComponent } from 'src/app/cms/modals/table-template-creation/table-template-creation.component';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { DataServiceService } from 'src/app/services/data-service.service';
import { translateProgrammingString } from 'src/app/utilities';

/**
 *
 * Editor-Block, ESG-Block and PDF-List-Block are still not handled here. They are working as before, their code is in the .ts files.
 *
 */

@Component({
  selector: 'app-block-handling',
  templateUrl: './block-handling.component.html',
  styleUrls: ['./block-handling.component.scss']
})
export class BlockHandlingComponent implements OnInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: boolean;
  @Input() blockType: string;
  @Input() deleteEmitter: any;  // Delete Emiter handles deletion if blocks BUT ALSO can be used to just trigger the blocksection to refresh
  @Input() metaCompanyName: string;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: string;
  @Input() metaCompanyData: any;
  @Input() notInitalized: boolean = false


  /** internal vars */

  needsDecimals = false;
  needsBL = false;
  needsTitle = false;
  needsTemplates = false;
  /** Menu General */

  isEditorMode: boolean = false;
  loadingEditor: boolean = false;
  changeMode: boolean = false;
  btnColorUnsavedChanges: boolean = false;


  /** Vars for Key Figures and FinancialTable */
  decimals: Array<number> = [0, 1, 2, 3];
  dcfTypes = ['Default', 'Italy'];
  languages = ['ger', 'au'];
  lang = 'ger';

  /** Template Handling */
  defaultTemplateName: string
  allTemplateNames = []


  constructor(private companyInfoService: CompanyInfoService, private dataService: DataServiceService, private modalService: NgbModal, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.needsDecimals = (this.blockType === 'keyfacts' || this.blockType === 'financialtable' || this.blockType === 'charting' || this.blockType === 'table-notabs' || this.blockType === 'table-tabs') ? true : false
    this.needsBL = (this.blockType === 'keyfacts' || this.blockType === 'financialtable' || this.blockType === 'charting' || this.blockType === 'table-notabs' || this.blockType === 'table-tabs' || this.blockType === 'tv-chart') ? true : false
    this.needsTitle = (this.blockType === 'keyfacts' || this.blockType === 'financialtable' || this.blockType === 'charting' || this.blockType === 'table-notabs' || this.blockType === 'table-tabs' || this.blockType === 'tv-chart' || this.blockType === 'video') ? true : false
    this.needsTemplates = (this.blockType === 'financialtable' || this.blockType === 'charting' || this.blockType === 'table-notabs' || this.blockType === 'table-tabs') ? true : false


    // check and fix important data if missing
    if (this.needsTitle) {
      this.fixHeadline()
    }
    if (this.blockType === 'video') {
      this.hasVideoURL()
    }
    if (this.blockType === 'button') {
      this.setPDFButtonTag()
    }
    if (this.needsDecimals) {
      this.hasDecimalsSet()
    }
    if (this.needsBL) {
      this.fillWithMetaBloombergTickerIfGiven()
    }

    if (this.needsTemplates) {
      /** set correct defaultTemplates in component */
      this.setDefaultTemplateName();
      /** Load correct Templates */
      if (this.blockType === 'financialtable' || this.blockType === "table-notabs" || this.blockType === "table-tabs") { this.loadTableTemplates() }
      if (this.blockType === 'charting') { this.loadChartingTemplates() }
    }


  }



  /** Open/Close Menu */
  public startChangeMode() {
    this.changeMode = true;
    if (this.blockType === 'financialtable' || this.blockType === "table-notabs" || this.blockType === "table-tabs") { this.loadTableTemplates() }
    if (this.blockType === 'charting') { this.loadChartingTemplates() }

  }
  public endChangeMode() {
    this.changeMode = false;
    this.isEditorMode = false;
  }
  /** listens to changes in input field for URLs/BL-Ticker */
  public onChangeInput(input) {
    this.btnColorUnsavedChanges = true;
    this.cdr.detectChanges();
  }
  /** Update Block data and send to backend */
  public saveAllChanges() {
    this.updateBlockData(false, this.data)
  }
  private updateBlockData(withreload, data) {
    // make a function from this
    if (this.blockType === 'keyfigures') { withreload = true }
    if (this.blockType === 'largeImage') { withreload = false }
    if (this.blockType === 'financialtable') { withreload = withreload } // fullupdate is true
    if (this.blockType === 'charting') { withreload = withreload } // fullupdate is true
    if (this.blockType === 'table-notabs') { withreload = withreload } // fullupdate is true
    if (this.blockType === 'table-tabs') { withreload = withreload } // fullupdate is true
    if (this.blockType === 'tv-chart') { withreload = true } // fullupdate is true
    if (this.blockType === 'video') { withreload = true } // fullupdate is true

    this.companyInfoService.setisLoading(true);
    this.companyInfoService.updateBuildingBlock(
      this.metaBloombergTicker,
      this.metaPageType,
      false,
      this.block_id,
      this.blockType,
      data,
      null,
      null
    ).then(() => {
      this.cdr.detectChanges();
      this.companyInfoService.setisLoading(false);
      this.btnColorUnsavedChanges = false;
      if (withreload) {
        setTimeout(() => {
          this.deleteEmitter.next({ blockId: this.block_id, 'delete': false, 'reload': true });
        }, 300);
      }
    });
  }
  /** Delete Block */
  private deleteBlock() {
    // UNCOMMENT THIS FOR REAL USAGE
    this.deleteEmitter.next({ blockId: this.block_id, 'delete': true });
  }
  public openDeletionWarning() {
    const modalRef = this.modalService.open(DeleteWarningComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.companyName = this.metaCompanyName
    modalRef.componentInstance.pageType = this.metaPageType
    modalRef.componentInstance.blocks = [this.blockType]
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'ok') {
        this.deleteBlock()
      } else {
        // do nothing
      }
    });
  }
  /** Translate strings */
  public translate(s: string): string {
    return translateProgrammingString(s);
  }
  /** Fixes, Replacers and Compability and  check init state of block data */
  private fixHeadline() {
    if (this.data.blockHeadline === "" || this.data.blockHeadline === null || this.data.blockHeadline === undefined) {
      this.data.blockHeadline = translateProgrammingString(this.blockType)
    }
  }
  private hasMinimumData() {
    if (this.blockType === 'keyfacts' || this.blockType === 'financialtable') {
      if (this.data.bloombergTicker === 'Eingabe erforderlich' || !this.data.bloombergTicker.includes(':')) {
        return true;
      } else {
        return false;
      }
    }
  }
  private hasDecimalsSet() {

    if (this.data.numDecimals === null || this.data.numDecimals === undefined) {
      this.data.numDecimals = 0;

    }
  }
  private fillWithMetaBloombergTickerIfGiven() {
    this.data.bloombergTicker = this.metaBloombergTicker;
  }
  private hasVideoURL() {
    if (this.data.videoURL === null || this.data.videoURL === undefined || this.data.videoURL === "") {
      this.data.videoURL = "https://vimeo.com/522221637"
    }
  }

  private setPDFButtonTag() {
    if (!this.data.blockTag) {
      this.data.blockTag = '#latestResearchButton';

    }
  }
  /** SHARED */
  showUploader() {
    let uploaderMode = ""
    if (this.blockType !== 'largeImage') { uploaderMode = 'pdfswithimages' } else { uploaderMode = 'imageOnly' }
    const modalRef = this.modalService.open(FileUploaderComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.uploaderMode = uploaderMode;
    modalRef.result.then((result) => {
      this.data.url = result;
      this.updateBlockData(false, this.data);
      this.cdr.detectChanges()
    }, (reason) => {
    });
  }
  setDecimals(decimals) {
    this.data.numDecimals = decimals;
    this.updateBlockData(false, this.data)
  }
  setLanguage(language) {
    this.lang = language; //TODO: this should not work at all
  }

  /** Templates */
  setDefaultTemplateName() {

    switch (this.blockType) {
      case 'financialtable': {
        this.defaultTemplateName = "financials-GKV";
        break;
      }
      case 'charting': {
        this.defaultTemplateName = "default-chart"
        break;
      }
      case 'table-notabs': {
        this.defaultTemplateName = "Keyfacts B/S";
        break;
      }
      case 'table-tabs': {
        this.defaultTemplateName = "financials-GKV";
        break;
      }
      default: {
        //statements;
        break;
      }

    }
    if (this.data.currentTemplate === null || this.data.currentTemplate === undefined) {
      this.data.currentTemplate = this.defaultTemplateName
    }
  }

  /** TABLE Templates */
  setTemplateName(newTemplate) {
    this.data.currentTemplate = newTemplate;
    this.updateBlockData(false, this.data)
  }

   /** TABLE Templates */
   setDCFType(newDCFType) {
    this.data.dcfType = newDCFType;
    this.updateBlockData(false, this.data)
  }

  createTemplate(): void {

    let tabs = true;
    if (this.blockType === "table-notabs") { tabs = false; } else { tabs = true; }
    const modalRef = this.modalService.open(TableTemplateCreationComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.tabs = tabs;
    modalRef.componentInstance.updateMode = false;
    modalRef.result.then((result) => {
      this.updateBlockData(false, this.data)
      this.dataService.getTableTemplates(tabs).subscribe(res => {
        this.allTemplateNames = (res as Array<any>).map(el => { return el.name })
      })
    }, (reason) => {
    })
  }
  editTemplate(): void {
    let tabs = true;
    if (this.blockType === "table-notabs") { tabs = false; } else { tabs = true; }
    const modalRef = this.modalService.open(TableTemplateCreationComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.templateName = this.data.currentTemplate;
    modalRef.componentInstance.tabs = tabs;
    modalRef.componentInstance.updateMode = true;
    modalRef.result.then((result) => {
      this.updateBlockData(false, this.data)
      this.dataService.getTableTemplates(tabs).subscribe(res => {
        this.allTemplateNames = (res as Array<any>).map(el => { return el.name })
      })
    }, (reason) => {
    })
  }
  loadTableTemplates() {
    let tabs = true;
    if (this.blockType === "table-notabs") { tabs = false; } else { tabs = true; }
    this.dataService.getTableTemplates(tabs).subscribe(res => {
      this.allTemplateNames = (res as Array<any>).map(el => { return el.name })
    })
  }
  /** CHARTING Templates */
  loadChartingTemplates() {
    this.dataService.getChartingTemplates().subscribe(res => {
      this.allTemplateNames = (res as Array<any>).map(el => { return el.name })
    })
  }
  createChartingTemplate(): void {
    const modalRef = this.modalService.open(ChartingTemplateCreationComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.updateMode = false;
    modalRef.result.then((result) => {
      this.updateBlockData(true, this.data)
      this.dataService.getChartingTemplates().subscribe(res => {
        this.allTemplateNames = (res as Array<any>).map(el => { return el.name })
      })
    }, (reason) => {
    })
  }
  editChartingTemplate(): void {
    const modalRef = this.modalService.open(ChartingTemplateCreationComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.templateName = this.data.currentTemplate;
    modalRef.componentInstance.updateMode = true;

    modalRef.result.then((result) => {
      this.updateBlockData(true, this.data)
      this.dataService.getChartingTemplates().subscribe(res => {
        this.allTemplateNames = (res as Array<any>).map(el => { return el.name })
      })
    }, (reason) => {
    })
  }

  isEditing(){
    return this.companyInfoService.isCurrentlyEditingMode(this.metaBloombergTicker, this.metaPageType);
  }



}
