import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mifid-disclaimer',
  templateUrl: './mifid-disclaimer.component.html',
  styleUrls: ['./mifid-disclaimer.component.scss']
})
export class MifidDisclaimerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
