import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BlockHandlingComponent } from 'src/app/sharedModule/other/block-handling/block-handling.component';
@Component({
  selector: 'app-block-table-tabs',
  templateUrl: './block-table-tabs.component.html',
  styleUrls: ['./block-table-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BlockTableTabsComponent implements OnInit {
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: boolean;
  @Input() blockType: string;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: string;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: string;
  @Input() metaCompanyData: any;
  @ViewChild(BlockHandlingComponent) blockhandler: BlockHandlingComponent; // call blockhandler function with this.blockhandler.functioname()

  notInitalized = true;
  constructor() { }

  ngOnInit(): void {
    //TODO: this should be done in handler
    this.notInitalized = this.data && (this.data.bloombergTicker === 'Eingabe erforderlich' || !this.data.bloombergTicker.includes(':')) ? true : false
    if (this.notInitalized) {
      if (this.metaBloombergTicker) { this.data.bloombergTicker = this.metaBloombergTicker }
      this.notInitalized = this.data && (this.data.bloombergTicker === 'Eingabe erforderlich' || !this.data.bloombergTicker.includes(':')) ? true : false
    }
  }

}
