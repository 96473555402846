import { ApplicationRef, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FileUploaderComponent } from 'src/app/cms/file-uploader';
import { emptyIssuerUser, IssuerUser } from 'src/app/corporate-dashboard/models/issuer.model';
import { CorporateDashboardService } from 'src/app/corporate-dashboard/services/corporate-dashboard.service';
import { EventSpeaker, SingleEvent } from 'src/app/models/event.model';
import { AuthService, DataServiceService } from 'src/app/services';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { BackupdownloaderComponent } from '../modals/backupdownloader/backupdownloader.component';
import { CreateCompanyComponent } from '../modals/create-company/create-company.component';
import { CreateEventComponent } from '../modals/create-event/create-event.component';
import { SaveNewsletterFormComponent } from '../modals/save-newsletter-form/save-newsletter-form.component';
import { SubpageLayoutSelectionComponent } from '../modals/subpage-layout-selection/subpage-layout-selection.component';
import {createEventRegistrationLink, timeZoneFormat} from 'src/app/events/utils/event.utils'
import { AdminPanelSettingsModalComponent } from '../modals/admin-panel-settings-modal/admin-panel-settings-modal.component';




@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminPanelComponent implements OnInit {
  timeZoneFormat = timeZoneFormat
  companies: any = [];
  companyNames: string[] = [];
  companyBloombergTickers: string[] = [];
  allUsers: any = [];
  issuerUsers: IssuerUser[] = [];
  newIssuerUser: IssuerUser = emptyIssuerUser()
  tmpAllowedCompanyId: any
  hiddenstatustouched = false;
  possiblePages: any;
  disclaimerCompanies = [{ "name": "", "ticker": "", "bloomberg": "", "disclosures": "" }]
  activeSessionPages: any = [];
  nonPublishedPages: any = [];
  isDeleting: boolean = false;
  showDeletionWarning = {}
  isLoading: any = false;
  public isCollapsed = true;
  pdfAnalytics: any = [];
  coicName: string;
  coicTicker: string;
  coicBloomberg: string;
  coicDisclosures: string;
  tmpNewWrite: any = false;
  tmpNewUpload: any = false;
  tmpNewDownload: any = false;
  showYearError = false
  invalidTickers = ["XX2:XX",
    "weekly:screener",
    "Test:Test",
    "XXX:XX",]

  rssFeed = [];

  news = {
    title: null,
    researchTitle: null,
    description: null,
    type: 'research',
    lang: 'de'
  }
  news_button_label = 'Publish';
  newsLangs = ['de','en'];

  possibleRoles = ["ADMIN", "CONTRIBUTOR", "USER", "no role"]
  possibleBaseYears = [/**2010, 2011,2012,2013,2014,2015,2016,2017,2018,**/2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2034]
  possibleBadges = ['None', 'Featured Stock', 'Transaction', 'Initiation'];
  transactionMode = false;
  newText = "" // delete this before deploy
  newLink = "" // delete this before deploy
  editEvents = false;
  myEvents:SingleEvent[] = []
  alsterExchangeMapping = {}
  newMappingSet = { blTicker: "", mappedTicker: "" }
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(
    private utilitiesService: UtilitiesService, 
    private companyInfoService: CompanyInfoService, 
    private modalservice: NgbModal, 
    private dataService: DataServiceService, 
    private authService: AuthService,
    private issuerService: CorporateDashboardService,
    ) {

  }

  createEventRegistrationLink = createEventRegistrationLink

  saveExchangeToMapping() {
    this.isLoading = true
    this.utilitiesService.updateExchangeMapping(this.newMappingSet).then((res: any) => {
      this.alsterExchangeMapping = res.result.mapping
      this.isLoading = false
    })
  }

  getAlsterExchageMapping() {
    this.utilitiesService.getExchangeMapping().then(res => {
      this.alsterExchangeMapping = res
    })
  }

  deleteAlsterExchangeMappingSet(key) {
    this.utilitiesService.deleteExchangeMapping(key).then(res => {
      window.location.reload()
    })
  }

  getAllEvents() {
    this.dataService.getAllEvents().subscribe((result:any) => {
      this.myEvents = result.data as SingleEvent[]
      this.myEvents = this.myEvents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      // console.log("--------------------------------- EVENTS ---------------------------------")
      // console.log(this.myEvents)
    })
  }

  removeSpeakerFromEvent(event:SingleEvent,index:number){
    event.speakers.splice(index,1)
  }

  addNewSpeakerToEvent(event:SingleEvent){
    if(!event.speakers){event["speakers"] = []}
    let emptySpeaker:EventSpeaker ={name:"",imageURL:"",position:""}
    event.speakers.push(emptySpeaker)
  }

  updateEvent(event:SingleEvent) {
    this.isLoading = true
    this.dataService.updateEvent(event).toPromise().then((res:any) => {
      this.myEvents.map((item:SingleEvent) => {
        if (item._id === res.data[0]["_id"]) {
          item = res.data[0]
        }
      })
      this.isLoading = false;
    })
  }


  editEventDate(event) {
    const modalRef = this.modalservice.open(CreateEventComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.mode = "editEventDate"
    modalRef.componentInstance.currentEvent = event
    modalRef.result.then((data:SingleEvent) => {
      this.myEvents.map((item:SingleEvent) => {
        if (item._id === data["_id"]) {
          item = data
        }
      })
    });
  }

  getAnalytics() {
    this.dataService.getAnalytics().subscribe((res: any) => this.pdfAnalytics = res.result)
  }

  download() {
    this.dataService.getAnalytics().subscribe((res: any) => this.downloadFile(res.result, 'downloads'))
  }

  downloadFile(data, filename = 'data') {
    let csvData = this.analyticsJSONToCSV(data);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  analyticsJSONToCSV(pdfs){
    let csv = '';
    for(let pdf of pdfs){
      let line ='';
      line = line + pdf._id + ";" + pdf.count;
      csv += line + '\r\n';
    }

    return csv;
  }

  getAllUsers() {
    this.authService.getAllUsers().subscribe((res: any) => { this.allUsers = res.users.filter(user => user.type !== "ISSUER") })
  }

  deleteEvent(event:SingleEvent) {
    for (let i = 0; i < this.myEvents.length; i++) {
      if (this.myEvents[i]._id === event._id) {
        this.myEvents.splice(i, 1)
      }
    }

    this.dataService.deleteEvent(event).toPromise().then(el => {

    }
    )
  }

  changeEventStatus(event:SingleEvent, selectedStatus:string) {
    let id = event._id
    let newStatus = !event[selectedStatus]
    if (newStatus === null || newStatus === undefined) { newStatus = false; }

    this.dataService.changeEventStatus(id, selectedStatus, newStatus).toPromise().then((res:any) => {

      this.myEvents.map((el:SingleEvent) => {
        if (el._id === res.data[0]["_id"]) {
          el[selectedStatus] = res.data[0][selectedStatus]
        }
      })
    }
    )

  }

  getTransactionContent() {
    this.companyInfoService.getTransactionContent().then(
      (result: any) => { if (result !== null && result.text && result.link) (this.newText = result.text, this.newLink = result.link) }
    )
  }

  toggleEditEvents() {
    this.editEvents = !this.editEvents
  }
  toggleShowFeedbackOfEvent(event) {
   event.showFeedback = !event.showFeedback
  }

  createOrUpdateTransaction() {
    if (!this.newLink.includes('https')) { this.newLink = 'https://' + this.newLink }
    this.companyInfoService.createOrUpdateTransaction(this.newText, this.newLink).then(() => window.location.reload())
  }
  deleteTransaction() {
    this.companyInfoService.deleteTransaction(this.newText, this.newLink).then(() => window.location.reload())
  }

  getDisclaimerCompanies() {
    this.companyInfoService.getDisclaimerCompanies().then((companies) => {
      this.disclaimerCompanies = companies as any
      this.disclaimerCompanies.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    }
    )

  }
  addNewDisclaimerCompany() {
    this.companyInfoService.updateDisclaimerCompanies({ name: this.coicName, ticker: this.coicTicker, bloomberg: this.coicBloomberg, disclosures: this.coicDisclosures }).then(() => window.location.reload())
  }
  deleteDisclaimerCompanies(coicName, coicTicker, coicBloomberg, coicDisclosures) {
    this.companyInfoService.deleteDisclaimerCompanies({ name: coicName, ticker: coicTicker, bloomberg: coicBloomberg, disclosures: coicDisclosures }).then(() => window.location.reload())
  }
  ngOnInit(): void {


    if (this.companyInfoService.getTransactionMode()) {
      this.transactionMode = true;
    }
    this.getTransactionContent()
    this.getDisclaimerCompanies() as any;
    this.possiblePages = this.companyInfoService.getAvailablePages();
    this.companyInfoService.setisLoading(true);
    //this.companyInfoService.initalizeCompanies(false);

    this.companyInfoService.dynamicCompanyList$.subscribe(
      (companyList) => {
        companyList.map(c => {!c.charts ? c.charts = ['','']:c.charts})
        this.companies = companyList.sort((a, b) => a.name.localeCompare(b.name));
        this.companyNames = this.companies.map(c => {return (c.name||'')});
        this.companyBloombergTickers = this.companies.map(c => {return (c.bloombergTicker||'')});
      }
    );

    this.getAllEvents()
    this.companyInfoService.loadingBool$.subscribe((cmsStatus) => {
      this.isLoading = cmsStatus.isloading;
      cmsStatus.companiesEditing.map(company => {
        const changeCompany = this.companies.find(item => item.companyId === company.bloombergTicker);
        if (changeCompany !== undefined) {
          // changeCompany.hasActiveSession =
          // this.findPagesWithNotifications("alreadyWorkedOn", company) // this is kind of useless, its only local?
          changeCompany.isInEditingMode = this.findPagesWithNotifications('isEditing', company); // changemodes in this session by  myself
        }
      });
    });

    this.companyInfoService.setisLoading(false);
    this.companyInfoService.refreshCompanyList()
    this.getAnalytics()
    this.getAllUsers()
    this.getAlsterExchageMapping()
    this.getRSSFeed();
    this.getIssuerUsers();
  }

  getRSSFeed() {
    this.dataService.getRSS().subscribe(res => {
      this.rssFeed = res as [any];
      this.rssFeed = this.rssFeed.reverse();
      for(let rss of this.rssFeed){
        rss.expanded = false;
        rss.buttonLabel = 'Save';
      }
    })

  }

  updateRSS(rss: any){
    this.dataService.updateRSS(rss).subscribe(res => {
      rss.buttonLabel = 'Saved';
    })
  }

  publishAllCompanyPages(companyId) {
    this.companyInfoService.publishAllChangesForCompany(companyId).then(res => {
    });
  }

  updateCompanySessionStatus() {
    const httpRequets = [];

    for (const company of this.companies) {
      for (const pageType of this.possiblePages) {
        httpRequets.push(this.getCompanyStatus(company.bloombergTicker, pageType));
      }
    }

    Promise.all(httpRequets).then(res => this.reduceStatusArray(res));
  }

  getCompanyStatus(companyId, pageType) {
    return this.companyInfoService.checkForDocumentStatus(companyId, pageType);
  }

  findPagesWithNotifications(target, company) {
    const pages = [];
    for (const [key, value] of Object.entries(company)) {
      if (value.hasOwnProperty(target)) {
        if (value[target] === true) {
          pages.push(key);
        }
      }
    }
    return pages;
  }

  hideCompany(companyIndex, isHidden) {
    this.companies[companyIndex].hidden = isHidden;
    this.hiddenstatustouched = true;
    this.companyInfoService.setHideOfCompany(this.companies[companyIndex]).then(res => {
      // this.initCompanyListForAdmin();
      this.hiddenstatustouched = false;
      this.companyInfoService.setExcelVisibilityFlag(this.companies[companyIndex].bloombergTicker, !isHidden).then(res => { });
    })
  }


  setColorfilter(companyIndex, colorfilter) {
    this.companies[companyIndex].colorfilter = colorfilter;
    this.companyInfoService.setColorfilterForCompany(this.companies[companyIndex], colorfilter).then(res => {
    })
  }

  setBadge(badge, company) {
    if (badge === 'None') {
      badge = '';
    }
    company.isFeatured = badge;

    this.dataService.setFeaturedStock(badge, company.bloombergTicker).toPromise().then(result => {
    })
  }


  publishFigures(company) {
    this.dataService.publishFigures(company).toPromise().then(result => {
    })
  }

  publishAllFigures() {
    // Sequential Promise Resolving: https://medium.com/developer-rants/running-promises-in-a-loop-sequentially-one-by-one-bd803181b283
    this.isLoading = true;

    const delayedApiCall = (company) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.dataService.publishFigures(company).toPromise().then(result => {
            resolve(company);
          })
        }, 1000);
      });
    }




    const doNextPromise = (d) => {
      delayedApiCall(this.companies.filter(company => !this.invalidTickers.includes(company.bloombergTicker) && !company.hidden)[d])
        .then((company: any) => {
          d++;
          if (d < this.companies.filter(el => !this.invalidTickers.includes(el.bloombergTicker) && !el.hidden).length)
            doNextPromise(d)
          else {
            this.isLoading = false;
          }

        })
    }
    doNextPromise(0);

  }


  reduceStatusArray(statusArray) {
    for (let i = 0; i <= statusArray.length - 1; i++) {
      const companyIndex = this.companies.findIndex(item => item.bloombergTicker === statusArray[i].bloombergTicker);
      if (companyIndex >= 0) {
        if (statusArray[i].hasUnpublishedChanges) { this.companies[companyIndex].hasNonpublishedChanges.push(statusArray[i].pageType); }
        if (statusArray[i].hasActiveWorkingSession) { this.companies[companyIndex].hasActiveSession.push(statusArray[i].pageType); }
      }

    }
    this.companyInfoService.setisLoading(false);
  }


  setBaseyearOfCompany(year, companyId) {
    this.showYearError = false
    if (this.possibleBaseYears.includes(Number(year))) {
      this.companyInfoService.setBaseyearOfCompany(year, companyId).then(
        () => {
          this.companyInfoService.refreshCompanyList()

        })
    } else {
      this.showYearError = true
    }

  }

  updateCompanyWithLogoURL(companyId, logoUrl): Promise<any> {
    return new Promise((resolve, reject) => {
      this.companyInfoService.setLogoUrlOfCompany(companyId, logoUrl).then(result => resolve(result))
    })
  }

  updateCompanyWithImageURL(companyId, imageURL): Promise<any> {
    return new Promise((resolve, reject) => {
      this.companyInfoService.setImageUrlOfCompany(companyId, imageURL).then(result => resolve(result))
    })
  }

  deleteCompany(force: boolean, companyID: string) {
    if (force === true) {
      this.showDeletionWarning[companyID] = false
      this.isDeleting = true
      this.companyInfoService.deleteCompanyFully(companyID).then(
        result => { this.isDeleting = false; window.location.reload() }

      )
    } else {
      this.showDeletionWarning[companyID] = true
    }
  }

  openLogoUploader(companyId) {
    const modalRef = this.modalservice.open(FileUploaderComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.uploaderMode = 'imageOnly';
    modalRef.result.then((logourl) => {
      this.updateCompanyWithLogoURL(companyId, logourl).then((result) => {
        this.companyInfoService.refreshCompanyList()
      })
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {

      } else {
        // do nothing
      }
    });
  }


  openCompanyImageUploader(companyId) {
    const modalRef = this.modalservice.open(FileUploaderComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.uploaderMode = 'imageOnly';
    modalRef.result.then((imageURL) => {
      this.updateCompanyWithImageURL(companyId, imageURL).then((result) => {
        this.companyInfoService.refreshCompanyList()
      })
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {

      } else {
        // do nothing
      }
    });
  }


  openSubpageLayoutSelection(companyID) {
    const modalRef = this.modalservice.open(SubpageLayoutSelectionComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.companyId = companyID
    modalRef.result.then((layoutresult) => {

    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {

      } else {
        // do nothing
      }
    });
  }

  openEvents() {
    const modalRef = this.modalservice.open(CreateEventComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.mode = "createEvent"
    modalRef.result.then((reason) => {

    });
  }

  openNewFormURL(mode: string) {
    const modalRef = this.modalservice.open(SaveNewsletterFormComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.formMode = mode;

  }

  openCreateCompany() {
    this.modalservice.open(CreateCompanyComponent, { centered: true, size: 'lg' }).result.then((result) => {

    }, (reason) => {
      if (reason === 'ok') {
        //insert company into companies list
      }
    });
  }

  openBackupDownloader() {
    this.modalservice.open(BackupdownloaderComponent, { centered: true, size: 'lg' }).result.then((result) => {

    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
        // do nothing
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        // do nothing
      } else if (reason === 'deny') {
        // do nothing
      } else if (reason === 'ok') {
        //console.log("ok")
      } else {
        // do nothing
      }
    });
  }

  formatter = (result) => {
    return result
  }
  

  searchBloombergTickers: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 ? []
      : this.companyBloombergTickers.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  searchCompanyNames: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.companyNames.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  addAllowedCompany(user) {
    let newCode = this.tmpAllowedCompanyId.bloombergTicker//this.utilitiesService.getMappedExchange(, true)

    user.rights.companies.push({ companyId: newCode, write: this.tmpNewWrite, upload: this.tmpNewUpload, download: this.tmpNewDownload })
    this.tmpAllowedCompanyId = null
    this.tmpNewUpload = null
    this.tmpNewDownload = null
    this.tmpNewWrite = null
    this.authService.addAllowedCompanies(user.userId, { companyId: newCode, rights: user.rights }).then((res: any) => {

      this.allUsers.map(localuser => {
        if (localuser.userId === res.result.userId) {
          localuser = res.result
        }
      })

    })

  }
  deleteAllowedCompany(user, companyId) {
    for (let i = 0; i < user.rights.companies.length; i++) {
      if (user.rights.companies[i].companyId === companyId) {
        user.rights.companies.splice(i, 1)
      }
    }
    this.authService.deleteAllowedCompanies(user.userId, { companyId: companyId, rights: user.rights }).then((res: any) => {

      this.allUsers.map(localuser => {
        if (localuser.userId === res.result.userId) {
          localuser = res.result
        }
      })

    })
  }

  updateAllowedCompany(user, companyId, action, newValue) {
    user.rights.companies.map(item => {
      if (item.companyId === companyId) {
        item[action] = newValue
      }
    })
    this.authService.updateAllowedCompanies(user.userId, user.rights).then((res: any) => {

      this.allUsers.map(localuser => {
        if (localuser.userId === res.result.userId) {
          localuser = res.result
        }
      })

    })
  }

  setAccessToAdminPanel(user, newValue) {
    user.rights.adminPanel = newValue
    this.authService.setAccessToAdminPanel(user.userId, user).then((res: any) => {

      this.allUsers.map(localuser => {
        if (localuser.userId === res.result.userId) {
          localuser = res.result
        }
      })

    })
  }

  setNewUserRole(user, newRole) {
    user.type = newRole
    this.authService.changeUserRole(user.userId, newRole).then((res: any) => {

      this.allUsers.map(localuser => {
        if (localuser.userId === res.result.userId) {
          localuser = res.result
        }
      })


    })
  }
  changeEvent(event: any) {
    if (event.target.id === "upload") { this.tmpNewUpload = event.target.checked } else if (event.target.id === "download") { this.tmpNewDownload = event.target.checked } else if (event.target.id === "write") { this.tmpNewWrite = event.target.checked }

  }

  publishNews(){
    if(this.news.title && this.news.title.length > 0){
      this.companyInfoService.addRSSEntry(this.news).then(res => {this.news_button_label = 'Finished'});
    }
  }

  setNewsLanguage(lang: string) {
    this.news.lang = lang;
  }

  getIssuerUsers() {
    this.issuerService.getIssuerUsers().toPromise().then((res: any) => {
      this.issuerUsers = res.result;
    });
  }

  addIssuerUser() {
    this.issuerService.addIssuerUser(this.newIssuerUser).toPromise().then(res => {
      this.issuerService.getIssuerUsers().toPromise().then((res: any) => {
        this.issuerUsers = res.result;
        this.newIssuerUser = emptyIssuerUser();
      });
    });
  }
  deleteIssuerUser(user: IssuerUser) {
    this.issuerService.deleteIssuerUser(user).toPromise().then(res => {
      this.issuerService.getIssuerUsers().toPromise().then((res: any) => {
        this.issuerUsers = res.result;
      });
    });
  }
  updateIssuerUser(user: IssuerUser) {
    this.issuerService.updateIssuerUser(user).toPromise().then(res => {
      this.issuerService.getIssuerUsers().toPromise().then((res: any) => {
        this.issuerUsers = res.result;
      });
    });
  }
  updateIssuerUserPassword(user: IssuerUser) {
    if(user.password === user.passwordB){
      let newPassword = user.password
      this.issuerService.updateIssuerUserPassword(user, newPassword).toPromise().then(res => {
        this.issuerService.getIssuerUsers().toPromise().then((res: any) => {
          this.issuerUsers = res.result;
        });
      });
    }else{
      return
    }

  }

  public openSettingsModal(company: any, index): void {
    const modalRef = this.modalservice.open(AdminPanelSettingsModalComponent, { centered: true, windowClass: 'custom-modal', size: 'xl' })
    modalRef.componentInstance.company = company;
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.companies = this.companies;
    modalRef.result.then(
      () => {
        this.companyInfoService.refreshCompanyList();
      },
      () => {
        this.companyInfoService.refreshCompanyList();
      }
    );
  }

}


