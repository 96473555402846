import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyInfoService } from '../../../../services/company-info.service';
import { DataServiceService } from '../../../../services/data-service.service';
import { deepCopy, translateProgrammingString } from '../../../../utilities';
import { FileUploaderComponent } from '../../../file-uploader';


@Component({
  selector: 'app-block-pdf-simple-list-research',
  templateUrl: './block-pdf-simple-list-research.component.html',
  styleUrls: ['./block-pdf-simple-list-research.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockPdfSimpleListResearchComponent implements OnInit {
  constructor(
    private companyInfoService: CompanyInfoService,
    private cdr: ChangeDetectorRef,
    private dataService: DataServiceService,
    private modalService: NgbModal,
  ) { }
  @Input() reportPanelMode = false
  @Input() data: any;  // data.src
  @Input() block_id: any;
  @Input() adminMode: boolean;
  @Input() blockType: string;
  @Input() deleteEmitter: any;
  @Input() metaCompanyName: string;
  @Input() metaBloombergTicker: any;
  @Input() metaPageType: string;

  isEditorMode = false;
  loadingEditor = false;
  changeMode = true;
  uploaderMode = false;
  bloombergTicker;
  companyName;
  pdfMode: string;
  layoutOptionList = true;
  layoutOptionImageList = false;
  layoutOptionDetailedList = false;
  layoutOptionPDFSlider = false;
  notInitalized = true;
  headline = '';
  carouselIndex = 0
  pdfsInChangeMode = []
  alwaysGetLatestResearch = false;
  loadingLatestPdfs = false
  public shownPDFsinCarousel = 4
  numPdfsShown = 4;

  // Files upload
  files: any = []; // pdfs
  images: any = []; // Images

  public imagePath;
  imgURL: any;
  public message: string;
  statusMessage = '';
  maxAdminPDFs = 3;
  shownAdminPDFs = 3;

  ngOnInit(): void {
    if (window.innerWidth <= 960) {
      this.shownPDFsinCarousel = 2
    }

    this.fixURLforStaticFiles()

    if (this.data.alwaysGetLatestResearch === true) {
      this.loadLatestPDfs().then(() => {
        this.loadingLatestPdfs = false
        this.cdr.detectChanges()
      })
    }

    this.companyName = this.companyInfoService.getCompanyNameByBloombergTicker(this.data.bloombergTicker);
    this.bloombergTicker = this.data.bloombergTicker;
    this.loadLayout(this.data);
    this.pdfMode = this.data.pdfMode;
    this.headline = this.data.headline;
    this.notInitalized = this.hasMinimumData();

    this.alwaysGetLatestResearch = this.data.alwaysGetLatestResearch !== null && this.data.alwaysGetLatestResearch !== undefined ? this.data.alwaysGetLatestResearch : false;

    if (this.data.pdfLinks === undefined || this.data.pdfLinks === null || this.data.pdfLinks.length < 0) {
      this.data.pdfLinks = [];
    }
    if (this.data.pdfImages === undefined || this.data.pdfImages === null || this.data.pdfImages.length < 0) {
      this.data.pdfImages = [];
    }
    this.pdfsInChangeMode = this.data.pdfLinks.map(el => { return false })
    this.cdr.detectChanges()
  }

  min(numberA: number, numberB: number) {
    return Math.min(numberA, numberB)
  }

  showSlide(pdfs, index) {
    if (pdfs.length > 0) {
      if (pdfs[index].imageURL !== null && pdfs[index].imageURL !== undefined && pdfs[index].imageURL !== "") { return pdfs[index].imageURL } else {
        return "assets/blankpdf.jpg"
      }

    }

    return null;
  }

  getPrev(pdfs, i) {
    if (this.carouselIndex >= 1) {
      this.carouselIndex = this.carouselIndex - 1;
    } else {
      this.carouselIndex = 0
    }
  }

  getNext(pdfs, i) {
    if (this.carouselIndex < this.data.pdfLinks.length - this.shownPDFsinCarousel) {
      this.carouselIndex = this.carouselIndex + 1;
    }
  }

  toggleChangeName(i) {
    this.pdfsInChangeMode[i] = !this.pdfsInChangeMode[i]
    this.pdfsInChangeMode[i]
  }

  hasMinimumData(): boolean {
    if (this.bloombergTicker === 'Eingabe erforderlich' || !this.bloombergTicker.includes(':')) {
      return true;
    } else {
      return false;
    }
  }

  updateBBTicker(): void {
    this.updateBlockData(true);
  }

  updateBlockData(withreload: boolean): void {
    this.companyInfoService.updateBuildingBlock(this.metaBloombergTicker, this.metaPageType, false, this.block_id, this.blockType, this.data, null, null)
      .then(() => {
        if (withreload) {
          setTimeout(() => {
            this.deleteEmitter.next({ blockId: this.block_id, 'delete': false, 'reload': true });
            this.cdr.detectChanges()
          }, 300);
        }
      });
  }

  setPDFMode(newMode: string, forceUpdate: boolean): void {
    this.pdfMode = newMode;
    this.data.pdfMode = this.pdfMode;

    if (forceUpdate) {
      this.updateBlockData(true);
    }
  }

  initBlock(pdfMode: string): void {
    this.setPDFMode(pdfMode, false);
    this.updateBlockData(true);
  }

  setLayout(chosenLayout: string): void {
    if (this.data.layout === undefined) {
      this.data.layout = {};
      this.data.layout.list = true;
      this.data.layout.imageList = false;
      this.data.layout.detailedList = false;
      this.data.layout.PDFSlider = false

    }

    if (chosenLayout === 'list') {
      this.data.layout.list = true,
        this.data.layout.imageList = false,
        this.data.layout.detailedList = false;
      this.data.layout.PDFSlider = false

    }

    if (chosenLayout === 'imageList') {
      this.data.layout.list = false,
        this.data.layout.imageList = true,
        this.data.layout.detailedList = false;
      this.data.layout.PDFSlider = false

    }

    if (chosenLayout === 'detailedList') {
      this.data.layout.list = false,
        this.data.layout.imageList = false,
        this.data.layout.detailedList = true;
      this.data.layout.PDFSlider = false

    }
    if (chosenLayout === 'PDFSlider') {
      this.data.layout.list = false,
        this.data.layout.imageList = false,
        this.data.layout.detailedList = false;
      this.data.layout.PDFSlider = true
    }

    this.updateBlockData(true);
  }

  loadLayout(blockData): void {
    if (blockData.layout === undefined) {
      this.layoutOptionList = true;
      this.layoutOptionImageList = false;
      this.layoutOptionDetailedList = false;
      this.layoutOptionPDFSlider = false;
    } else {
      if (blockData.layout.list === true) {
        this.layoutOptionList = true;
        this.layoutOptionImageList = false;
        this.layoutOptionDetailedList = false;
        this.layoutOptionPDFSlider = false;
      }

      if (blockData.layout.imageList === true) {
        this.layoutOptionList = false;
        this.layoutOptionImageList = true;
        this.layoutOptionDetailedList = false;
        this.layoutOptionPDFSlider = false;
      }

      if (blockData.layout.detailedList === true) {
        this.layoutOptionList = false;
        this.layoutOptionImageList = false;
        this.layoutOptionDetailedList = true;
        this.layoutOptionPDFSlider = false;
      }
      if (blockData.layout.PDFSlider === true) {
        this.layoutOptionList = false;
        this.layoutOptionImageList = false;
        this.layoutOptionDetailedList = false;
        this.layoutOptionPDFSlider = true;
      }
    }
  }

  public startEditorMode(): void {
    this.isEditorMode = true;
    this.loadingEditor = true;
    this.cdr.detectChanges()
  }

  public endEditorMode(): void {
    this.isEditorMode = false;
    this.cdr.detectChanges()
  }

  deleteBlock(): void {
    this.deleteEmitter.next({ blockId: this.block_id, 'delete': true });
    this.cdr.detectChanges()
  }

  startChangeMode(): void {
    this.changeMode = true;
    this.cdr.detectChanges()
  }

  endChangeMode(): void {
    this.changeMode = false;
    this.isEditorMode = false;
    this.cdr.detectChanges()
  }

  loadLatestPDfs(): Promise<any> {
    this.loadingLatestPdfs = true
    return new Promise((resolve, reject) => {
      this.dataService.getLatestPDfs().subscribe((result: any) => {
        this.data.pdfLinks = result.map(el => el.pdfFile);

        for (let file of this.data.pdfLinks) {
          file['url'] = file.fileURL;
        }

        this.data.companyNames = result.map(el => el.companyName);

        resolve(true)
      })

    })

  }

  onCheckBoxChange(loadLatestPdfsOnly) {
    if (loadLatestPdfsOnly) {
      this.loadLatestPDfs().then(() => {
        this.loadingLatestPdfs = false
        this.cdr.detectChanges()
      })
    }

  }

  showUploader(): void {
    const modalRef = this.modalService.open(FileUploaderComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.uploaderMode = 'pdfswithimages';
    modalRef.componentInstance.bloombergTicker = this.bloombergTicker;
    modalRef.componentInstance.companyName = this.companyName;
    modalRef.componentInstance.pdfMode = this.pdfMode;
    modalRef.componentInstance.pdfLinks = deepCopy(this.data.pdfLinks)
    modalRef.componentInstance.pdfImages = deepCopy(this.data.pdfImages)
    modalRef.componentInstance.reportPanelMode = this.reportPanelMode
    modalRef.result.then((result) => {
      this.cdr.detectChanges()
      this.data.pdfLinks = result.pdfLinks;

      if (this.data.blockTag === '#researchPDF' || this.data.blockTag === '#pdf-research') {
        this.setDownloadButtonsToNewestPDF();
      }

      this.data.pdfImages = result.pdfImages;
      this.pdfsInChangeMode = this.data.pdfLinks.map(el => { return false })
      this.cdr.detectChanges()
      this.updateBlockData(false);
      this.cdr.detectChanges()
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC) {
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      } else if (reason === 'deny') {
      } else if (reason === 'ok') {
      } else {
      }
    });
  }

  deletePDF(file): void {
    const removeIndexPDF = this.data.pdfLinks.map(el => el.filename).indexOf(file.filename);
    const removeIndexImage = this.data.pdfImages.map(el => el.imageMapperID)
      .indexOf(this.data.pdfLinks[removeIndexPDF].imageMapperID);

    this.data.pdfLinks.splice(removeIndexPDF, 1);
    this.data.pdfImages.splice(removeIndexImage, 1);

    this.updateBlockData(false);
    this.pdfsInChangeMode = this.data.pdfLinks.map(el => { return false })
  }

  setDownloadButtonsToNewestPDF() {
    let pages = ['snapshot', 'quality', 'growth', 'research', 'valuation', 'financials', 'swot', 'chart', 'esg', 'corporate'];
    let link = this.data.pdfLinks[0].url;
    let date = new Date();

    let month = '' + (date.getMonth() + 1);
    if (month.length < 2) {
      month = '0' + month;
    }

    let day = '' + (date.getDate());
    if (day.length < 2) {
      day = '0' + day;
    }

    let today = date.getFullYear() + '-' + month + '-' + day;

    for (let page of pages) {
      this.companyInfoService.getLayoutForCompany(this.data.bloombergTicker, page, '', '', "block pdf 1").subscribe(layout => {
        if (layout) {
          for (let block of layout.blocks) {

            // WARNING HACK
            if (!block.data.blockTag) {
              block.data.blockTag = '#latestResearchButton';
            }

            if (block.data.blockTag === '#latestResearchButton') {
              this.statusMessage = 'Updated button with new research in editing mode. Publish if you wish.';
              block.data['link'] = link;
              block.data['date'] = today;
              setTimeout(() => this.companyInfoService.updateBuildingBlock(this.data.bloombergTicker, page, false, block.block_id, block.blockType, block.data, null, null), 1500);
              this.cdr.detectChanges();
            }
          }
        }
      })
    }
  }

  changeTeaserText(): void {
    this.updateBlockData(false);
  }

  showMorePDFs() {
    this.numPdfsShown = Math.min(this.data.pdfLinks.length + 1, this.numPdfsShown + 5);
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.data.pdfLinks, event.previousIndex, event.currentIndex);
    this.cdr.detectChanges()
  }

  saveAllChanges(): void {
    this.updateBlockData(true);
  }

  translate(s: string): string {
    return translateProgrammingString(s);
  }
  // this is a hack to fix the url for the static files, so we dont have to change it in db
  fixURLforStaticFiles() {
    if (this.data.pdfLinks && this.data.pdfLinks.length > 0) {
      for (let pdf of this.data.pdfLinks) {
        pdf.url = pdf.url.replace("api.7markets.de", "downloads.research-hub.de")
      }
    }
    if (this.data.pdfImages && this.data.pdfImages.length > 0) {
      for (let pdf of this.data.pdfLinks) {
        pdf.url = pdf.url.replace("api.7markets.de", "downloads.research-hub.de")
      }
    }

  }
}
