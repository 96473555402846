import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CompanyInfoService } from 'src/app/services';

@Component({
  selector: 'app-fundamental-score',
  templateUrl: './fundamental-score.component.html',
  styleUrls: ['./fundamental-score.component.scss']
})
export class FundamentalScoreComponent implements OnInit {
  @Input() companyId = '';
  @Input() companyName = '';
  stock: any = {
    ratings: {
      general: 50,
      finance: 50,
      earnings: 50,
      pricepervalue: 50,
      ethical: 50
    }
  };
    url: any;
    noData = false;
  constructor(private companyInfoSerice: CompanyInfoService, private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {

    this.companyInfoSerice.getFundamentalScore(this.companyId).subscribe((res: any) => {
      this.stock = res.content;

      this.cdr.detectChanges();
    },(err)=>{
      this.noData = true
    });
  }

}
