import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'app-responsive-video',
  templateUrl: './responsive-video.component.html',
  styleUrls: ['./responsive-video.component.scss']
})
export class ResponsiveVideoComponent implements OnInit, OnChanges {

  constructor(
    private embedService: EmbedVideoService,
    private cdr: ChangeDetectorRef
  ) { }
  @Input() videoURL = "https://vimeo.com/522221637"
  public renderedHTMLVideo
  reloading = true;
  ngOnInit(): void {

    this.catchMissingLink()
    this.renderedHTMLVideo = this.createEmbeddedHTML(this.videoURL)

  }

  ngOnChanges() {
    this.reloading = true;
    this.catchMissingLink()
    this.renderedHTMLVideo = this.createEmbeddedHTML(this.videoURL)
    this.cdr.detectChanges()
  }

  catchMissingLink() {
    if (this.videoURL === "" || this.videoURL === null || this.videoURL === undefined) {
      this.videoURL = "https://vimeo.com/522221637"
    }
  }

  createEmbeddedHTML(vimeoUrl) {
    this.reloading = false;
    return this.embedService.embed(vimeoUrl, {
      query: { border: 1, portrait: 0, byline: false, title: false, badge: true, color: 'FFF', responsive: true },
      attr: { width: '768', height: "432" }
    })
  }
}
