<div>
  <div class="tile-title row">
      <div class="col-10">Major Shareholders</div>

      <div class="col-2 text-right fingerFix" (click)="closeWindow()">
          <div>X</div>
      </div>
  </div>
      <div>
        <app-template-chart class="full-width tile-component text-center" [noSliders]="true" [companyData]="companyData" [baseyear]="baseyear" [mapping]="mapping" [config]="config"></app-template-chart>
      </div>
</div>