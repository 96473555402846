import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDragNDropFiles]'
})
export class DragNDropFilesDirective {
@HostBinding('class.fileover') fileOver:boolean
  constructor() { }

@Output() fileDropped = new EventEmitter<any>();

// Listens to the event when file is dragged over the drop area
@HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }
// Listens to the event when file is dragged out of the drop area
@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }
// Listens to the event when file is dropped in the drop area
@HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    this.fileOver = false;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
    
  }


}
