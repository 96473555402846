import { Component, OnInit } from '@angular/core';
import { CompanyInfoService } from 'src/app/services';

@Component({
  selector: 'app-transactionbanner',
  templateUrl: './transactionbanner.component.html',
  styleUrls: ['./transactionbanner.component.scss']
})
export class TransactionbannerComponent implements OnInit {

  innerWidth: any
  transactionBannerContent = { "text": "test test test test test test test test ", "link": "https://www.leeway.tech" };
  constructor(private companyInfoService: CompanyInfoService) { }

  ngOnInit(): void {
    this.transactionBannerContent = this.companyInfoService.getTransactionBannerContent()
  }

}
