<div>
  <!--   Change to 3 items per row:  set col-6 to col-4 in line 4 | remove strangeFontFixClass | use div>strong instead of h4 for name | remove <br *ngIf....>   -->
  <div class="row">
    <div *ngFor="let event of events | slice:0:(more ? undefined : 14)" class="col-12 col-lg-6 ">
      <div class="row mx-2 shadow mb-5 p-3 rounded">
        <!-- <div class="row mx-2"> -->
        <div class="col-12 order-2 order-sm-1 col-sm-7 p-2 bottomLine">
          <div>{{event?.date |date:'MMM dd, yyyy, HH:mm':timeZoneFormat(event?.date)}}</div>
          <!-- <div><strong>{{event.title}}</strong></div> -->
          <h4 class="mt-2">{{event.title}}</h4>
          <div class="" style="font-size:smaller !important; display: block;">
            <a class="btn btn-link float-left strangeFontSizeFix " style="padding:0 !important;"
              *ngIf="event.isCovered" [routerLink]="['/companies/'+event.companyName]" [fragment]="'X'">read
              research <i class="ml-1 fas fa-arrow-right"></i>
          </a>
            <br *ngIf="event.isCovered">
            <a class="btn btn-link float-left strangeFontSizeFix text-left" style="padding:0 !important;"
              *ngIf="event.presentationURL !== ''" [href]="[event.presentationURL]">download presentation<i
                class="ml-1 fas fa-arrow-right"></i></a>
          </div>
        </div>
        <div class="col-12 order-1 order-sm-2 col-sm-5 p-2 bottomLine">

          <div class="overlayWrapper" style="position:relative;  vertical-align: top;">
            <div id="modalClickerOverlay" *ngIf="event.videoURL" (click)="openVideoModal(event.videoURL)" style=" cursor: pointer; position:absolute; top:0; right:0; width:100%; height:100%;
              z-index:1049;"></div>
            <div id="modalClickerOverlay" *ngIf="!event.videoURL" style="position:absolute; top:0; right:0; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.7);
                          z-index:1049; text-align:center">
              <h4 style="position:absolute; top:50%; left:35%; color:white; transform:translateY(50%);  margin:0">
                no video</h4>
            </div>

            <app-responsive-video class="center-horizontally" [videoURL]="event.videoURL"></app-responsive-video>
            <!-- this does nothing: it is only for showing the thumbnail and play button-->
          </div>
        </div>

      </div>

    </div>
  </div>

</div>
<div class="col-12 pt-3 text-center">
  <button (click)="more = !more"  class="btn text-muted "> <i *ngIf="!more" class="fas fa-angle-down"></i><i *ngIf="more" class="fas fa-angle-up"></i>
    {{more ? 'show less' : 'show all'}}
  </button>
</div>