import { ChangeDetectorRef, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit, OnChanges } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { CompanyInfoService } from '../../../../services/company-info.service';
import { TableNotabsComponent } from 'src/app/sharedModule/contentParts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent implements OnInit, OnChanges {

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  year: number;
  @Input() config: { headline: string, type: string, content: string, height: number, width: number, mobile: boolean };

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  bgColor = null;
  color = null;

  targets = {
    'Interim Figures': 'Quarterly table',
  }

  constructor(
    private companyInfoService: CompanyInfoService,
    private viewportScroller: ViewportScroller,
    private cdr: ChangeDetectorRef,
    public modalService: NgbModal
  ) {
    
  }

  ngOnInit(): void {
    this.year = this.baseyear;
  }

  ngOnChanges(): void {
    if (Object.keys(this.companyData).length > 0) {
      this.baseyear = this.companyInfoService.getCompanyDataByBloombergTicker(this.companyData.CompanyId)?.baseyear;
    }
  }

  onTileEvent($event) {
    if ($event.type === 'color-change') {
      this.bgColor = $event.change.backgroundColor;
      this.color = $event.change.color;
      this.cdr.detectChanges();
    } else if ($event.type === 'half-year') {
      this.targets['Interim Figures'] = 'Half year table';
    } else if ($event.type === 'year-change') {
      this.year = $event.change.year;
    } else if($event.type === 'headline-change'){
      this.config.headline = $event.change.headline
    }

    this.tileEvent.emit($event);
  }

  scrollIntoView(emitter) {
    this.viewportScroller.scrollToAnchor(this.targets[emitter])
  }


  openModal(data) {
    const modalRef = this.modalService.open(data.component, {
      centered: true, size: 'xl', windowClass: "tableModal"
    });
    
    modalRef.componentInstance.companyData = this.companyData;
    modalRef.componentInstance.templateName = data.templateName;
    modalRef.componentInstance.collapse = false;
  }

  handleClick(headline) {
    if (headline === 'Interim Figures') {
       let tableName = this.targets[headline];
      this.openModal({
        component: TableNotabsComponent,
        templateName: tableName
      })
    }
  }
}
