import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { DataServiceService } from '../../../services/data-service.service';

import { Tables } from '../../../utilities'

@Component({
  selector: 'app-quickfacts',
  templateUrl: './quickfacts.component.html',
  styleUrls: ['./quickfacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickfactsComponent implements OnInit, OnChanges {
  @Input() addressInfo = false;
  @Input() companyId: string;
  @Input() companyName = '';
  @Input() companyData = '';
  @Input() templateName = 'Quickfacts';
  @Input() decimals = 0;

  tableLayout = this.tableUtil.defaultLayout;

  constructor(private tableUtil: Tables, private dataService: DataServiceService, private cdr: ChangeDetectorRef) {
   }

  ngOnInit(): void {
    if(typeof this.companyData !== 'undefined' && this.tableLayout === this.tableUtil.defaultLayout){
      this.dataService.getTableTemplate(this.templateName).subscribe((res: any) => {
        this.tableLayout = res.template
        this.tableUtil.createTableData(this.companyData, this.tableLayout, this.decimals, this.companyId).then(table => {
          this.tableLayout = table;
          this.cdr.detectChanges();
        })
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof this.companyData !== 'undefined'){
      this.dataService.getTableTemplate(this.templateName).subscribe((res: any) => {
        this.tableLayout = res.template
        this.tableUtil.createTableData(this.companyData, this.tableLayout, this.decimals, this.companyId).then(table => {
          this.tableLayout = table;
          this.cdr.detectChanges();
        })
      });
    }
  }
}
