import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { Tables } from '../../../../../utilities';

@Component({
  selector: 'app-sidebars',
  templateUrl: './sidebars.component.html',
  styleUrls: ['./sidebars.component.scss']
})
export class SidebarsComponent implements OnInit, OnChanges {

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: {headline: string, type: string, content: string[], height: number, width: number, mobile: boolean};
  @Input() decimals = 0;
  @Input() lang = 'eng';

  data: [{title: string, value: string, valueModifier: string}];
  index: number = 0;

  constructor(private tableUtils: Tables) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    // this.prepareData();
  }

  // //  if (this.companyData.general_information.shareh_name_A !== "#EMPTY") {
  // //     this.data.push({title:this.companyData.general_information.shareh_name_A, value:100*this.companyData.general_information.shareh_perc_A})
  // //   } 
  //   // if (this.companyData.general_information.shareh_name_B !== "#EMPTY") {
  //   //   this.data.push({name:this.companyData.general_information.shareh_name_B, value:100*this.companyData.general_information.shareh_perc_B})
  //   // }
  //   // if (this.companyData.general_information.shareh_name_C !== "#EMPTY") {
  //   //   this.shareholders.push({name:this.companyData.general_information.shareh_name_C, value:100*this.companyData.general_information.shareh_perc_C})
  //   // }
  //   // if (this.companyData.general_information.shareh_name_D !== "#EMPTY") {
  //   //   this.shareholders.push({name:this.companyData.general_information.shareh_name_D, value:100*this.companyData.general_information.shareh_perc_D})
  //   // }
  //   // if (this.companyData.general_information.shareh_name_E !== "#EMPTY") {
  //   //   this.shareholders.push({name:this.companyData.general_information.shareh_name_E, value:100*this.companyData.general_information.shareh_perc_E})
  //   // }
  //   // if (this.companyData.general_information.shareh_name_F !== "#EMPTY") {
  //   //   this.shareholders.push({name:this.companyData.general_information.shareh_name_F, value:100*this.companyData.general_information.shareh_perc_F})
  //   // }
  //   console.log("SHAREHOLDERS",this.data)

  // prepareData(){
  //   if(this.mapping && this.companyData  && this.companyData.base){
  //     for(let key of this.config.content){
        
  //       if(this.mapping[key]){
  //         let mappingEntry = this.mapping[key];
  //         if(mappingEntry.type === 'VALUE'){
  //           let entry = {title: mappingEntry[this.lang], value: this.tableUtils.getValue(this.companyData, mappingEntry, this.lang, this.decimals)};

  //           if(this.data){
  //             this.data.push(entry);
  //           }else{
  //             this.data = [entry];
  //           }
  //         } else if(mappingEntry.type === 'TIMESERIES'){
  //           if(!this.dates){
  //             this.dates = this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, 1, 1, 'date', this.lang, this.decimals);
  //           }

  //           let entry = {title: mappingEntry[this.lang], value: this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, 1, 1, 'value', this.lang, this.decimals)};

  //           if(this.data){
  //             this.data.push(entry);
  //           }else{
  //             this.data = [entry];
  //           }
  //         }
  //       }

  //     }
  //   }
  // }

}
