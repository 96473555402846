<div class="container-fluid ">
  <ng-template #rt let-r="result" let-t="term">
    <!--bla-->
    <a class=" uppercase screener__typeahead-link">
      {{r[language] }}
    </a>
  </ng-template>

  <div class="row">
    <div class="col-12 pad-bottom" *ngIf="!noblocktitle">
      <div class="row">
        <div class="col-12 pad-bottom">
          <h3>Stock Screener</h3>
        </div>
      </div>
    </div>



    <div class="col-12">
      <div class="row">
        <div class="col-lg-4 col-md-12 mt-1">
          <!-- <span class="mr-1">Sector:</span> -->
          <label class="bigger bold">Sector:</label>
          <!-- <span> -->
          <div class="d-inline-blockx screener">
            <select class="w-100" id="sectorToggle" [(ngModel)]="currentScreen.sector">
              {{currentScreen.sector}}
              <option *ngFor="let sector of sectors" [value]="sector">
                {{sector}}
              </option>
            </select>
          </div>
          <!-- </span> -->
        </div>
        <div class="col-lg-4 col-md-12 mt-1">
          <label class="bigger bold">Year:</label>
          <div class="d-inline-blockx screener">
            <select class="w-100" id="yearToggle" [(ngModel)]="currentScreen.year">
              {{currentScreen.year}}
              <option *ngFor="let year of years" [value]="year">
                {{year}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 mt-1">
          <label class="bigger bold">Country:</label>
          <div class="d-inline-blockx screener">
            <select class="w-100" id="yearToggle" [(ngModel)]="currentScreen.country">
              {{currentScreen.country}}
              <option *ngFor="let country of countries" [value]="country">
                {{country}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>



    <div class="col-12 mt-5">

      <p class="bigger bold">Popular search criteria by other investors:</p>
      <div class="score-filter">

        <button *ngFor="let item of keyNamesForButtons| keyvalue; let i = index" class="px-2 py-2 btn btn-inverted "
          (click)="addSearchKey(item)" [ngClass]="{ 'active': item.value }">
          {{mapping[item.key][language] || '...' }}
        </button>
      </div>



      <!-- <div class="row  row-eq-height my-3 ">

        <div *ngFor="let item of keyNamesForButtons| keyvalue; let i = index" class=" mxx-2 mxy-2 ">

          <button class=" col-3 btn btn-inverted w-100 h-100" (click)="addSearchKey(item)"
            [ngClass]="{ 'active': item.value }">
            {{mapping[item.key][language]}}</button>
        </div>


      </div> -->
    </div>


    <div class="col-12 my-3">
      <input id="typeahead-basic" type="text" class="w-100" placeholder="Add more filter criteria..."
        [ngbTypeahead]="search" (selectItem)="addSearchKeyWithTypeahead($event)" [resultTemplate]="rt"
        (click)="click$.next($any($event).target.value)" (focus)="focus$.next($any($event).target.value)" />

    </div>




    <div class="col-12">
      <table class="table table-striped">
        <tbody>
          <tr *ngFor="let query of currentScreen.queries; let i = index">
            <td>{{query.name}}</td>
            <td>
              <div class="form-inline">
                <label class="mx-3">Above</label>
                <input class="form-control w-50" type="number" name="gte" id="gte" placeholder=""
                  [(ngModel)]="query.$gte" />
              </div>
            </td>
            <td>
              <div class="form-inline">
                <label class="mx-3">Below</label>
                <input class="form-control  w-50" type="number" name="lte" id="lte" placeholder=""
                  [(ngModel)]="query.$lte" />
              </div>
            </td>

            <td>
              <div class="col-1 fingerFix center-vertical" (click)="deleteQueryObj(i)"><strong>&#10005;</strong></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-12 mt-3">
      <button *ngIf="!loadingScreen" class=" text-left btn btn-std" (click)="getScreen()">Search</button>
      <button *ngIf="loadingScreen" class=" text-left btn btn-std" disabled>Loading...</button>
      <span class=" ml-2 smaller" *ngIf="showError">An error occured. Please try again.</span>
      <button class=" float-right btn btn-link" [hidden]="companies.length <=0" (click)="parseToCSV()">Download
        CSV <i class="fas fa-arrow-right"></i> </button>
    </div>
  </div>


  <div class="row">
    <div class="table-wrapper container my-3 col-12" *ngIf="companies.length>0">
      <div class=" mx-auto mt-2 scrollme">
        <table class="table table-sm table-hover table-responsive screener-result">
          <tbody>
            <tr>
              <th class="width-250">
                <div>
                  Company Name
                </div>
              </th>
              <th *ngFor="let query of completedScreen.queries; let i = index">
                <div class="center-horizontal fingerFix" (click)="sortCompanies(query.key, i, false)">
                  {{query.name}}
                </div>
              </th>
            </tr>
            <tr *ngFor="let company of companies" class="mb-3">
              <td class="width-250">
                <div>
                  <a href='companies/investment-case/{{company.CompanyName}}' class="blackText">{{company.CompanyName}}</a>
                </div>
              </td>
              <td *ngFor="let query of completedScreen.queries">
                <div class="center-horizontal">
                  {{company[query.key]}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>






</div>
