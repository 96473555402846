<ng-template #noScoreBadData>
  <td class="contentTooltip">
    n/a</td>
</ng-template>
<ng-template #noMeterBadData>
  <span class="contentTooltip" style="color:#145389 !important">
    n/a</span>
</ng-template>
<div [ngClass]="{'border border-dark rounded' : adminMode}" id="{{data.blockTag}}">
  <div class="row">
    <div class="col-lg-7 col-md-12">
      <app-markets-editor *ngIf="!loadingEditor" [data]="data.text" [isEditorMode]="isEditorMode" class="bg-secondary"
        (editorClosedEventhandler)="editorClosedByChildEvent($event)" [editortouchedNOSAVE]="showLoadingIndicator">
      </app-markets-editor>
    </div>
    <div class="col-lg-5 col-md-12">
      <div class="row">
        <div class="col-lg-12 col-md-6 mt-4">
          <app-esg-score (sendLWCompanyname)="setLWComppanyName($event)"
            [symbolExchange]="metaBloombergTicker || data.bloombergTicker">
          </app-esg-score>
        </div>
        <div class="col-lg-12 col-md-6 mt-4">
          <app-esg-labels [symbolExchange]="metaBloombergTicker || data.bloombergTicker"></app-esg-labels>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row mt-5">
    <div class="col-12">
      <h3>Leeway Analytics</h3>
      <p>
        Leeway uses machine learning enhanced analysis methods to evaluate, score and rank listed companies
        by ESG metrics. The data is sourced and aggregated from various places around the
        web. The main data source is the companies sustainability report, in so far as one is provided. Our mission
        is a cleaner, more transparent and more accessible stock market and that mission, for us, starts with
        easy access to comparable data. The complete datasets are available on our <a target="_blank"
          href="https://www.leeway.tech">homepage</a>.
      </p>
    </div>
  </div> -->
  <div class="row">
    <div class="col-12">
      <h3 *ngIf="adminMode">
        <hr>
      </h3>
    </div>
    <div class="col-2">
      <button *ngIf="adminMode && !changeMode" class="btn btn-sm blockButton form-control btn-inverted-gray"
        (click)="startChangeMode()">Show settings</button>
      <button *ngIf="adminMode && changeMode" class="btn btn-sm  blockButtonform-control btn-inverted-gray"
        (click)="endChangeMode()">Hide settings</button>
    </div>
    <div class="col-4"> <small *ngIf="this.showSaveErrMsg">Autosave-Error: Please click "Save text in editor".</small>
    </div>
    <div class="col-2" *ngIf="adminMode && changeMode">
      <button
        ngbPopover="In order to delete blocks that have just been inserted, the page must be reloaded once. (reload or click on other subpage)"
        triggers="mouseenter:mouseleave" popoverTitle="Info"
        class="btn blockButton btn-sm btn-inverted-gray form-control" (click)="deleteBlock()">Delete block </button>
    </div>
    <div class="col-3" *ngIf="adminMode && changeMode">
      <button class="btn btn-inverted-gray blockButton form-control" (click)="updateBlockData(true, false)">Save all
        changes</button>
    </div>
    <div class="col-3 form-group form-inline" *ngIf="adminMode && changeMode">
      <label for="blockTagInput"><small>Block Tag</small></label>
      <input name="blockTagInput" class="form-control" type="text" [(ngModel)]="data.blockTag">
    </div>
    <div class="col-1" *ngIf="adminMode && changeMode"></div>
  </div>
  <div *ngIf="adminMode && changeMode" style="background-color: lightcyan" class="mx-2 my-2 px-4 py-4">
    <div class="row">
      <div class="col-12 text-center">
        <h5>Settings for {{ translate(blockType) }}</h5>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-3">

          </div>
          <div class="col-6  text-center" ngbPopover="Starting the editor can take a few seconds."
            triggers="mouseenter:mouseleave" popoverTitle="Info">
            <button *ngIf="!isEditorMode" class="btn blockButton btn-std btn-sm" (click)="startEditorMode()">Open
              Editor</button>
          </div>
          <div class="col-3 text-center">
            <strong *ngIf="loadingEditor">Starting editor....</strong>
            <strong class="bg-warning" *ngIf="showLoadingIndicator">Saving...</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
