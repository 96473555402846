import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SigninComponent } from 'src/app/auth';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,

  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.authService.isAuthenticated()) { return true; }
    this.openLogin(state.url)
    return false;
  }
  openLogin(rerouteURL: string = '') {
    const modalRef = this.modalService.open(SigninComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.status = this.authService.isAuthenticated()
    modalRef.componentInstance.reroute = true
    modalRef.componentInstance.rerouteURL = rerouteURL
    modalRef.result.then(() => {
    }, (reason) => {
      if (
        reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK ||
        reason === 'Cross click' ||
        reason === 'ok'
        ) {
        this.router.navigate([''])
        }
    });

  }
}
