import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { BaseButton } from '../base-button';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends BaseButton {
  @Output() public buttonClick: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    this.buttonClick.emit();
  }
}
