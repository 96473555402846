<div class="px-3 py-3">
  <div class="row text-right">
    <div class="col-12"><span class="fingerFix bigger text-muted"
        (click)="activeModal.dismiss('deny')"><strong>&#10005;</strong></span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 ">

      <h4>Thank you for subscribing.</h4>
    </div>
  </div>



  <div class="row mt-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>We have saved your EMail in our mailing list.<br> Please feel free to contact us anytime under <a
              class="bold darkblue" href="mailto:info@alsterresearch.com">info@alsterresearch.com</a>. </p>

        </div>
      </div>
    </div>
  </div>
