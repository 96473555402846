<div class="row justify-content-center">
  <div class="col-12 rss-feed">
    <div class="row w-100" style="height: 90%;">
      <h4 class="col-12 m-2 p-2">RSS Feed</h4>
      <div class="rss-feed-text-box" >
        <div (click)="showFullFeed(rss)" class="bg-yellow m-2 p-1 rss-feed-text-item" *ngFor="let rss of rssFeed">
          <div class="col-12 p-2  fingerFix">
            {{rss.title}}
          </div>
          <div class="col-12 text-secondary ">
            <i>{{rss.pubDate  | date:'MMM dd, HH:mm'}}</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
