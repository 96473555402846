<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button  class="btn-close" (click)="ngbModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p>{{text}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="ngbModal.close('Close click')">Close</button>
</div>
