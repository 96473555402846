import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieBannerModalComponent } from './basic-pages/modals/cookie-banner-modal/cookie-banner-modal.component';
import { AuthService, CompanyInfoService } from './services';
import { CookieService } from './services/cookie.service';
import { ToastService } from './services/toast.service';


const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private HEADER_OFFSET = 500 // 1 em = 16px =>  without banner: 25em = 400px; with banner = 28.5em = 456px; new company slider adds 53.3 px
  readonly SCROLL_DELAY = 1250; //1500

  title = 'researchHub';
  currentURL = '';

  showContactButton = false;
  showContactSlider = false;
  public isCookiesAccepted: boolean = false;
  showHeaderAndFooter = true
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private cookieService: CookieService,
    private companyInfoService: CompanyInfoService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {

  }
  tokenIsSet() {
    return !this.authService.checkDisclaimerModalTokenIsNotSet()
  }

  private cookiesIsAccepted(): boolean {
    return this.cookieService.checkCookiePermission();
  }



  ngOnInit() {

    if (window.innerWidth <= 1000) {
      this.showContactButton = false
    }

    if (this.companyInfoService.getTransactionMode()) {
      this.HEADER_OFFSET = this.HEADER_OFFSET + 56;
    }

    this.currentURL = this.router.url;

    if (this.cookiesIsAccepted()) {
      this.cookieService.setCookieAccepted();
      this.isCookiesAccepted = true;
    } else {
      this.cookieService.clearCookiePermission();
      const modalRef = this.modalService.open(CookieBannerModalComponent, {
        centered: true,
        size: "lg",
        backdrop: "static",
        keyboard: false,
      });
      modalRef.result.then(
        (closeMessage) => {
          closeMessage === "Accepted" && this.cookieService.setCookieAccepted();
          this.isCookiesAccepted = true;
        },
        (dismiss) => {
          // console.log("ngOnInit ~ dismiss:", dismiss)
          this.isCookiesAccepted = false;
          this.router.navigate(["/cookies-denied"]);
        }
      );
    }

    // if (this.tokenIsSet()) {
    //   this.modalService.open(DisclaimerModalComponent, {
    //     centered: true, size: 'lg', backdrop: 'static',
    //     keyboard: false
    //   });
    // }

    if (this.authService.isAuthenticated()) {
      this.authService.verifyToken().then(res => {
        this.cdr.detectChanges()
      })
    }

    // Subscribe to router events to toggle header and footer visibility
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showHeaderAndFooter = !event.url.includes('events/registration/');
      }
    });



  }
}
