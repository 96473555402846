<div class="cpage-header full-width cpage-inner-header " [ngClass]="{ 'bannerTransactionCPageSpacerNew': transactionMode }"
  #complexHeader>
  <div class="bg-bluearea px-2 px-lg-0">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 col-lg-9">
          <h2 class="d-none d-lg-block">{{companyName}}</h2>
          <h3 class="d-block d-lg-none">{{companyName}}</h3>
        </div>
        <div class="d-none d-lg-block col-lg-3 col-12 text-right my-auto">
          <h3 class="signalWord-complexHeader" [ngStyle]="{'color': recColors[rating]}">{{rating}}</h3>
        </div>
      </div>
      <div class="row mt-1 d-flex flex-column flex-lg-row justify-content-between">

        <div class="col-lg-7 col-12 d-lg-flex justify-content-start">
          <div class="uppercase text-muted">
            <span>{{companyData?.base?.sector}}</span>
            <br class="d-block d-lg-none">
            <span class="ml-lg-5">Last: {{companyData?.base?.quote_curr || companyData?.base?.curr}} </span>
            <span class="mr-lg-5 ml-lg-1" *ngIf="quotes?.close !== 'NA'"> {{quotes?.close |number:'1.2-2' }}</span>
            <span class="mr-lg-5 ml-lg-1" *ngIf="quotes?.close === 'NA'"> n/a</span>
            <span class="mx-lg-2" [ngClass]="quotes?.change < 0? 'negativeRed' : 'positiveGreen'">
              <span *ngIf="quotes?.change < 0"><i class=" arrowIcon fas fa-arrow-down"></i></span>
              <span *ngIf="quotes?.change > 0"><i class=" arrowIcon fas fa-arrow-up"></i></span>
              <span *ngIf="quotes?.change_p !== 'NA'">{{quotes?.change_p |number:'1.1-1'}}%</span>
              <span *ngIf="quotes?.change_p === 'NA'">n/a</span>
            </span>
          </div>

          <div class="mx-lg-4">

          </div>
        </div>
        <div class="d-none d-lg-flex col-lg-5 col-12  justify-content-end uppercase text-muted">
          <div class="mx-lg-4">Price target: {{companyData?.base?.quote_curr || companyData?.base?.curr}}
            {{companyData?.general_information?.cus_pt |
            number:'1.2-2'}}
          </div>
          <div class="ml-lg-5">Up-/Downside:
            <span [ngStyle]="{'color': recColors[rating]}">{{companyData?.financial_figures?.ratios?.cus_pt_range
              *
              100 |
              number:'1.1-1'}} %</span>
          </div>
        </div>


      </div>
    </div>
  </div>

  <div class="navi-background">
    <div class="row content-wrapper fix-mobile-flex-direction mt-lg-3 navi-background">
      <div class="col-6 col-lg-2 grayNavAreaHeight">
        <app-company-slider class="container-fluid full-width  " [onlyLeft]="true" [symbolExchange]="symbolExchange"
          (onURLEventEmitter)="onURLEvent($event)"></app-company-slider>
      </div>
      <div class="col-lg-8 d-none d-lg-block subpage-selector-center-content">
        <app-horizontal-nav-panel class="container-fluid full-width"></app-horizontal-nav-panel>
      </div>
      <div class="col-6 col-lg-2 grayNavAreaHeight">
        <app-company-slider class="container-fluid full-width " [onlyRight]="true" [symbolExchange]="symbolExchange"
          (onURLEventEmitter)="onURLEvent($event)"></app-company-slider>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="
  container-fluid 
  limit-pagewidthX 
  defaultPushdown" 
  [style.padding-top.px]="pushDownPadding"  
  >
    <div class="content-wrapper">
      <app-large-tile-grid *ngIf="companyData.general_information" [archives]="archives"
        [currentCompanyCMSEntry]="currentCompanyCMSEntry" [liveQuotes]="quotes" [companyData]="companyData"
        [buildingBlocksSnapshot]="buildingBlocksSnapshot" [buildingBlocksCorporate]="buildingBlocksCorporate"
        [symbolExchange]="symbolExchange" [archiveDate]="archiveDate" [archiveTitle]="archiveTitle"
        [companyName]="companyName" [videoURLObj]="videoURLObj" [buildingBlocksResearch]="buildingBlocksResearch |async"
        [mapping]="mapping" [loadingNewData]="loadingNewData" class=" container-fluid full-width" id="overview"
        #overview>
      </app-large-tile-grid>
    </div>
    <div class="row cpage-content content-background " *ngIf="buildingBlocksResearch | async; else loading">
      <div class="col-lg-12">
        <div class="row markets-custom-layout-wrapper  content-background-one page-padding-left-right"
          *ngIf="(buildingBlocksResearch | async) as blocksR; else loading">
          <div class="col-12" id="research">
            <h2 class="chapter-headline">Research</h2>
          </div>
          <div class="col-12" id="research">
            <app-editor-header [archiveDate]="archiveDate || null" [archiveTitle]="archiveTitle || null"
              (editModeChanged)="onEditModeChange($event, 'research')" [companyName]="companyName"
              [companyId]="symbolExchange" [pageType]="'research'"></app-editor-header>
          </div>
          <div class="col-12 col-lg-8" id="research">
            <app-markets-custom-layout *ngIf="blocksR" [buildingBlocks]="blocksR" [companyName]="companyName"
              [pageType]="'research'" [companyData]="companyData" [archiveTitle]="archiveTitle"
              [archiveDate]="archiveDate" [filterRHThree]="!(adminMode && changeMode)">
            </app-markets-custom-layout>
          </div>
          <div class="col-12 col-lg-4" id="research">
            <div class="mt-5">
              <app-tile-wrapper-grid [config]="researchTileSettings" [companyData]="companyData" [mapping]="mapping"
                [baseyear]="baseyear"></app-tile-wrapper-grid>
            </div>
          </div>
          <div class="col-12 archive" *ngIf="blocksR" id="research-archive">
            <div>
              <h3 class="my-4" *ngIf="archives.length > 0">Previous Updates</h3>
              <p *ngIf="adminMode">
                <strong>Attention:</strong> A changed title or deleted archive will only be shown correctly after a
                <strong>reload of this
                  page</strong>. Also: We <strong>strongly recommend</strong> changing titles and deleting archives
                only on the current
                main-article (the
                one which is not archived yet.).
              </p>
              <div class="row collapseBlockHeadline border-top border-light-blue"
                *ngFor="let page of archives.slice(0,numArchivesShown) let i = index">
                <div class="col-12 col-lg-8 archive py-1" *ngIf="!changeMode">
                  <div class="row">
                    <div class="col-12 col-lg-9 my-1">
                      <span (click)="page.expanded = !page.expanded" class="fingerFix">
                        <i class="fa fa-chevron-up px-2" *ngIf="page.expanded"></i>
                        <i class="fa fa-chevron-down px-2" *ngIf="!page.expanded"></i>
                        {{page.archiveTitle}}
                      </span>
                    </div>
                    <div class="col-12 col-lg-3 my-1">
                      <a target="_blank" class="btn btn-link p-0 float-right" [href]="page.downloadLink">Download
                        PDF</a>
                    </div>
                  </div>
                </div>
                <div class="col-12" id="research">
                  <div class="content-background-one-archive p-3" [ngClass]="{'is-hidden': !page.expanded}">
                    <app-markets-custom-layout [buildingBlocks]="(page.archivePage | async)" [companyName]="companyName"
                      [pageType]="'research'" [companyData]="companyData" [archiveTitle]="page.archiveTitle"
                      [archiveDate]="page.archiveDate" [filterRHThree]="!(adminMode && changeMode)">
                    </app-markets-custom-layout>
                  </div>
                </div>

                <div class="archive-editing" *ngIf="adminMode && changeMode">
                  <a mat-list-item [routerLink]="[cleanUrl(router.url)]"
                    [queryParams]="{'date': page.archiveDate,'title': page.archiveTitle}" [fragment]="'X'">
                    {{page.archiveTitle}}
                    <a class="btn btn-link" [href]="page.downloadLink">Download PDF</a>
                  </a>
                  <span class="border border-red text-muted" id="research">
                    <span *ngIf="initializedTitleChange && i === currentChangeIndex">
                      <input class="form-control " [(ngModel)]="newArchiveTitle">
                      <span class=" px-3 fingerFix" (click)="changeTitle(page, 'research')">Ok</span>
                      <span class=" px-3 fingerFix" (click)="initializedTitleChange = false;">cancel</span>
                    </span>
                    <span *ngIf="!initializedTitleChange && !initializedArchiveDeletion" class="px-3 fingerFix"
                      (click)="changeTitleMode(i,page.archiveTitle)">change</span>
                    <span *ngIf="!initializedArchiveDeletion && !initializedTitleChange" class="px-3 fingerFix"
                      (click)="initializedArchiveDeletion = !initializedArchiveDeletion; currentChangeIndex = i">delete</span>
                    <span *ngIf="initializedArchiveDeletion && i === currentChangeIndex" class="px-3 fingerFix"
                      (click)="deleteArchive(page, 'research')">yes, delete it!</span>
                    <span *ngIf="initializedArchiveDeletion && i === currentChangeIndex" class="px-3 fingerFix"
                      (click)="initializedArchiveDeletion = false;">cancel</span>

                  </span>
                </div>
              </div>
              <div class="row" *ngIf="numArchivesShown < archives.length" id="research">
                <div class="py-2 btn btn-link">
                  <i class="fas fa-chevron-down px-2"></i><a *ngIf="numArchivesShown < archives.length"
                    class="fingerFix" (click)="numArchivesShown = archives.length">show all</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!loadingIndicator" class="row trianglebelow trianglebelow-one"></div>
        <div class="row markets-custom-layout-wrapper  content-background-two page-padding-left-right"
          *ngIf="(buildingBlocksInvestmentCase | async) as blocksIC; else loading">
          <div class="col-12" id="keyfigures">
            <h2 class="chapter-headline">Key Figures</h2>
          </div>
          <div class="col-12" id="investmentcase">
            <app-editor-header [archiveDate]="archiveDate || null" [archiveTitle]="archiveTitle || null"
              (editModeChanged)="onEditModeChange($event,'investment-case')" [companyName]="companyName"
              [companyId]="symbolExchange" [pageType]="'investment-case'"></app-editor-header>
          </div>
          <div class="col-12 col-lg-8" id="keyfigures">
            <app-markets-custom-layout *ngIf="blocksIC" [buildingBlocks]="blocksIC" [companyName]="companyName"
              [pageType]="'investment-case'" [companyData]="companyData" [archiveTitle]="archiveTitle"
              [archiveDate]="archiveDate" [filterRHThree]="true" [expanded]="true">
            </app-markets-custom-layout>
          </div>
          <div class="col-12 col-lg-4" id="keyfigures">
            <div class="mt-5">
              <app-tile-wrapper-grid [config]="keyfiguresTileSettings" [companyData]="companyData" [mapping]="mapping"
                [baseyear]="baseyear"></app-tile-wrapper-grid>
            </div>
          </div>
        </div>
        <div *ngIf="!loadingIndicator" class="row trianglebelow trianglebelow-two"></div>
        <div class="row markets-custom-layout-wrapper  content-background-one page-padding-left-right"
          *ngIf="(buildingBlocksInvestmentCase | async) as blocksIC; else loading">
          <div class="col-12" id="investmentcase">
            <h2 class="chapter-headline">Investment Case</h2>
          </div>
          <div class="col-12" id="investmentcase">
            <app-editor-header [archiveDate]="archiveDate || null" [archiveTitle]="archiveTitle || null"
              (editModeChanged)="onEditModeChange($event,'investment-case')" [companyName]="companyName"
              [companyId]="symbolExchange" [pageType]="'investment-case'"></app-editor-header>
          </div>
          <div class="col-12 col-lg-8" id="investmentcase">
            <app-markets-custom-layout *ngIf="blocksIC" [buildingBlocks]="blocksIC" [companyName]="companyName"
              [pageType]="'investment-case'" [companyData]="companyData" [archiveTitle]="archiveTitle"
              [archiveDate]="archiveDate" [filterRHThree]="true" [expanded]="false">
            </app-markets-custom-layout>
          </div>
          <div class="col-12 col-lg-4" id="investmentcase">
            <div class="mt-5">
              <app-tile-wrapper-grid [config]="investmentCaseTileSettings" [companyData]="companyData"
                [mapping]="mapping" [baseyear]="baseyear"></app-tile-wrapper-grid>
            </div>
          </div>
          <div class="col-12 d-none d-lg-block">
            <h3 class="my-4">Investment Case in Charts</h3>
          </div>
          <div class="col-12" id="investmentcase">
            <app-tile-wrapper-grid [config]="chartTileSettingsIC" [companyData]="companyData" [mapping]="mapping"
              [baseyear]="baseyear"></app-tile-wrapper-grid>
          </div>
          <!-- <div class="col-12">
              <div class="btn btn-link mb-3 px-0" (click)="ICsettings.expanded = !ICsettings.expanded">
                <i class="fa fa-chevron-up px-2" *ngIf="ICsettings.showAll"></i>
                <i class="fa fa-chevron-down px-2" *ngIf="!ICsettings.showAll"></i>
                {{ICsettings.showAllLabel}}
              </div>
            </div> -->
        </div>
        <div *ngIf="!loadingIndicator" class="row trianglebelow trianglebelow-one"></div>
        <div class="row markets-custom-layout-wrapper  content-background-two page-padding-left-right"
          *ngIf="(buildingBlocksSWOT | async) as blocksSwot; else loading">
          <div class="col-12" id="swot">
            <h2 class="chapter-headline">SWOT-Analysis</h2>
          </div>
          <div class="col-12" id="swot">
            <app-editor-header [archiveDate]="archiveDate || null" [archiveTitle]="archiveTitle || null"
              (editModeChanged)="onEditModeChange($event, 'swot')" [companyName]="companyName"
              [companyId]="symbolExchange" [pageType]="'swot'"></app-editor-header>
          </div>
          <div class="col-12 col-lg-4 mt-5" id="swot">
            <!--roce-->
            <app-tile-wrapper-grid [config]="swotTileSettings" [companyData]="companyData" [mapping]="mapping">
            </app-tile-wrapper-grid>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-12 col-lg-7" id="swot">
            <app-markets-custom-layout *ngIf="blocksSwot" [buildingBlocks]="blocksSwot" [companyName]="companyName"
              [pageType]="'swot'" [companyData]="companyData" [archiveTitle]="archiveTitle" [archiveDate]="archiveDate"
              [filterRHThree]="!(adminMode && changeMode)">
            </app-markets-custom-layout>
          </div>
        </div>
        <div *ngIf="!loadingIndicator" class="row trianglebelow trianglebelow-two"></div>
        <div class="row markets-custom-layout-wrapper  content-background-one page-padding-left-right"
          *ngIf="(buildingBlocksValuation | async) as blocksValuation; else loading">
          <div class="col-12" id="valuation">
            <h2 class="chapter-headline">Valuation</h2>
          </div>

          <div class="col-12" id="valuation">
            <app-editor-header [archiveDate]="archiveDate || null" [archiveTitle]="archiveTitle || null"
              (editModeChanged)="onEditModeChange($event, 'valuation')" [companyName]="companyName"
              [companyId]="symbolExchange" [pageType]="'valuation'"></app-editor-header>
          </div>
          <div class="col-12 col-lg-8" id="valuation">
            <p class="base-font">
              At AlsterResearch, we apply different valuation techniques. The DCF model results in a fair
              value of {{getCompanyfiguresValue('curr')}} {{getCompanyfiguresValue('dcf_dcfps')}} per
              {{getCompanyfiguresValue('name')}} share. This is based on
              the following assumptions: for the top line, we expect a CAGR
              {{getCompanyfiguresValue('HL_dcf_2')}}-{{getCompanyfiguresValue('HL_dcf_9')}} in the range
              of {{getCompanyfiguresValue('dcf_plan_hor')}} p.a. The long-term growth rate is set at
              {{getCompanyfiguresValue('dcf_term_val_gro')}}. Cost
              of equity is calculated as {{getCompanyfiguresValue('dcf_cost_of_debt')}}. This results in a long-term
              WACC of
              {{getCompanyfiguresValue('dcf_term_val_wacc')}}. For further detail and a sensitivity analysis, see
              the DCF model below. We also
              perform a free cash flow analysis. The adjusted Free Cash Flow Yield results in a fair value between
              {{getCompanyfiguresValue('curr')}} {{getCompanyfiguresValue('RW_fcf_fvps_2')}} per share based on
              {{getCompanyfiguresValue('HL_fcf_2')}} and {{getCompanyfiguresValue('RW_fcf_fvps_6')}}
              {{getCompanyfiguresValue('curr')}}
              per share on {{getCompanyfiguresValue('HL_fcf_6')}} estimates. Again, more details can be found below.
            </p>
            <app-markets-custom-layout *ngIf="blocksValuation" [buildingBlocks]="blocksValuation"
              [companyName]="companyName" [pageType]="'valuation'" [companyData]="companyData"
              [archiveTitle]="archiveTitle" [archiveDate]="archiveDate" [filterRHThree]="true">
            </app-markets-custom-layout>
          </div>
          <div class="col-12 col-lg-4 mb-3" id="valuation">
            <app-tile-wrapper-grid [config]="valuationTileSettings" [companyData]="companyData" [mapping]="mapping">
            </app-tile-wrapper-grid>
          </div>
        </div>
        <div *ngIf="!loadingIndicator" class="row"></div>
        <div class="d-none d-lg-block row markets-custom-layout-wrapper  content-background-two page-padding-left-right"
          *ngIf="(buildingBlocksFinancials | async) as blocksF; else loading">
          <div class="col-12" id="financials">
            <h2 class="chapter-headline">Financials</h2>
          </div>
          <div class="col-12" id="financials">
            <app-editor-header [archiveDate]="archiveDate || null" [archiveTitle]="archiveTitle || null"
              (editModeChanged)="onEditModeChange($event, 'financials')" [companyName]="companyName"
              [companyId]="symbolExchange" [pageType]="'financials'"></app-editor-header>
          </div>
          <div class="col-12 col-lg-8" id="financials">
            <app-markets-custom-layout *ngIf="blocksF" [buildingBlocks]="blocksF" [companyName]="companyName"
              [pageType]="'financials'" [companyData]="companyData" [archiveTitle]="archiveTitle"
              [archiveDate]="archiveDate" [filterRHThree]="true">
            </app-markets-custom-layout>
          </div>
          <!-- <div class="col-4 mb-3" id="financials">
                <app-tile-wrapper-grid [config]="financialTileSettings" [companyData]="companyData" [mapping]="mapping">
                </app-tile-wrapper-grid>
              </div> -->
        </div>
        <div *ngIf="adminMode">
          <div class="row markets-custom-layout-wrapper  content-background-one page-padding-left-right"
            *ngIf="(buildingBlocksCorporate | async) as blocksC; else loading">
            <div class="col-12" id="corporate">
              <h2 class="chapter-headline">Corporate</h2>
            </div>
            <div class="col-12" id="corporate">
              <app-editor-header [archiveDate]="archiveDate || null" [archiveTitle]="archiveTitle || null"
                (editModeChanged)="onEditModeChange($event, 'corporate')" [companyName]="companyName"
                [companyId]="symbolExchange" [pageType]="'corporate'"></app-editor-header>
            </div>
            <div class="col-12 col-lg-8" id="corporate" *ngIf="changeMode">
              <app-markets-custom-layout *ngIf="blocksC" [buildingBlocks]="blocksC" [companyName]="companyName"
                [pageType]="'corporate'" [companyData]="companyData" [archiveTitle]="archiveTitle"
                [archiveDate]="archiveDate" [filterRHThree]="!(adminMode && changeMode)">
              </app-markets-custom-layout>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block d-lg-none row cpage-content content-background ">
      <div class="col-12">
        <app-tile-wrapper-grid [config]="infotextTileSettings" [companyData]="companyData" [mapping]="mapping"
        [baseyear]="baseyear"></app-tile-wrapper-grid>
      </div>
    </div>
    <hr>
    <div class="row content-wrapper" style="margin-top: 3em;">
      <div class="col-12">
        <h3>Other interesting companies</h3>
      </div>
      <app-company-grid class="full-width" [displayMode]="'companyPage'" [numRows]=1></app-company-grid>
    </div>
    <div class="row content-wrapper">
      <app-subscribe-banner class="full-width"></app-subscribe-banner>
    </div>

  </div>
</div>
<ng-template #loading>
  <h4 class="my-5 py-5">Loading...</h4>
</ng-template>