<div class="container-fluid px-0">
  <!-- Intro-Image -->
  <section class="bg-bluearea px-5 fill-image fill-image--gradient topslider-area d-flex gap-3">
    <div class="limit-pagewidth col-12 content-wrapper d-flex justify-content-start">
      <h1 class="introText ml-3 mt-4 d-none d-md-block ">Your platform for high-quality <br><span class="blueText">
        research and events</span>
    </h1>
      <h2 class="mt-4 mt-md-5 d-block d-md-none ">Your platform for high-quality <br><span class="blueText">
        research and events</span>
    </h2>
    </div>
    <div class="fill-image__image">
      <img loading="lazy" src="../../../assets/intro.jpg" alt="ResearchHub">
    </div>
    
  </section>
  <!-- new events banner -->
  
  <section class="px-5 d-flex gap-3" style="background-color: rgba(223, 233, 246, 1);">
    <div class="limit-pagewidth content-wrapper d-flex">
      <app-auto-slider class=""></app-auto-slider>
    </div>
  </section>

  <section class="pt-5 px-5 bg-yellow">
    <div class="row content-wrapper limit-pagewidth pt-5 pb-1">
      <div class="col-lg-7 col-md-12">
        <h2>
          Gain access to <span class="yellowText"><br> companies through us</span>
        </h2>

      </div>
      <div class="col-lg-5 col-md-12">
        <p class="bigger">Get in touch with key people in interesting companies. Register now for upcoming roadshows,
          roundtables and conferences.</p>
      </div>
      <div class="col-12 pt-4">
        <hr>
      </div>
    </div>
  </section>

  <section class="px-5 bg-yellow pb-4" id="#eventgrid">
    <div class="content-wrapper limit-pagewidth">
      <!-- <app-event-grid [adminMode]="adminMode"></app-event-grid> -->
      <app-event-list-lp></app-event-list-lp>
    </div>
  </section>

  <!-- text -->
  <section class="p-5">
    <div class="row content-wrapper limit-pagewidth pt-5 pb-1">
      <div class="col-lg-7 col-md-12">
        <h2>
          Find the research <span class="blueText"><br> you are looking for</span>
        </h2>

      </div>
      <div class="col-lg-5 col-md-12">
        <p class="bigger">We frequently publish updates and the universe of companies we cover is continuously
          expanding. So make
          sure to
          come back and check regularly.</p>

      </div>
      <div class="col-12 pt-4">
        <hr>
      </div>
    </div>
  </section>

  <!-- company tiles and search -->
  <section class="px-5">
    <div class="content-wrapper limit-pagewidth">
      <app-company-grid></app-company-grid>
    </div>
  </section>

  <!-- request coverage banner -->
  <!-- <section class="p-5">
    <div class="row text-center content-wrapper limit-pagewidth py-4">
      <div class="col-lg-3 col-md-0"></div>
      <div class="col-lg-6 col-md-12 border-grey">
        <div class="p-5">
          <h3>Missing a company?</h3>
          <p>You can request coverage for a company or get your own company listed by filling ot a short form.</p>
          <span><button class="btn btn-link bigger py-0" (click)="requestCoverage()">request coverage
              <i class="fas fa-arrow-right"></i></button></span>
        </div>
      </div>
      <div class="col-lg-3 col-md-0"></div>
    </div>
  </section> -->



  <!-- Weekly screener-->
  <section class="px-5" #weeklyScreener id="weeklyScreener">
    <div class="row content-wrapper limit-pagewidth" *ngIf="(buildingBlocks | async) as weeklyScreeners">
      <div class="col-lg-1 col-md-0"></div>
      <div class="col-lg-10 col-md-12 text-center p-5">
        <h2 class="p-4 d-md-block d-none"><span class="blueText">AlsterResearch Weekly <br></span> Fresh ideas for your
          performance</h2>
        <h4 class="p-4 d-block d-md-none"><span class="blueText">AlsterResearch Weekly <br></span> Fresh ideas for your
          performance</h4>
        <p class="bigger px-4">Our sales desk and our analysts take turns in uncovering hidden jewels and finding new
          angles on old
          acquaintances.
        </p>
      </div>
      <div class="col-lg-1 col-md-0"></div>
      <div class="col-12" *ngIf="adminMode">
        <button class="btn" [routerLink]="['blog/weeklyScreener']">+ New weekly screener</button>
      </div>


      <div *ngFor="let page of weeklyScreeners.archivedPages?.slice().reverse().slice(0,4); let i = index"
        class="col-lg-3 col-md-12 pb-5 ">
        <p class="small text-muted">{{niceDate(page.archiveDate)}}</p>
        <h3 *ngIf="i>0" class="nonzero fingerFix" [routerLink]="['blog/weeklyScreener']"
          [queryParams]="{'date': page.archiveDate,'title': this.encodeForURL(page.archiveTitle)}">{{page.archiveTitle}}
        </h3>
        <h3 *ngIf="i==0" class="zero fingerFix" [routerLink]="['blog/weeklyScreener']">{{page.archiveTitle}}</h3>
        <p>
          {{ (page.archiveTeaser.length>150)? (page.archiveTeaser | slice:0:150)+'...':(page.archiveTeaser) }}
        </p>
        <span *ngIf="adminMode">
          <span *ngIf="initializedTitleChange && i === currentChangeIndex" class="px-3 fingerFix">
            <input [(ngModel)]="newArchiveTitle">
            <span (click)="changeTitle(page, index)">Ok</span>
          </span>
          <span *ngIf="!initializedTitleChange" class="px-3 fingerFix"
            (click)="iniArchivetAction('change', i, page.archiveTitle)">ändern</span>
          <span *ngIf="!initializedArchiveDeletion" class="px-3 fingerFix"
            (click)="iniArchivetAction('delete', i, null)">x</span>
          <span *ngIf="initializedArchiveDeletion && i === currentChangeIndex" class="px-3 fingerFix"
            (click)="deleteArchive(page)">Sicher?</span>
        </span>
        <button *ngIf="i > 0" class="btn read-more blueText pl-0 fingerfix" [routerLink]="['blog/weeklyScreener']"
          [queryParams]="{'date': page.archiveDate,'title': this.encodeForURL(page.archiveTitle)}">read more -></button>
        <button *ngIf="i == 0" class="btn read-more blueText pl-0 fingerfix" [routerLink]="['blog/weeklyScreener']">read
          more -></button>
      </div>

    </div>
  </section>

  <!-- subscibe banner-->
  <section class="px-5">
    <div class="content-wrapper limit-pagewidth">
      <hr> <!-- häh warum wird die nicht angezeigt -.-.- -->
      <div class="py-5">
        <app-subscribe-banner class="full-width bg-bluearea"></app-subscribe-banner>
      </div>
    </div>
  </section>
</div>