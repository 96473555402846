<div class="mt-5 mx-4">

    <div *ngFor="let article of news" class="row my-3">
    <div class="col-12 text-muted">
        <small>
            <a href={{article.url}}>{{formatNewsDate(article.date)}}</a></small>
    </div>
    <div class="col-12">
        <a href={{article.url}}><b class=""><small style="color:black;">{{article.title |slice:0:50}}...</small></b></a>
        <div class="text-muted"><small>{{article.summary  |slice:0:150}}...</small></div>
    </div>
    <div class="col-12">
        <hr>
    </div>
</div>
</div>