<div class="container my-3 row">

  <div class="col-11">
    <h5>Chart Templater</h5>
  </div>
  <div class="col-1">
    <h5 class="fingerFix" (click)="activeModal.dismiss('deny')"><strong>X</strong></h5>
  </div>
</div>

<div class="row" *ngIf="!type">
  <div class="col-6">
    <p>Choose chart class</p>
  </div>
  <div class="col-3">
    <div ngbDropdown class="d-inline-block">
      <button class="btn  blockButton btn-outline-primary" id="chartTypeToggle" ngbDropdownToggle>
        Set Charttype </button>
      <div ngbDropdownMenu aria-labelledby="chartTypeToggle">
        <button *ngFor="let chartType of chartTypes" class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
          (click)="setCharttype(chartType)">
          {{chartType.name}}
        </button>
      </div>
    </div>
  </div>
  <div class="col-3">
  </div>
</div>

<div class="container tableTemplate row" *ngIf="type?.key === 'TIMESERIES'">
  <div class="col-4" *ngIf="updateMode===false">Template Name: </div>
  <div class="col-8" *ngIf="updateMode===false">
    <input class="form-control" type="text" name="templateName" id="templateName" placeholder="Template name"
      [(ngModel)]="defaultTemplate.name" />
  </div>

  <div class="col-4">Chart Titel: </div>
  <div class="col-8">
    <input class="form-control" type="text" name="templateTitle" id="templateTitle" placeholder="Titel"
      [(ngModel)]="defaultTemplate.template.name[defaultTemplate.template.settings.language]" />
  </div>

  <div class="col-4">Sprache: </div>
  <div class="col-8">
    <div ngbDropdown class="d-inline-block">
      <button class="btn blockButton  btn-outline-primary" id="languageToggle" ngbDropdownToggle> Sprache </button>
      <div ngbDropdownMenu aria-labelledby="languageToggle">
        <button *ngFor="let lang of languages" class="btn  blockButton btn-inverted-gray my-1" ngbDropdownItem
          (click)="setLanguage(lang, 0)">
          {{lang}}
        </button>
      </div>
    </div>
  </div>
  <div class="col-4 small">
    Anzahl Jahre Vergangenheit
  </div>
  <div class="col-2">
    <input class="form-control" type="text" name="numberPastYears" id="numberPastYears"
      placeholder="Anzahl Jahre Vergangenheit" [(ngModel)]="defaultTemplate.template.settings.numYearsBack" />
  </div>
  <div class="col-4 small">
    Anzahl Jahre Zukunft
  </div>
  <div class="col-2">
    <input class="form-control" type="text" name="numberFutureYears" id="numberFutureYears" placeholder="1"
      [(ngModel)]="defaultTemplate.template.settings.numYearsFront" />
  </div>

  <div class="table-wrapper container my-3 customFont">
    <div class="row py-0 my-0">
      <div class="table-responsive mx-auto mt-2">
        <div>
          <table class="table table-sm table-hover bilanzkennzahlen">
            <tbody>
              <tr>
                <th>
                  <div>
                    Leeway Key
                  </div>
                </th>
                <th>
                  <div>
                    Anzeigetyp
                  </div>
                </th>
                <th></th>
                <th>Y-Achse</th>
                <th></th>
                <th></th>
              </tr>
              <tr *ngFor="let row of defaultTemplate.template.data; let i = index">
                <td>
                  <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="row.key"
                    [ngbTypeahead]="search" />
                </td>
                <td>
                  <div>
                    {{row.type}}
                  </div>
                </td>
                <td>
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn  blockButton btn-outline-primary" id="visualizationToggle" ngbDropdownToggle>
                      Set Visualization </button>
                    <div ngbDropdownMenu aria-labelledby="visualizationToggle">
                      <button *ngFor="let visualizationType of visualizationTypes"
                        class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
                        (click)="setVisualizationType(visualizationType, i)">
                        {{visualizationType}}
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div *ngIf="row.yaxis">
                    {{row.yaxis}}
                  </div>
                </td>
                <td>
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn  blockButton btn-outline-primary" id="axisToggle" ngbDropdownToggle>
                      Set Y-Axis </button>
                    <div ngbDropdownMenu aria-labelledby="axisToggle">
                      <button *ngFor="let axisNumber of axisNumbers" class="btn  blockButton btn-inverted-gray my-1"
                        ngbDropdownItem (click)="setYAxis(axisNumber, i)">
                        {{axisNumber}}
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <small class="fingerFix" (click)="deleteRow(i)">X</small>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                  <small class="fingerFix" (click)="addRow(0)">Neue Zeile</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container tableTemplate row" *ngIf="type?.key === 'VALUE'">
  <div class="col-4" *ngIf="updateMode===false">Template Name: </div>
  <div class="col-8" *ngIf="updateMode===false">
    <input class="form-control" type="text" name="templateName" id="templateName" placeholder="Template name"
      [(ngModel)]="defaultTemplate.name" />
  </div>

  <div class="col-4">Chart Titel: </div>
  <div class="col-8">
    <input class="form-control" type="text" name="templateTitle" id="templateTitle" placeholder="Titel"
      [(ngModel)]="defaultTemplate.template.name[defaultTemplate.template.settings.language]" />
  </div>

  <div class="col-3">
    Sprache:
  </div>
  <div class="col-3">
    <div ngbDropdown class="d-inline-block">
      <button class="btn  blockButton btn-outline-primary" id="languageToggle" ngbDropdownToggle>
        {{defaultTemplate.template.settings.language}} </button>
      <div ngbDropdownMenu aria-labelledby="languageToggle">
        <button *ngFor="let lang of languages" class=" blockButton btn btn-inverted-gray my-1" ngbDropdownItem
          (click)="setLanguage(lang, 0)">
          {{lang}}
        </button>
      </div>
    </div>
  </div>
  <div class="col-1">
  </div>
  <div class="col-3">
  </div>
  <div class="col-1">
  </div>

  <div class="table-wrapper container my-3 customFont">
    <div class="row py-0 my-0">
      <div class="table-responsive mx-auto mt-2">
        <div>
          <table class="table table-sm table-hover bilanzkennzahlen">
            <tbody>
              <tr>
                <th>
                  <div>
                    Leeway Key
                  </div>
                </th>
                <th>
                  <div>
                    Label
                  </div>
                </th>
                <th>
                  <div>
                    Base year
                  </div>
                </th>
                <th></th>
              </tr>
              <tr *ngFor="let row of defaultTemplate.template.data; let i = index">
                <td>
                  <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="row.key"
                    [ngbTypeahead]="search" />
                </td>
                <td>
                  <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="row.label"
                    [ngbTypeahead]="search" />
                </td>
                <td>
                  <input *ngIf="mapping[row.key].type === 'TIMESERIES'" id="typeahead-basic" type="text"
                    class="form-control" [(ngModel)]="row.baseyear" [ngbTypeahead]="search" />
                </td>
                <td>
                  <small class="fingerFix" (click)="deleteRow(i)">x</small>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                </td>
                <td>
                  <small class="fingerFix" (click)="addRow()">Neue Zeile</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container row" *ngIf="type">
  <div class="text-center col-2" *ngIf="defaultTemplate.name!==''">
    <button class="mx-auto  blockButton  btn btn-std form-control" (click)="closeWindow()">Speichern</button>
  </div>
  <div class="text-center col-2" *ngIf="defaultTemplate.name===''">
    Bitte Templatenamen eingeben
  </div>
</div>
