<div class="container">
  <div class="row" style="padding-top: 50px;">
    <div class="col-10">
      <h3>Impressum</h3>
      <p>
        SRH AlsterResearch AG<br>
        Mittelweg 142<br>
        20148 Hamburg<br>
      </p>
      ​
      <p>
        Fon: +49 40 309 293-52<br>
        Fax: +49 40 556 330-54<br>
      </p>
      ​
      <p>
        E-Mail: info@alsterresearch.com<br>
      </p>
      ​
      <p>
        Vorstand: Thomas Wissler, Dr. Oliver Wojahn, CFA <br>
        Vorsitzender des Aufsichtsrats: Detlef Lübbe
      </p>
      ​
      <p>
        Inhaltlich Verantwortliche gemäß § 55 Abs. 2 RStV: Thomas Wissler
      </p>
      <p>
        Registergericht: Amtsgericht Hamburg – HRB 98756<br>
        USt-IdNr.: DE249083224<br>
      </p>
      ​
      <h3>
        Zuständige Aufsichtsbehörde:
      </h3>
      ​
      <p>
        Bundesanstalt für Finanzdienstleistungsaufsicht<br>
        Marie-Curie-Str. 24-28<br>
        60439 Frankfurt<br>
      </p>
      ​
      <p>
        Postfach 50 01 54<br>
        60391 Frankfurt<br>
      </p>
      <p>
        Fon: +49 228 / 4108 – 0<br>
        Fax: +49 228 / 4108 – 123<br>
        E-Mail: poststelle@bafin.de<br>
        De-Mail: poststelle@bafin.de-mail.de<br>
      </p>
      ​


      ​
      <p>
        Haftungshinweis<br>
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den
        Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      </p>
      ​

      <p>
        Bildnachweise<br>
        Urheber und Bildquelle der verwendeten Bilder:
        Nick Herbold, Jarrestrasse 82a, 22303 Hamburg, www.sehenswuerdigkeiten-in.hamburg
      </p>
    </div>
  </div>
</div>
