import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DataServiceService } from '../../../services/data-service.service';

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss']
})

export class NewsfeedComponent implements OnInit, OnChanges {
  @Input() type = '';
  @Input() companyName: string;
  @Input() symbolExchange: string;
  @Input() queryTerm: any;

  //TODO create a news model
  news: Array<any>;


  constructor(private dataService: DataServiceService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    // console.log("META",this.queryTerm, this.symbolExchange);
    this.getNews();
  }

  ngOnChanges(): void {
    this.getNews();
  }

  getNews(): any {
    setTimeout(() => {
      // console.log("META2",this.queryTerm, this.symbolExchange);
      this.dataService.getNews(this.symbolExchange, this.companyName, this.type, this.queryTerm).subscribe(res => {
        this.news = res as Array<any>;
        this.cdr.detectChanges();
      })
    }, 1000);
  }

  formatNewsDate(date: string): string {
    let splitDate = date.split(' ')[0].split('-');
    date = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0] + ' ' + date.split(' ')[1];
    return date;
  }

}
