<div [ngClass]="{ 'border border-dark rounded editMode': adminMode }" id="{{data.blockTag}}">
  <h3>{{data.blockHeadline || "Corporate video"}}</h3>
  <div class="my-3" #measurementDiv>
    <div *ngIf="!data" class="text-center my-5">
      Kein Video eingestellt
    </div>

    <div class="mx-auto px-auto text-center embed-responsive embed-responsive-16by9">
      <div class="embed-responsive-item" [innerHTML]="renderedHTML"></div>
    </div>

  </div>
  <div class="row" *ngIf="adminMode">
    <div class="col-12">
      <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
        [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName" [metaBloombergTicker]="metaBloombergTicker"
        [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData"></app-block-handling>
    </div>
  </div>
</div>
