import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prediction-channel',
  templateUrl: './prediction-channel.component.html',
  styleUrls: ['./prediction-channel.component.scss']
})
export class PredictionChannelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
