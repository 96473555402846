import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CompanyInfoService } from 'src/app/services';

@Component({
  selector: 'app-esg-labels',
  templateUrl: './esg-labels.component.html',
  styleUrls: ['./esg-labels.component.scss']
})
export class EsgLabelsComponent implements OnInit {
  @Input() symbolExchange = '';
  noData = false;

  stock: any = {
    labels: {
      negativeLabels: [],
      positiveLabels: []
    }
  }
  constructor(private companyInfoSerice: CompanyInfoService, private cdr: ChangeDetectorRef,) { }

  ngOnInit(): void {

    this.companyInfoSerice.getESGLabels(this.symbolExchange).subscribe((res: any) => {
      this.stock["labels"] = res;
      this.stock['companyName'] = res.companyName;
      this.stock["labels"].positiveLabels = this.stock["labels"].positiveLabels.reverse()
      this.stock["labels"].negativeLabels = this.stock["labels"].negativeLabels.reverse()
      this.cdr.detectChanges();
    }, (err) => {
      this.noData = true
    });
  }

}
