<div *ngIf="data.link">
  <b>{{data.date}}</b>
  <a Pdfdownload [clickedURL]="data.link" class="float-right" target="_blank" [href]="data.link">
    <app-button color="primary"> Download PDF</app-button>
  </a>
</div>
<div class="row" *ngIf="adminMode">
  <div class="col-12">
    <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
      [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName" [metaBloombergTicker]="metaBloombergTicker"
      [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData"></app-block-handling>
  </div>
</div>
