<div class="subpage-selector__links-wrapper desktop-navigation mt-1" *ngFor="let jumpmark of navigationPoints; let i = index">
  <div class="mx-2 mx-lg-2 mx-xxl-4" (mouseleave)="handleHoverDropdown($event, false, i)">
    <div #DropdownNavigation="ngbDropdown" ngbDropdown  NgbDropdownAnchor [attr.id]="'jumpmarkID-'+jumpmark.jumpmark"
      class="subpage-selector__dropdown" [ngClass]="i<navigationPoints.length-1?'not-last':'last'">
      <a class="subpage-selector__link uppercase"
      (click)="scrollIntoView(jumpmark.jumpmark)"
      [ngClass]="{'subpage-selector__link--active':jumpmark.inView,'nonActiveLink':!jumpmark.inView }"
        (mouseover)="handleHoverDropdown($event, true, i)">
        {{ jumpmark.name }}
      </a>
    </div>
  </div>
</div>
