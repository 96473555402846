<div class="min-height" [ngClass]="{'border-custom-red rounded' : userIsAdmin && !reportPanelMode}">
  <!-- <div *ngIf=" buildingBlocks !== null && buildingBlocks.length < 1">
    <h3>Coming soon..</h3>
  </div> -->
  
  <div *ngIf="userIsAdmin && !reportPanelMode" class="row text-center">
    <div class="col-12">
      <h5>Modular Area</h5>
    </div>
  </div>

  <ng-template appLayoutHost></ng-template>

  <div *ngIf="userIsAdmin && !reportPanelMode" class="row border border-black rounded px-4 py-4 my-5 mx-auto"
    style="background-color: hsl(4, 0%, 64%)">
    <div *ngIf=" buildingBlocks !== null && buildingBlocks.length < 1">
      <p>Insert blocks here...</p>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              Add new block
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button class="btn  blockButton btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('editor')">
                Text-Block
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
                (click)="addNewBlock('largeImage')">
                Large Image
              </button>
              <button class="btn blockButton  blockButton  btn-inverted-gray my-1" ngbDropdownItem
                (click)="addNewBlock('charting')">
                Charting
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('newsfeed')">
                Newsfeed
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
                (click)="addNewBlock('pdf-research')">
                PDF-Uploader
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('keyfacts')">
                Keyfacts-Block
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
                (click)="addNewBlock('financialtable')">
                Financials-Table
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
                (click)="addNewBlock('table-notabs')">
                Standard-Table
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
                (click)="addNewBlock('table-tabs')">
                Table with Tabs
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('tv-chart')">
                Tradingview-Chart
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('screener')">
                Screener
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('esg-all')">
                ESG-Overview
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('video')">
                Video
              </button>
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('dcfModel')">
                DCF Model
              </button>

              <!-- for now just latest research. if needed rename/reuse for multiple button functions -->
              <button class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('button')">
                Latest Research Button
              </button>
              <!-- <button class="btn btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('esg-labels')">
            ESG-Labels (einzeln)
          </button>
          <button class="btn btn-inverted-gray my-1" ngbDropdownItem (click)="addNewBlock('esg-score')">
            ESG-Score (einzeln)
          </button> -->
            </div>
          </div>
        </div>
        <div class="col-6">
          <h5 class="texte-center">Current blocks</h5>
          <p><small>Change order with drag & drop.</small></p>
          <div cdkDropList class="example-list-dragdrop" (cdkDropListDropped)="drop($event)">
            <div class="example-box-dragdrop" *ngFor="let block of buildingBlocks; let i = index" cdkDrag>
              <div class="example-custom-placeholder-dragdrop" *cdkDragPlaceholder></div>
              <p class="block-info">
                {{i}}: {{ translate(block.blockType) }}
              </p>
            </div>
          </div>
          <button class="btn blockButton  btn-std" (click)="saveNewOrderedParts()" ngbPopover="
            If a new block has been inserted, the page should be reloaded before the order is changed."
            triggers="mouseenter:mouseleave" popoverTitle="Info">Save new order</button>
        </div>
      </div>
    </div>
  </div>
</div>
