<h3 class="text-left">{{headline}}</h3>
<div class="table-wrapper container py-0 my-0 customFont">
  <div class="row py-0 my-0">
    <div class="table-responsive mx-auto mt-2">
      <ngb-tabset [destroyOnHide]="false">
        <ngb-tab *ngFor="let tab of tableLayout">
          <ng-template ngbTabTitle>
            <small class="colorBlack">
              {{tab.name[tab.settings.language]}}
            </small>
          </ng-template>
          <ng-template ngbTabContent>
            <div>
              <table class="table table-sm table-hover">
                <tbody>
                  <tr *ngFor="let row of tab.data">
                    <th [attr.colspan]="row.colspan" [ngClass]="{
                        'rowHighlight': row.highlight,
                        rowHighlight2: row.highlight2,
                        'tabHeader': row.headline
                      }">
                      {{ row.key }}
                    </th>
                    <td *ngFor="let datapoint of row.data" [ngClass]="{
                        'rowHighlight': row.highlight,
                        rowHighlight2: row.highlight2,
                        'tabHeader': row.headline
                      }">
                      {{ datapoint }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>

      <p>Source: {{ dataSource.eng }}</p>
    </div>
  </div>
</div>
