import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { mapPageTypes } from '../utilities';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta) { }

  setMetaTags(title, description, url, pageType) {

    if (title === null) {
      title = "AlsterResearch | Digital ResearchHub | Equity research "
    }
    if (description === null) {
      description = "The ResearchHub provides free MiFID II-compliant research on German stocks and access to key people in interesting companies via roadshows and conferences."
    }
    if (url === null) {
      url = "https://www.research-hub.de"
    }
    if (pageType === null) {
      pageType = "Equity research"
    }

    this.hidePageFromSEO(false);
    this.setGeneralTags(title, description, url, pageType);
    this.setOGTags(title, description, url, pageType);
  }

  hidePageFromSEO(isActive) {
    if (isActive) {
      this.meta.updateTag({ property: 'robots', content: "noindex nofollow" });
    } else {
      this.meta.removeTag("robots")
    }
  }

  setGeneralTags(title, description, currentURL, pageType) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'title', property: 'title', content: title });
    this.meta.updateTag({ name: 'description', property: 'description', content: description });
    this.meta.updateTag({ name: 'url', property: 'url', content: currentURL });
    this.meta.updateTag({ name: 'site_name', content: mapPageTypes(pageType) })
  }

  setOGTags(title, currentUrl, description, pageType) {
    this.meta.updateTag({ name: 'og:title', property: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:type', property: 'og:type', content: 'Website' });
    this.meta.updateTag({ name: 'og:url', property: 'og:url', content: currentUrl }); // this.router.url
    this.meta.updateTag({ name: 'og:description', property: 'og:description', content: description });
    this.meta.updateTag({ name: 'og:site_name', content: mapPageTypes(pageType) })
  }

  updateTitle(title: string, pageType: string) {
    let newTitle = title + " - " + mapPageTypes(pageType)
    this.title.setTitle(newTitle);
    this.meta.updateTag({ name: 'og:title', content: newTitle })
  }

  updateOgSitename(sitename: string, pageType: string) {
    let newSitename = sitename + mapPageTypes(pageType)
    this.meta.updateTag({ name: 'og:site_name', content: newSitename })
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'og:description', content: desc })

  }
}
