<div class="container py-0 my-0 px-0 customFont" *ngIf="!noData">

  <ng-template class="customPopups" #popContent>
    <p>The Leeway Rating scores the stocks fundamental data on a scale of -100 to 100. The significance of key ratios
      for a stocks performance were statistically evaluated over the past years. On that basis, the interpretation of
      more than 200 data points are aggregated for an in-depth, performance-oriented evaluation of the stock.</p>
  </ng-template>

  <div class="rowx py-0 my-0 px-0 mx-0">
    <div class="col-12x">
      <div class="py-0">


        <div class="">
          <h5>Financial Score<span [ngbPopover]="popContent" placement="bottom" #popover="ngbPopover"></span>
            <div class="float-right fingerFix" (click)="popover.toggle()"><small style="font-size: small;">
                info
              </small></div>
          </h5>


          <div *ngIf="!noData">
            <div class="score-progress col-12x" ngbTooltip="">
              <label for="gesamtBewertung d-flex justify-content-between">
                <span>Total:</span>
                <span>{{stock.ratings.general |number:'1.1-1'}} </span>
              </label>
              <meter name="gesamtBewertung" value="{{stock.ratings.general}}" value="80" min="-120" low="-66"
                optimum="100" high="66" max="120"></meter>
            </div>
          </div>
          <div class="col-12x mt-2" ngbTooltip="" *ngIf="!noData">
            <div class="score-progress">
              <label for="sicherheit d-flex justify-content-between">
                <span>Finance:</span>
                <span>{{stock.ratings.finance|number:'1.1-1'}}</span>
              </label>
              <meter name="sicherheit" value="80" min="-120" low="-66" optimum="100" high="66" max="120"
                value="{{stock.ratings.finance}}" max="120"></meter>
            </div>
          </div>
          <div class="col-12x mt-2 " ngbTooltip="" *ngIf="!noData">
            <div class="score-progress">
              <label for="potential d-flex justify-content-between">
                <span>Price:</span>
                <span>{{stock.ratings.pricepervalue |number:'1.1-1'}} </span>
              </label>
              <meter name="potential" value="80" min="-120" low="-66" optimum="100" high="66" max="120"
                value="{{stock.ratings.pricepervalue}}" max="120"></meter>
            </div>
          </div>
          <div class="col-12x mt-2 " ngbTooltip="" *ngIf="!noData">
            <div class="score-progress">
              <label for="rendite d-flex justify-content-between">
                <span>Earnings:</span>
                <span>{{stock.ratings.earnings|number:'1.1-1'}} </span>
              </label>
              <meter name="rendite" value="80" min="-120" low="-66" optimum="100" high="66" max="120"
                value="{{stock.ratings.earnings}}" max="120"></meter>
            </div>
          </div>
          <div class="col-12x mt-2" *ngIf="!noData">
            <div class="score-progress">
              <label for="sicherheit d-flex justify-content-between">
                <span>Ethical:</span>
                <span>{{stock.ratings.ethical|number:'1.1-1'}}</span>
              </label>
              <meter name="sicherheit" value="80" min="-120" low="-66" optimum="100" high="66" max="120"
                value="{{stock.ratings.ethical}}" max="120"></meter>
            </div>
          </div>

          <div class="col-12x mt-3"><a href="https://www.leeway.tech" target="_blank">
              <small class="muted">Powered
                by
              </small>
              <img loading="lazy" class="float-right"
                src="https://leeway.tech/assets/logo/Leeway_Logo_ohne_Icon_2020-04_RGB.svg" alt="Leeway">
            </a>
          </div>

        </div>

      </div>


    </div>

  </div>
</div>
