<div class="container my-3">

  <div class="row">
    <div class="col-11">
      <h5>File-Upload</h5>
    </div>
    <div class="col-1">
      <h5 class="fingerFix" (click)="activeModal.dismiss('deny')"><strong>X</strong></h5>
    </div>
  </div>
</div>
<div class="container" *ngIf="uploaderMode === 'imageOnly'">

  <div class=" uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadImageFile($event)">
    <input hidden type="file" #fileInput (change)="uploadImageFile($event.target.files)" multiple>
    <p class="text-center center-text align-self-center">Klicke in das blaue Feld, um <strong>eine</strong> Bild-Datei
      auszuwählen. Drag&Drop ist derzeit leider nicht möglich.</p>
  </div>


  <div class="row">
    <div class="col-12" *ngIf="files.length>1">
      <div class="text-center center-text">
        <p>Bitte nur eine Bild-Datei hochladen. Anzahl ausgewählter Dateien: {{files.length}}</p>
      </div>
    </div>

    <div class="col-12 files-list" *ngFor="let file of files;let i=index">
      <p *ngIf="!file.uploadComplete">{{file.name}}</p>
      <p *ngIf="!file.uploadComplete">{{file.eprogress}} %</p>
      <p></p>
      <p *ngIf="file.uploadStartet && !file.uploadComplete">Uploading...</p>
      <p></p>
      <p *ngIf="file.uploadComplete">Upload fertig!</p>

      <button class="delete-file" (click)="deleteImageAttachment(i)">
        <span class="fa mr-1 text-muted">
          X
        </span>
      </button>
    </div>
    <div class="col-12">
      <button (click)="startImageUpload()" class="btn btn-sm btn-std">Dieses Bild hochladen.</button>
    </div>
  </div>

</div>
<div class="container" *ngIf="uploaderMode === 'pdfswithimages'">

  <div class="row">
    <div class="col-12">
      <h4>2 most recent uploads...</h4>
    </div>
    <div class="col-12" *ngFor="let file of files; let i=index">
      <div *ngIf="i > (files.length-3)" class="row files-list">
        <div class="col-1">

        </div>
        <div class="col-4">

          <span class="my-auto">
            <p>Filename: <small class="breakWords">
                {{file.name}}
              </small></p>


            <label for="showname">
              Enter custom Name<br>
            </label>
            <input [disabled]="file.uploadComplete" placeholder="Namen eingeben" type="text" name="shownname"
              id="showname" class="form-control" [(ngModel)]=file.shownName>
          </span>

        </div>
        <div class="col-4">
          <div *ngIf="(!file.uploadComplete || file.prefilled) && !file.previewURL"
            class="uploadfilecontainer form-control bigUpload"
            [ngStyle]="{'background-color':!useDefaultImage? 'AntiqueWhite':'grey'}" (click)="fileInput.click()"
            appDragDrop>
            <p class="text-center center-text align-self-center">Select image</p>
            <input [disabled]="useDefaultImage" class="form-control" hidden type="file" #fileInput
              (change)="addImage($event.target.files, file.imageMapperID)" multiple>
          </div>
          <!-- <p class="my-auto">{{images[i]?.name}}</p> -->
          <figure *ngIf="file.previewURL" class="my-auto mx-auto align-self-center">
            <img class="previewImage form-control" [src]="file.previewURL" alt="preview">
            <figcaption *ngIf="(!file.uploadComplete || file.prefilled)" class="fingerFix text-center"
              (click)="deleteImage(images[0].imageMapperID)">delete</figcaption>
          </figure>
        </div>
        <div class="col-2">
          <p class="my-auto" *ngIf="file.uploadComplete">Upload finished!</p>
        </div>
        <div class="col-1">
          <button class="delete-file btn btn-std btn-inverted-gray" (click)="deleteAttachment(i)">
            <h5 class="fa mr-1 text-center fingerFix">
              X
            </h5>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12" *ngFor="let file of files; let i=index">
      <div *ngIf="newPdfs>=1 && i === newPdfs-1" class="row files-list">
        <div class="col-1">

        </div>
        <div class="col-4">

          <span class="my-auto">
            <p>Filename: <small class="breakWords">
                {{file.name}}
              </small></p>


            <label for="showname">
              Enter custom Name<br>
            </label>
            <input [disabled]="file.uploadComplete" placeholder="Namen eingeben" type="text" name="shownname"
              id="showname" class="form-control" [(ngModel)]=file.shownName>
          </span>

        </div>
        <div class="col-4">
          <div *ngIf="(!file.uploadComplete || file.prefilled) && !file.previewURL"
            class="uploadfilecontainer form-control bigUpload"
            [ngStyle]="{'background-color':!useDefaultImage? 'AntiqueWhite':'grey'}" (click)="fileInput.click()"
            appDragDrop>
            <p class="text-center center-text align-self-center">Select image</p>
            <input [disabled]="useDefaultImage" class="form-control" hidden type="file" #fileInput
              (change)="addImage($event.target.files, file.imageMapperID)" multiple>
          </div>
          <!-- <p class="my-auto">{{images[i]?.name}}</p> -->
          <figure *ngIf="file.previewURL" class="my-auto mx-auto align-self-center">
            <img class="previewImage form-control" [src]="file.previewURL" alt="preview">
            <figcaption *ngIf="(!file.uploadComplete || file.prefilled)" class="fingerFix text-center"
              (click)="deleteImage(images[0].imageMapperID)">delete</figcaption>
          </figure>
        </div>
        <div class="col-2">
          <p class="my-auto" *ngIf="file.uploadComplete">Upload finished!</p>
        </div>
        <div class="col-1">
          <button class="delete-file btn btn-std btn-inverted-gray" (click)="deleteAttachment(i)">
            <h5 class="fa mr-1 text-center fingerFix">
              X
            </h5>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container row my-5"></div>
  <div class="col-12">
    <div class=" uploadfilecontainer" (click)="fileInput.click()" appDragDrop>
      <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" multiple>
      <p class="text-center center-text align-self-center">Select PDFs</p>
    </div>
  </div>
  <div class="col-12 text-center">

    <input type="checkbox" [(ngModel)]="useDefaultImage" id="defaultimage">
    <label class="ml-2" for="defaultimage">use image from previous upload</label>

  </div>
  <div class="col-12 text-center" *ngIf="showMissingImageErrorMsg">
    <p>You have to upload an image for each PDF you added. Click the checkbox to your the previous image.</p>
    <div class="loaderLine my-2"></div>
  </div>
  <div class="col-12">
    <div class="text-center" ngbPopover="
    If not all PDFs have been uploaded, the 'Upload all' button can be clicked several times."
      triggers="mouseenter:mouseleave" popoverTitle="Info">
      <button *ngIf="files.length <=0 || showMissingImageErrorMsg" class=" mx-auto btn-inverted-gray btn btn-std"
        disabled>Upload all</button>
      <button *ngIf="files.length >0 && !showMissingImageErrorMsg" class="mx-auto  btn btn-std"
        (click)="uploadClicked()">Upload all</button>

    </div>


  </div>
</div>
<div class="container row my-5">
  <!-- started: {{uploadStarted}}
  finished: {{uploadFinished}} -->
  <div class="col-10"><span *ngIf="uploadStarted && !uploadFinished">Uploading... Please wait...</span></div>

  <div class="text-center col-2">
    <button *ngIf="uploadFinished" class="mx-auto  btn btn-std form-control" (click)="closeWindow()">Continue</button>
    <!-- <button class="mx-auto  btn btn-std form-control" (click)="closeWindow()">Fertig</button> -->
  </div>

</div>
