<div class="limit-pagewidth">
  <div class="content-wrapper" *ngIf="(buildingBlocks | async) as archivedPages">
    <div class="row" style="padding-top: 50px;">
      <div class="col-12">
        <app-editor-header [archiveDate]="archiveDate || null" [archiveTitle]="archiveTitle || null"
          (editModeChanged)="onEditModeChange()" [companyName]="companyName" [pageType]="pageType">
        </app-editor-header>
      </div>
    </div>


    <!-- nä das ist quark. wir machen das mit dem existierenden admin head archive System und da kommt halt noch n teaser dazu-->
    <!-- <div class="row" *ngIf="adminMode">
    Title: <input type="text" [(ngModel)]="archiveTitle">
    <p class="caps muted">{{niceDate(buildingBlocks?.archiveDate)}}, WEEKLY SCREENER {{buildingBlocks?.archivedPages?.findIndex(e => e.archiveDate === buildingBlocks?.archiveDate)}}</p>
    Teaser text: <input type="text" [(ngModel)]="teaserText">
        </div> -->

    <div class="row py-3 px-3 px-md-0">
      <div class="col-12">
        <h2 class="pt-5">{{archivedPages?.archiveTitle ||
          archivedPages.archivedPages[archivedPages.archivedPages.length-1].archiveTitle }}</h2>
        <p class="caps text-muted">{{niceDate(archivedPages?.archiveDate) ||
          niceDate(archivedPages.archivedPages[archivedPages.archivedPages.length-1].archiveDate)}}, <span
            class="uppercase">featured ideas</span>
          {{screenerNumber}}</p>
      </div>
    </div>


    <div class="row px-3  px-md-0">
      <div class="col-lg-8 col-md-12 markets-custom-layout-wrapper">
        <div *ngIf="adminMode && newScreenerMode">
          <h3>Create a new screener</h3>
          <ol>
            <li>Click on "Änderungen an Block/WeeklyScreener vornehmen"</li>
            <li>Click on "Editor starten" and change content</li>
            <li>Click on "Text im Editor Speichern"</li>
            <li>Click on "Aus den Änderungen einen Artikel erstellen"</li>
            <li>Type in title and teaser</li>
            <li>Click on "Artikel veröffentlichen" and go to landingpage to see result.</li>
          </ol>
          <span class="pull-right">
            <button class="btn btn-std " *ngIf="!showTitleInput && adminMode" (click)="showTitleInput = true">Aus
              den
              Änderungen einen neuen Artikel erstellen</button>
            <input class="form-control" *ngIf="showTitleInput" id="archiveTitle" placeholder="Archiv: Titel"
              [(ngModel)]="archiveTitle">

            <textarea *ngIf="showTitleInput" class="form-control" name="archiveTeaser" id="" rows="10"
              placeholder="Archiv: Teaser,  150 Zeichen werden angezeigt" [(ngModel)]="archiveTeaser"></textarea>


            <button class="btn btn-std " *ngIf="showTitleInput && adminMode " (click)="archivePublishedPage()">Artikel
              veröffentlichen</button>
          </span>
          <div>
            Full screener link:

            <a
              href="https://www.research-hub.de/blog/weeklyScreener?date={{
              archivedPages.archivedPages[archivedPages.archivedPages.length-1].archiveDate}}&title={{formatURLParam(archivedPages.archivedPages[archivedPages.archivedPages.length-1].archiveTitle) }}">https://www.research-hub.de/blog/weeklyScreener?date={{
              archivedPages.archivedPages[archivedPages.archivedPages.length-1].archiveDate}}&title={{formatURLParam(archivedPages.archivedPages[archivedPages.archivedPages.length-1].archiveTitle)
              }}</a>
          </div>
        </div>

        <app-markets-custom-layout [archiveTitle]="archiveTitle" [archiveDate]="archiveDate"
          [buildingBlocks]="archivedPages" [companyName]="companyName" [pageType]="pageType"
          [companyData]="companyData">
        </app-markets-custom-layout>
      </div>

      <div class="col-lg-4 col-md-12" *ngIf="(buildingBlocksSmallList | async) as archivedPagesCurrent">
        <h3 class="py-4">Most recent</h3>
        <div
          *ngFor="let page of archivedPagesCurrent?.archivedPages?.slice().reverse().slice(0,maxPreview); let i = index"
          class="py-3" (click)="loadAnotherScreener(page.archiveDate,page.archiveTitle,i)">
          <p class="text-muted">{{page.archiveDate}}
          </p>
          <h4>{{page.archiveTitle}}</h4>
          <p>{{page.archiveTeaser}}</p>
          <button class="btn btn-link caps px-0"
            (click)="loadAnotherScreener(page.archiveDate,page.archiveTitle,i)">read
            more
            <i class="fas fa-arrow-right"></i></button>
        </div>
        <div class="button-group d-flex justify-content-between">
          <button *ngIf="maxPreview <=5 " class="float-left btn btn-sm btn-link" (click)="showMore(true)">show more
          </button>
          <button *ngIf="maxPreview >=10 " class=" float-right  btn btn-sm btn-link" (click)="showMore(false)">show
            less</button>
        </div>

      </div>
    </div>

    <div class="row py-4 mt-3">
      <div class="col-12">
        <app-company-grid [numRows]=1></app-company-grid>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-subscribe-banner></app-subscribe-banner>
      </div>
    </div>
  </div>
</div>
