import { OnInit, Input, Directive } from '@angular/core';

enum LinkButtonColor {
  primary = 'btn-std',
  basic = 'btn-inverted-gray',
}

// Need to use Directive decorator because Angular features inside the class
@Directive()
// By default, all classes marked with Directive decoratore must have 'Directive' suffix
// tslint:disable-next-line
export abstract class BaseButton implements OnInit {
  @Input() public color: 'primary' | 'basic' = 'basic';

  public cssClasses: string;

  public ngOnInit(): void {
    this.generateCssClasses();
  }

  private generateCssClasses(): void {
    this.cssClasses = `btn ${LinkButtonColor[this.color]}`;
  }
}
