<!-- <ng-template #rt let-r="result" let-t="term" class="selected">
  <a class="company-list__typeahead-link">
    <strong><span style="font-size: larger;">{{r}}</span></strong>
  </a>

</ng-template> -->



<!-- <div *ngIf="formMode !== 'newsletter'">
  <div *ngIf="emailMode !== ''" class="px-3 py-3">
    <div class="row text-right">
      <div class="col-12"><span class="fingerFix bigger text-muted"
          (click)="activeModal.dismiss('deny')"><strong>&#10005;</strong></span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 ">

        <h2>Just one last step... </h2>
      </div>
    </div>

    <div class="row mx-5 my-3" *ngIf="emailMode === 'subscribetoevent'">
      <div class="col-12 text-center">
        <h4>{{this.selectedEvent}}</h4>
      </div>
    </div>

    <div class="row mt-3">
      <div class="container">
        <div class="row">
          <div class="col-md-12">

            <form [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()">

              <div class="form-row">


                <div class="form-group col-6">
                  <label class="bold">EMail address</label>
                  <input type="text" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                  </div>
                </div>
                <div class="form-group col-6">

                  <label class="bold">Company</label>
                  <input type="text" formControlName="company" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Company is required. Write N/A if you cannot enter a
                      company.
                    </div>
                  </div>
                </div>
                <div class="form-group col-6">

                  <label class="bold">First name</label>
                  <input type="text" formControlName="firstName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                  </div>
                </div>
                <div class="form-group col-6">
                  <label class="bold">Last name</label>
                  <input type="text" formControlName="lastName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                  </div>
                </div>


              </div>


              <div class="form-row">


                <div class="form-group col-6">

                  <label class="bold" for="city">City</label>
                  <input type="text" id="city" formControlName="city" class="form-control" />

                </div>
                <div class="form-group col-6">

                  <label class="bold"> </label>
                  <p><span class="bold">Hint:</span> if you let us know in which city you're
                    based we can inform you about upcoming
                    roadshows and events in your area. </p>
                </div>


              </div>




              <div class="form-row  col-12">

                <div class="form-group">

                  <input id="tcCheck" type="checkbox" class="customCheckbox" formControlName="datasecurity"
                    [ngClass]="{ 'is-invalid': submitted && f.datasecurity.errors }" />
                  <label class="ml-2" for="tcCheck"> <span> I accept the <a href="datenschutz">Terms and Conditions
                      </a>regarding
                      privacy and data
                      protection.</span></label>
                  <div *ngIf="submitted && f.datasecurity.errors" class="invalid-feedback">
                    <div>This field is required.</div>
                  </div>




                </div>

              </div>


              <div class="form-row my-3">
                <div class="form-group  col-12">
                  <button class="btn btn-std float-right" [disabled]="loadingRequest">
                    <span *ngIf="!loadingRequest && emailMode === 'subscription'">confirm subscription</span>
                    <span *ngIf="!loadingRequest && emailMode === 'companyintro'">Send request for company intro</span>
                    <span *ngIf="!loadingRequest && emailMode === 'requestcoverage'">Send request for coverage</span>
                    <span *ngIf="!loadingRequest && emailMode === 'subscribetoevent'">Subscribe to event</span>
                    <span *ngIf="loadingRequest">sending request...</span>
                  </button>

                </div>
              </div>








            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showResult" class="col-12 my-3">

      <div class="col-12 text-center">
        <p class=" uppercase bold">Success!</p>
      </div>
    </div>

  </div>
</div> -->

<!-- <div *ngIf="formMode === 'newsletter'"> -->

<div style="height: 80vh;">
  <div style="width:100%; height:100%; display:flex;" *ngIf="!unsafeURL">
    <div class="mx-auto my-auto text-center" style=" align-items:center;">
      <div class="spinner-border blueText" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <iframe *ngIf="unsafeURL" [src]=" unsafeURL |safe: 'resourceUrl'" frameborder="0"
    style="width:100%; height:100%;"></iframe>
</div>
<!-- </div> -->
