import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { leewayAPI_URL } from 'src/app/app-settings/api-urls';

@Component({
  selector: 'app-backupdownloader',
  templateUrl: './backupdownloader.component.html',
  styleUrls: ['./backupdownloader.component.scss']
})
export class BackupdownloaderComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }
  backupmode = "full"
  customerId = "5eed24ff4fe2bd337ccab5d3"
  apitoken = "cqt1bii3tq5fuzo0c5id"
  downloadLink = ""
  ngOnInit(): void {
    this.downloadLink = leewayAPI_URL+"tools/downloadbackup/" + this.customerId + "?apitoken=" + this.apitoken + "&backupmode=" + this.backupmode

  }


}
