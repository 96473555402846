import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { ActionButtonsComponent } from './action-buttons';
import { AutoSliderComponent } from './auto-slider/auto-slider.component';
import { ButtonComponent, LinkButtonComponent } from './buttons';
import {
  ChartComponent,
  ChartingComponent,
  FinancialsTableComponent,
  KeyfactsComponent,
  NewsfeedComponent,

  PredictionChannelComponent, QuickfactsComponent,


  TableNotabsComponent, TableTabsComponent
} from './contentParts';
import { DcfModelComponent } from './contentParts/dcf-model/dcf-model.component';
import { EsgLabelsComponent } from './contentParts/esg-labels/esg-labels.component';
import { EsgScoreComponent } from './contentParts/esg-score/esg-score.component';
import { FundamentalScoreComponent } from './contentParts/fundamental-score/fundamental-score.component';
import { ResponsiveVideoComponent } from './contentParts/responsive-video/responsive-video.component';
import { ScreenerComponent } from './contentParts/screener/screener.component';
import { TvTickerComponent } from './contentParts/tv-ticker/tv-ticker.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { BlockHandlingComponent } from './other/block-handling/block-handling.component';
import { PdfdownloadDirective } from './pdfdownload.directive';
import { ESGLabelRenamer } from './pipes/esgLabel.pipe';
import { HTMLSpecialCharPipe } from './pipes/htmlspecialchar.pipe';
import { RenamePageTypes } from './pipes/renamePagetypes.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { RssFeedComponent } from './rss-feed/rss-feed.component';




const sharedComponents = [
  KeyfactsComponent,
  QuickfactsComponent,
  FinancialsTableComponent,
  ChartComponent,
  ChartingComponent,
  DcfModelComponent,
  NewsfeedComponent,
  PredictionChannelComponent,
  LinkButtonComponent,
  ButtonComponent,
  FundamentalScoreComponent,
  EsgScoreComponent,
  TableTabsComponent,
  TableNotabsComponent,
  ScreenerComponent,
  ESGLabelRenamer,
  RenamePageTypes,
  EsgLabelsComponent,
  ResponsiveVideoComponent,
  TvTickerComponent,
  ActionButtonsComponent,
  SafePipe,
  BlockHandlingComponent,
  PdfdownloadDirective,
  LoadingIndicatorComponent,
  HTMLSpecialCharPipe,
  AutoSliderComponent,
  RssFeedComponent,
];

PlotlyModule.plotlyjs = PlotlyJS;
@NgModule({
  declarations: [...sharedComponents],
  imports: [
    PlotlyModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    RouterModule,
  ],
  exports: [...sharedComponents]
})
export class SharedModule { }
