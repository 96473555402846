import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from '../basic-pages';
import { AuthGuardService } from '../services';
import { CustomRouteReuseStategy } from '../utilities/customRouterReloadStrategy.util';
import { CorporateDashboardComponent } from './corporate-dashboard/corporate-dashboard.component';


/**
 * Dieser ganze Spaß den wir an die Routes angebaut haben wir hier automatisch übernommen.
 */
const routes: Routes = [
  { path: '', component: CorporateDashboardComponent, children:[{path:'dashboard', component:CorporateDashboardComponent}], canActivate: [AuthGuardService] },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CorporateDashboardRoutingModule { }
