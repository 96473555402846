<div class="container my-3 row">

  <div class="col-11">
    <h5>Table Templater</h5>
  </div>
  <div class="col-1">
    <h5 class="fingerFix" (click)="activeModal.dismiss('deny')"><strong>X</strong></h5>
  </div>
</div>

<div class="container table my-5" *ngIf="tabs">
  <div class="text-center col-12">
    <div class="row">
      <div class="col-6" *ngIf="updateMode===false">
        <input class="form-control" type="text" name="templateName" id="templateName" placeholder="Template name"
          [(ngModel)]="defaultTemplate.name" />
      </div>
      <div [ngClass]="{'col-12':updateMode!==false,'col-6':updateMode===false }">
        <input class="form-control" type="text" name="templateTitel" id="templateTitel" placeholder="Template title"
          [(ngModel)]="defaultTemplate.settings.title[defaultTemplate.template[0].settings.language]" />
      </div>
    </div>

    <div class="row">
      <hr>
    </div>

    <div class="row">
      <div class="col-4">
        <button class="btn blockButton  btn-outline-primary" id="addTab" (click)="addTab()"> Add tab
        </button>
      </div>
      <div class="col-4"></div>
      <div class="col-4">
        <div ngbDropdown class="d-inline-block">
          <button class="btn blockButton  btn-outline-primary" id="languageToggle" ngbDropdownToggle>
            Delete tab
          </button>
          <div ngbDropdownMenu aria-labelledby="languageToggle">
            <button *ngFor="let tab of defaultTemplate.template" class="btn blockButton  btn-inverted-gray my-1"
              ngbDropdownItem (click)="deleteTab(tab)">
              {{tab.name[tab.settings.language]}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <ngb-tabset [destroyOnHide]="false">
        <ngb-tab *ngFor="let tab of defaultTemplate.template; let tabNum = index">
          <ng-template ngbTabTitle>
            <input type="text" name="tabName" id="tabName" placeholder="Name der Registerkarte"
              [(ngModel)]="tab.name[tab.settings.language]" />
          </ng-template>
          <ng-template ngbTabContent>
            <div class="row my-2">
              <div class="col-12 my-2">Tab settings</div>
              <div class="col-2">
                <div ngbDropdown class="d-inline-block">
                  <button class="btn blockButton  btn-outline-primary" id="languageToggle" ngbDropdownToggle>
                    Language
                  </button>
                  <div ngbDropdownMenu aria-labelledby="languageToggle">
                    <button *ngFor="let lang of languages" class="btn blockButton  btn-inverted-gray my-1"
                      ngbDropdownItem (click)="setLanguage(lang, tabNum)">
                      {{lang}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <small># Years History</small>
              </div>
              <div class="col-1">
                <input class="form-control" type="text" name="numberPastYears" id="numberPastYears"
                  placeholder="Anzahl Jahre Vergangenheit" [(ngModel)]="tab.settings.numYearsBack" />
              </div>
              <div class="col-3">
                <small># Years Future</small>
              </div>
              <div class="col-1">
                <input class="form-control" type="text" name="numberFutureYears" id="numberFutureYears" placeholder="1"
                  [(ngModel)]="tab.settings.numYearsFront" />
              </div>
            </div>
            <div class="table-wrapper container my-3 customFont">
              <div class="row py-0 my-0">
                <div class="table-responsive mx-auto mt-2">
                  <div>
                    <table class="table table-sm table-hover bilanzkennzahlen">
                      <tbody>
                        <tr>
                          <th>
                            <div>
                              Leeway Key
                            </div>
                          </th>
                          <th>
                            <div>
                              Data type
                            </div>
                          </th>
                          <th>
                          </th>
                          <th>
                            <div>
                              Line format
                            </div>
                          </th>
                          <th>
                          </th>
                        </tr>
                        <tr *ngFor="let row of tab.data; let i = index">
                          <td>
                            <input *ngIf="row.type==='NODATA' || row.type==='DATES'" class="form-control" type="text"
                              name="key" id="key" placeholder="Key" [(ngModel)]="row.key" />
                            <input *ngIf="row.type!=='NODATA' && row.type!=='DATES'" id="typeahead-basic" type="text"
                              class="form-control" [(ngModel)]="row.key" [ngbTypeahead]="search" />
                          </td>
                          <td>
                            <div>
                              <small>{{row.type}}</small>
                            </div>
                          </td>
                          <td>
                            <div ngbDropdown class="d-inline-block">
                              <button class="btn  blockButton btn-outline-primary" id="languageToggle"
                                ngbDropdownToggle>
                                Set Datatype </button>
                              <div ngbDropdownMenu aria-labelledby="languageToggle">
                                <button *ngFor="let datatype of datatypes"
                                  class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
                                  (click)="setDatatype(datatype, tabNum, i)">
                                  {{datatype}}
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div *ngIf="row.highlight">
                              <small>Highlight</small>
                            </div>
                            <div *ngIf="row.highlight2">
                              <small>Highlight2</small>
                            </div>
                            <div *ngIf="row.headline">
                              <small>Headline</small>
                            </div>
                            <div *ngIf="!row.highlight&&!row.highlight2&&!row.headline">
                              <small>None</small>
                            </div>
                          </td>
                          <td>
                            <div ngbDropdown class="d-inline-block">
                              <button class="btn  blockButton btn-outline-primary" id="languageToggle"
                                ngbDropdownToggle>
                                Set Highlight </button>
                              <div ngbDropdownMenu aria-labelledby="languageToggle">
                                <button *ngFor="let highlightType of highlights"
                                  class="btn  blockButton btn-inverted-gray my-1" ngbDropdownItem
                                  (click)="setHighlight(highlightType, tabNum, i)">
                                  {{highlightType}}
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <small class="fingerFix" (click)="deleteRow(tabNum, i)">X</small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                            <small class="fingerFix" (click)="addRow(tabNum)">New line</small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>

<div class="container tableTemplate row" *ngIf="!tabs">
  <div class="row">
    <div class="col-6" *ngIf="updateMode===false">
      <!-- TODO wtf why doesnt it work with updateMode-->
      <input class="form-control" type="text" name="templateName" id="templateName" placeholder="Template name"
        [(ngModel)]="defaultTemplate.name" />
    </div>
    <div [ngClass]="{'col-12':updateMode!==false,'col-6':updateMode===false }">
      <input class="form-control" type="text" name="templateTitel" id="templateTitel" placeholder="Template title"
        [(ngModel)]="defaultTemplate.settings?.title[defaultTemplate.template[0]?.settings?.language]" />
    </div>
  </div>

  <div class="row">
    <hr>
  </div>

  <div class="row">
    <div class="col-3">
      <div ngbDropdown class="d-inline-block">
        <button class="btn blockButton  btn-outline-primary" id="languageToggle" ngbDropdownToggle> Sprache </button>
        <div ngbDropdownMenu aria-labelledby="languageToggle">
          <button *ngFor="let lang of languages" class="btn blockButton  btn-inverted-gray my-1" ngbDropdownItem
            (click)="setLanguage(lang, 0)">
            {{lang}}
          </button>
        </div>
      </div>
    </div>
    <div class="col-3 small">
      # Years History
    </div>
    <div class="col-1">
      <input class="form-control" type="text" name="numberPastYears" id="numberPastYears"
        placeholder="Anzahl Jahre Vergangenheit" [(ngModel)]="defaultTemplate.template[0].settings.numYearsBack" />
    </div>
    <div class="col-3 small">
      # Years Future
    </div>
    <div class="col-1">
      <input class="form-control" type="text" name="numberFutureYears" id="numberFutureYears" placeholder="1"
        [(ngModel)]="defaultTemplate.template[0].settings.numYearsFront" />
    </div>
  </div>

  <div class="table-wrapper container my-3 customFont">
    <div class="row py-0 my-0">
      <div class="table-responsive mx-auto mt-2">
        <div>
          <table class="table table-sm table-hover bilanzkennzahlen">
            <tbody>
              <tr>
                <th>
                  <div>
                    Leeway Key
                  </div>
                </th>
                <th>
                  <div>
                    Data type
                  </div>
                </th>
                <th>
                </th>
                <th>
                  <div>
                    Line format
                  </div>
                </th>
                <th>
                </th>
              </tr>
              <tr *ngFor="let row of defaultTemplate.template[0].data; let i = index">
                <td>
                  <input *ngIf="row.type==='NODATA' || row.type==='DATES'" class="form-control" type="text" name="key"
                    id="key" placeholder="Key" [(ngModel)]="row.key" />
                  <input *ngIf="row.type!=='NODATA' && row.type!=='DATES'" id="typeahead-basic" type="text"
                    class="form-control" [(ngModel)]="row.key" [ngbTypeahead]="search" />
                </td>
                <td>
                  <div>
                    {{row.type}}
                  </div>
                </td>
                <td>
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn blockButton  btn-outline-primary" id="languageToggle" ngbDropdownToggle>
                      Set Datatype </button>
                    <div ngbDropdownMenu aria-labelledby="languageToggle">
                      <button *ngFor="let datatype of datatypes" class="btn blockButton  btn-inverted-gray my-1"
                        ngbDropdownItem (click)="setDatatype(datatype, 0, i)">
                        {{datatype}}
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div *ngIf="row.highlight">
                    <small>Highlight</small>
                  </div>
                  <div *ngIf="row.highlight2">
                    <small>Highlight2</small>
                  </div>
                  <div *ngIf="row.headline">
                    <small>Headline</small>
                  </div>
                  <div *ngIf="!row.highlight&&!row.highlight2&&!row.headline">
                    <small>None</small>
                  </div>
                </td>
                <td>
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn blockButton  btn-outline-primary" id="languageToggle" ngbDropdownToggle>
                      Highlight </button>
                    <div ngbDropdownMenu aria-labelledby="languageToggle">
                      <button *ngFor="let highlightType of highlights" class="btn  blockButton btn-inverted-gray my-1"
                        ngbDropdownItem (click)="setHighlight(highlightType, 0, i)">
                        {{highlightType}}
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <small class="fingerFix" (click)="deleteRow(0, i)">X</small>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                  <small class="fingerFix" (click)="addRow(0)">New line</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container row">
  <div class="text-center col-2" *ngIf="defaultTemplate.name!==''">
    <button class="mx-auto   blockButton btn btn-std form-control" (click)="closeWindow()">Save</button>
  </div>
  <div class="text-center col-2" *ngIf="defaultTemplate.name===''">
    Please enter template name...
  </div>
</div>
