import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@tinymce/tinymce-angular';
import { EmbedVideo } from 'ngx-embed-video';
import { SharedModule } from '../sharedModule/shared.module';
import { DeleteWarningComponent } from './delete-warning/delete-warning.component';
import { EditorComponent } from './editor/editor/editor.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { BlockChartingComponent } from './layoutElements/blockComponents/block-charting/block-charting.component';
import { BlockEsgAllComponent } from './layoutElements/blockComponents/block-esg-all/block-esg-all.component';
import { BlockFinancialTableComponent } from './layoutElements/blockComponents/block-financial-table/block-financial-table.component';
import { BlockKeyfactsComponent } from './layoutElements/blockComponents/block-keyfacts/block-keyfacts.component';
import { BlockLargeImageComponent } from './layoutElements/blockComponents/block-large-image/block-large-image.component';
import { BlockNewsfeedComponent } from './layoutElements/blockComponents/block-newsfeed/block-newsfeed.component';
import { BlockPdfSimpleListResearchComponent } from './layoutElements/blockComponents/block-pdf-simple-list-research/block-pdf-simple-list-research.component';
import { BlockScreenerComponent } from './layoutElements/blockComponents/block-screener/block-screener.component';
import { BlockTableNotabsComponent } from './layoutElements/blockComponents/block-table-notabs/block-table-notabs.component';
import { BlockTableTabsComponent } from './layoutElements/blockComponents/block-table-tabs/block-table-tabs.component';
import { BlockTvChartComponent } from './layoutElements/blockComponents/block-tv-chart/block-tv-chart.component';
import { BlockVideoComponent } from './layoutElements/blockComponents/block-video/block-video.component';
import { EditorBlockComponent } from './layoutElements/blockComponents/editor-block/editor-block.component';
import { CustomLayoutComponent } from './layoutElements/custom-layout/custom-layout.component';
import { LayoutDirective } from './layoutElements/layout.directive';
import { ChartingTemplateCreationComponent } from './modals/charting-template-creation/charting-template-creation.component';
import { TableTemplateCreationComponent } from './modals/table-template-creation/table-template-creation.component';
import { PublishwarningComponent } from './publishwarning/publishwarning.component';
import { SessionwarningComponent } from './sessionwarning/sessionwarning.component';
import { BlockButtonComponent } from './layoutElements/blockComponents/block-button/block-button.component';
import { BlockDcfModelComponent } from './layoutElements/blockComponents/block-dcf-model/block-dcf-model.component';





@NgModule({
  declarations: [

    BlockLargeImageComponent,


    CustomLayoutComponent,
    LayoutDirective,
    EditorComponent,
    EditorBlockComponent,


    BlockKeyfactsComponent,

    BlockNewsfeedComponent,
    BlockFinancialTableComponent,

    BlockPdfSimpleListResearchComponent,

    PublishwarningComponent,
    SessionwarningComponent,
    FileUploaderComponent,
    DeleteWarningComponent,
    TableTemplateCreationComponent,
    BlockTableTabsComponent,
    BlockTableNotabsComponent,
    BlockChartingComponent,
    ChartingTemplateCreationComponent,
    BlockTvChartComponent,
    BlockScreenerComponent,
    BlockEsgAllComponent,
    BlockVideoComponent,
    BlockButtonComponent,
    BlockDcfModelComponent


  ],
  imports: [
    CommonModule,
    FormsModule,
    EditorModule,
    SharedModule,
    NgbModule,
    DragDropModule,
    EmbedVideo.forRoot()





  ],
  entryComponents: [

    BlockLargeImageComponent,
    EditorBlockComponent
  ],
  exports: [CustomLayoutComponent, LayoutDirective, EditorModule]
})
export class CmsModule { }
