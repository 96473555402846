import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { emptySingleEvent, EventPerson, SingleEvent } from 'src/app/models/event.model';
import { InvestorprofilemodalComponent } from '../../investorprofilemodal/investorprofilemodal.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { VideoModalComponent } from 'src/app/basic-pages/modals/video-modal/video-modal.component';
import { createEventRegistrationLink, timeZoneFormat } from 'src/app/events/utils/event.utils';
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  timeZoneFormat = timeZoneFormat

  @Input() listType = 1 // ["nextEvent", "pastEvents", "upcomingEvents"]
  @Input() event: SingleEvent = emptySingleEvent()
  @Input() currentUsersCompanyName = null
  @Input() index = null
  @Input() existingCompany = false
  expanded = [false, false]
   inviteLink =null
  constructor(
    private modalService: NgbModal,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {

    if(this.event && this.event.subscribers){
      this.event.subscribers.map((subscriber)=>{
        if(subscriber.investorProfile && subscriber.investorProfile.investorType){
          subscriber.investorProfile.investorType = String(Number(subscriber.investorProfile.investorType)-1)
        }
      })
    }
    if(this.event &&  this.event.participants){
      this.event.participants.map((subscriber)=>{
        if(subscriber.investorProfile && subscriber.investorProfile.investorType){
          subscriber.investorProfile.investorType = String(Number(subscriber.investorProfile.investorType)-1)
        }
      })
    }
    
    if(this.event){
      this.inviteLink = createEventRegistrationLink(this.event.bloombergTicker,this.event.date)
    }

      console.log(this.event)
  }
  toggleExpand(index) {
    this.expanded[index] = !this.expanded[index]
  }

  sortPeople(listName:string, target: string) {
    // firstName, lastName, company, investorProfile
    this.event[listName].sort((a, b) => {

      if (target === "investorProfile" && this.personHasProfile(a)) {
        return -1
      } else {
        if (a[target] === null || a[target] === undefined || (target === "investorProfile" && !this.personHasProfile(a))) {
          return 1
        }
        else {
          return a[target].localeCompare(b[target])
        }
      }
    })

  }
  personHasProfile(person: EventPerson) {
    if (!person.investorProfile) { return false }
    if (!this.hasEnoughValues(person.investorProfile,person)) { return false }
    return true
  }
  hasEnoughValues(obj,person) {
    const keys = Object.keys(obj);
    const nonEmptyKeys = keys.filter(key => obj[key] !== "");
    return nonEmptyKeys.length >= 2
  }

  openModal(person: EventPerson) {
    const modalRef = this.modalService.open(InvestorprofilemodalComponent, {
      centered: true, size: 'xl', windowClass: "investorProfile"
    });
    modalRef.componentInstance.person = person
  }
  copyTextToClipboard(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }

  openVideoModal(videoURL) {
    const modalRef = this.modalService.open(VideoModalComponent, { centered: true, size: 'lg', windowClass: 'biggerModal' });
    modalRef.componentInstance.videoURL = videoURL
  }

  
}
