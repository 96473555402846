import { HttpUrlEncodingCodec } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CompanyInfoService } from 'src/app/services';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SEOService } from 'src/app/services/seo.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DataServiceService } from '../../services/data-service.service';
import { niceDate, parseParams } from '../../utilities';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  buildingBlocks: Observable<any>;
  buildingBlocksSmallList: Observable<any>;
  companyName = 'Blog';
  pageType = 'weeklyScreener';
  companyId = "weekly:screener";
  maxPreview = 5;
  adminMode = false;
  newScreenerMode = false;
  archiveTeaser = ""

  showTitleInput: boolean = false;

  teaserText;
  archiveTitle = "";
  archiveDate = "";
  screenerNumber;

  symbolExchange
  title


  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private companyInfoService: CompanyInfoService,
    private dataService: DataServiceService,
    private seoService: SEOService,
    private authService: AuthService,
    public utilitiesService: UtilitiesService,
    private cdr: ChangeDetectorRef,
  ) {


    this.adminMode = this.authService.isAdmin();
    // const urlParameters = combineLatest(
    //   this.activatedRoute.params,
    //   this.activatedRoute.queryParams,
    //   this.router.events,
    //    (params, queryParams) => ({ ...params, ...queryParams }));

    this.router.events.subscribe(event => {
      let e = event as any;
      if (e instanceof NavigationEnd) {

        let splitURL = e.url.split('/');
        if (splitURL[1] === 'blog') {
          let newParams = parseParams(splitURL[2].split(/#|\?/)[1])

          if ((newParams.date === null || newParams.date === undefined || newParams.title === null || newParams.title === undefined) && this.authService.isAdmin()) {
            this.newScreenerMode = true;
          } else {
            this.newScreenerMode = false;
          }

          this.archiveDate = newParams.date;
          this.archiveTitle = newParams.title;
          this.buildingBlocks = this.companyInfoService.getLayoutForCompany(this.companyId, this.pageType, newParams.date, newParams.title, "block subscribe 1");
          this.buildingBlocksSmallList = this.companyInfoService.getLayoutForCompany(this.companyId, this.pageType, '', '', 'block subscribe 2');

          this.buildingBlocks.subscribe(cmsPage => {
            this.title = (cmsPage as any).archiveTitle;
            this.archiveTeaser = (cmsPage as any).archiveTeaser;
          })
        }
      }
    });
  }

  formatURLParam(param) {
    if (param.includes("?")) {
      if (param.includes(":")) {
        let tmp = param = this.encodeForURL(param.split(":")[0] + ":") + param.split(":")[1]
        param = tmp;
      } else {
        param = encodeURIComponent(param)
      }
      return param
    } else {
      return this.encodeForURL(param)
    }

  }

  public ngOnInit(): void {
    //enter teaser text and headline or something
    this.seoService.setMetaTags(this.archiveTitle, null, null, null);
  }

  public onEditModeChange() {
    this.buildingBlocks = this.companyInfoService.getLayoutForCompany(this.companyId, this.pageType, this.archiveDate, this.archiveTitle, "block changemode 1");
  }

  public niceDate(date) {
    return niceDate(date);
  }

  publishChangesForPage() {
    return new Promise((resolve, reject) => {
      this.companyInfoService.publishChangesForPage(this.companyId, this.pageType, this.archiveDate, this.archiveTitle).then(res => {
        setTimeout(() => {
          resolve(true)
        }, 200);
      },
        (err) => { reject(false) });
    })
  }


  archivePublishedPage() {
    this.showTitleInput = false;
    this.publishChangesForPage().then(
      el => {
        return new Promise((resolve, reject) => {
          this.companyInfoService.archivePublishedPage(this.companyId, this.pageType, this.archiveTitle, this.archiveTeaser).then(res => {
            setTimeout(() => {
              resolve(true)
            }, 200);
          },
            (err) => { reject(false) });
        })
      }
    )
  }

  encodeForURL(input) {
    let encoder = new HttpUrlEncodingCodec();
    return encoder.encodeKey(input);
  }



  public loadAnotherScreener(archiveDate, archiveTitle, index) {
    if (index > 0) {
      const queryParams = { 'date': archiveDate, 'title': this.formatURLParam(archiveTitle) };
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    } else {
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: {}
        });
    }
  }


  showMore(more) {
    if (more) { this.maxPreview = this.maxPreview + 5 }
    if (!more) { this.maxPreview = this.maxPreview - 5 }
  }

}
