import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyInfoService } from 'src/app/services';
import { BrowserService } from 'src/app/services/browser.service';
import { Tables } from '../../../../../utilities';

@Component({
  selector: 'app-table-slider',
  templateUrl: './table-slider.component.html',
  styleUrls: ['./table-slider.component.scss']
})
/**
 * This does not work as a component in the tile-wrapper-grid
 * because it needs to be able to change the content of the tile
 * based on the slider index. This is not possible with the
 * current implementation of the tile-wrapper-grid.
 * 
 */

export class TableSliderComponent implements OnInit {

  @Input() mapping: any;
  @Input() companyData: any;
  @Input() baseyear: number;
  @Input() config: {headline: string, type: string, content: string[]|any[], height: number, width: number, mobile: boolean, decimals: number};
  @Input() lang = 'eng';
  @Input() valueTable = true; // all other components that use valueTable are false

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  data: [{title: string, value: string[]}];
  dates: string[];
  index: number = 0;

  constructor(
    private tableUtils: Tables,
    private companyInfoSerice: CompanyInfoService,
    private cdr: ChangeDetectorRef,
    public browserService: BrowserService

    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    this.prepareData();
  }

  prepareData() {
    this.data = null;
    let numFigures = 3;
    if(this.mapping && this.companyData  && this.companyData.base){
      for(let key of this.config.content[this.index]){
        // add functionality to handle keys that are not mapping-keys but custom stuff
        if(typeof key === 'string'){
          if(this.mapping[key]){
          let mappingEntry = this.mapping[key];
          if(mappingEntry.type === 'VALUE'){
            let entry = {title: mappingEntry[this.lang], value: this.tableUtils.getValue(this.companyData, mappingEntry, this.lang, this.config.decimals)};

            if ((key === 'mc'||key==='con_pt') && this.valueTable) {// hack for market cap
              entry['valueModifier'] = (this.companyData?.base?.quote_curr || this.companyData?.base?.curr);
            }
            if ((key === 'mc') && this.valueTable) {// hack for showing millions of shares
              entry['unit'] = "m";
            }

            if(this.data){
              this.data.push(entry);
            }else{
              this.data = [entry];
            }
          } else if(mappingEntry.type === 'TIMESERIES'){
            
            let quarterlyPattern = /_q$/;
            //crap hack
            //uses halfyearly data if quarterly isnt there
            if(quarterlyPattern.test(key)
              && (this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0] === 'na' || this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0].includes('WERT'))
              && (this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[1] === 'na' || this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'value', this.lang, this.config.decimals)[0].includes('WERT'))
              ) {
              if(this.tableUtils.getTimeseries(key.replace('_q','_h'), this.companyData, this.mapping[key.replace('_q','_h')], -1, numFigures, 'value', this.lang, this.config.decimals)[0] !== 'na'){
                key = key.replace('_q','_h');
                mappingEntry = this.mapping[key.replace('_q','_h')];
                this.tileEvent.emit({ type: 'half-year'})
              }
            }

            if(!this.dates){
              this.dates = this.tableUtils.getTimeseries(key, this.companyData, mappingEntry, -1, numFigures, 'date', this.lang, this.config.decimals);
            }

            let entry = {title: mappingEntry[this.lang], value: this.tableUtils.getOneFromTimeseries(this.companyData, mappingEntry, 1, 'value', this.lang, this.config.decimals)};
            if ((key === 'ev' || key === 'volume') && this.valueTable) {// hack for enterprive value or trading volume
              entry['valueModifier'] = (this.companyData?.base?.quote_curr || this.companyData?.base?.curr);
            }
            if ((key === 'ev' || key === 'volume'||key === 'num_shares') && this.valueTable) {// hack for showing millions of shares
              entry['unit'] = "m";
            }
            if(this.data){
              this.data.push(entry);
            }else{
              this.data = [entry];
            }
          }
          }
        }
        else if(typeof key === 'object' && key.type === 'custom'){
          this.companyInfoSerice.getESGScore(key.input).subscribe((data: any) => {
            this.parsecustomDataIntoTable(data, key.content)
          })
        }
      }

    }
  }
  navigate(step: number) {
    if (this.index + step > this.config.content.length - 1) {
      this.index = 0;
    } else if (this.index + step < 0) {
      this.index = this.config.content.length - 1;
    } else {
      this.index = this.index + step;
    }

    this.prepareData();
  }

parsecustomDataIntoTable(data: any, contentType:string){
  this.data = null;
  if(contentType === 'esgScores'){
    let titles ={
      "total":"ESG Score", 
      "Social":"Social Score",
      "Environment":"Environment Score",
      "Governance":"Governance Score"
    }
    for(let key of Object.keys(data.rating)){
      if(
        key === 'total' ||
         key === 'Social' ||
         key === 'Environment' ||
         key === 'Governance' 
      ){
        if(data['rating'][key]){
        let entry = {title: titles[key], value: [data['rating'][key].toFixed(0)]};
        if(this.data){
          this.data.push(entry);
        }else{
          this.data = [entry];
        }}
      }

   }
  }
  this.cdr.detectChanges();
}
}
