import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';




declare const TradingView: any;

@Component({
  selector: 'app-tv-ticker',
  templateUrl: './tv-ticker.component.html',
  styleUrls: ['./tv-ticker.component.scss']
})
export class TvTickerComponent implements OnInit, AfterViewInit {


  @ViewChild('chartWidthMeasureElement') divToMeasureElement: ElementRef;
  chartSymbol = ""
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //  this.createChart()
  }
  createChart() {
    let divToMeasureWidth = this.divToMeasureElement.nativeElement.offsetWidth;
    new TradingView.widget(
      {
        "symbols": [
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500"
          },
          {
            "proName": "FOREXCOM:NSXUSD",
            "title": "Nasdaq 100"
          },
          {
            "proName": "FX_IDC:EURUSD",
            "title": "EUR/USD"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "BTC/USD"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "ETH/USD"
          }
        ],
        "showSymbolLogo": true,
        "colorTheme": "light",
        "isTransparent": false,
        "displayMode": "adaptive",
        "locale": "de_DE"
      }
    );
  }

}
