<div class="create-event-wrapper container-fluid">
  <div class="row py-5">
    <div class="col-11">
      <h3 *ngIf="formMode === 'newsletter'">Publish newsletter form</h3>
      <h3 *ngIf="formMode === 'events'">Publish event form</h3>
    </div>
    <div class="col-1">
      <h5 class="fingerFix" (click)="activeModal.dismiss('deny')"><strong>X</strong></h5>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      URL
    </div>
    <div class="col-8">
      <input class="form-control" placeholder="insert 'Quick Share Link' here " [(ngModel)]="newURL">
    </div>
  </div>
  <hr>




  <div class="row py-5">
    <app-button (click)="sendURL()" color="primary">Save new URL</app-button>
  </div>
</div>
