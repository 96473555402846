<table class="bilanzkennzahlen table  table-hover borderless mt-2">
  <tbody>
    <tr *ngFor="let row of tableLayout[0].data">
      <th [attr.colspan]="row.colspan" [ngClass]="{
              nowrap: true,
              rowHighlight: row.highlight,
              rowHighlight2: row.highlight2,
              tabHeader: row.headline
            }">
        {{ row.key }}
      </th>
      <td *ngFor="let datapoint of row.data" [ngClass]="{
              rowHighlight: row.highlight,
              rowHighlight2: row.highlight2,
              tabHeader: row.headline
            }">
        {{ datapoint }}
      </td>
    </tr>
  </tbody>
</table>