import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DataServiceService } from '../../../services/data-service.service';
import { Tables } from '../../../utilities';



@Component({
  selector: 'app-keyfacts',
  templateUrl: './keyfacts.component.html',
  styleUrls: ['./keyfacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyfactsComponent implements OnInit, OnChanges {

  @Input() companyId: string;
  @Input() companyData: any = {};
  @Input() decimals = 0;

  //yea this is crap. better to build a "no tab table" component and insert it 3 times here
  // but this will do for now
  //for now also no point to input this
  @Input() templateNamePnL = {'ger':'Keyfacts PnL','au': 'Keyfacts PnL AU'};
  @Input() templateNameBS = {'ger':'Keyfacts B/S', 'au': 'Keyfacts B/S AU'};
  @Input() templateNameMult = {'ger':'Keyfacts Multiples', 'au': 'Keyfacts Multiples'};

  @Input() lang = 'ger';


  //put this as tableTemplate in DB. Allow template selection in table creation
  //write UI to create templates
  // -> select  and set commonSize true/false
  // -> select keys
  // -> mark highlight and (NO)DATA/DAtes
  // -> validate everything marked DATA (default) is contained in mapping

  tableLayoutPnL = this.tableUtil.defaultLayout;
  tableLayoutBS = this.tableUtil.defaultLayout;
  tableLayoutMult = this.tableUtil.defaultLayout;

  //could also be uploaded and integrated but for now..
  dataSource = {
    eng: 'Company data; AlsterResearch',
    ger: 'Firmenangaben; AlsterResearch'
  }

  constructor(private tableUtil: Tables, private dataService: DataServiceService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.dataService.getTableTemplate(this.templateNamePnL[this.lang]).subscribe((res: any) => {
      let template = res.template;
      this.tableUtil.createTableData(this.companyData, template, this.decimals, this.companyId).then(table => {
        this.tableLayoutPnL = table;
        this.cdr.detectChanges();
      });
    });

    this.dataService.getTableTemplate(this.templateNameBS[this.lang]).subscribe((res: any) => {
      let template = res.template;
      this.tableUtil.createTableData(this.companyData, template, this.decimals, this.companyId).then(table => {
        this.tableLayoutBS = table;
        this.cdr.detectChanges();
      })
    });

    this.dataService.getTableTemplate(this.templateNameMult[this.lang]).subscribe((res: any) => {
      let template = res.template;
      this.tableUtil.createTableData(this.companyData, template, this.decimals, this.companyId).then(table => {
        this.tableLayoutMult = table;
        this.cdr.detectChanges();
      })
    });

  }

  ngOnChanges(): void {
    this.dataService.getTableTemplate(this.templateNamePnL[this.lang]).subscribe((res: any) => {
      let template = res.template;
      this.tableUtil.createTableData(this.companyData, template, this.decimals, this.companyId).then(table => {
        this.tableLayoutPnL = table;
        this.cdr.detectChanges();
      });
    });

    this.dataService.getTableTemplate(this.templateNameBS[this.lang]).subscribe((res: any) => {
      let template = res.template;
      this.tableUtil.createTableData(this.companyData, template, this.decimals, this.companyId).then(table => {
        this.tableLayoutBS = table;
        this.cdr.detectChanges();
      })
    });

    this.dataService.getTableTemplate(this.templateNameMult[this.lang]).subscribe((res: any) => {
      let template = res.template;
      this.tableUtil.createTableData(this.companyData, template, this.decimals, this.companyId).then(table => {
        this.tableLayoutMult = table;
        this.cdr.detectChanges();
      })
    });
  }

}
