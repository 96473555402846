<div class="row py-3 mb-5 bg-white">
  <div class="col-12">
    <h4>News Feed</h4>
  </div>
  <div class="col-12 scrollbox">
    <div *ngFor="let article of news" class="my-3">
      <small class="text-muted">{{formatNewsDate(article.date)}}</small><br>
      <a href={{article.url}}><b class="">{{article.title}}</b></a><br>
      <p>{{article.summary}} <a href={{article.url}}><span class="bold"> mehr</span></a></p>
      <hr>
    </div>
  </div>
</div>
