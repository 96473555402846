<div [ngClass]="{ 'border border-dark rounded editMode': adminMode }" id="{{data.blockTag}}">
  <h3>{{data.blockHeadline || "Chart"}}</h3>
  <div class="my-3">
    <!-- TradingView Widget BEGIN -->
    <div class="containerWrapper" #chartWidthMeasureElement>
      <div class="tradingview-widget-container">
        <div id="tradingview_bac65"></div>
        <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/{{chartSymbol}}"
            rel="noopener" target="_blank"><span class="blue-text">{{chartSymbol}} Chart</span></a> by TradingView</div>
        <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
        <script type="text/javascript"></script>
      </div>

    </div>
    <!-- TradingView Widget END -->
  </div>
  <div class="row" *ngIf="adminMode">
    <div class="col-12">
      <app-block-handling [data]="data" [block_id]="block_id" [adminMode]="adminMode" [blockType]="blockType"
        [deleteEmitter]="deleteEmitter" [metaCompanyName]="metaCompanyName" [metaBloombergTicker]="metaBloombergTicker"
        [metaPageType]="metaPageType" [metaCompanyData]="metaCompanyData"></app-block-handling>
    </div>
  </div>
</div>
