import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataServiceService } from '../../../services/data-service.service';
import { Charting } from '../../../utilities';


@Component({
  selector: 'app-charting-template-creation',
  templateUrl: './charting-template-creation.component.html',
  styleUrls: ['./charting-template-creation.component.scss']
})
export class ChartingTemplateCreationComponent implements OnInit {

  @Input() templateName = '';
  @Input() updateMode = false;

  type;
  defaultTemplate = this.chartingUtil.defaultTemplate['VALUE'];
  languages = this.chartingUtil.languages;
  chartTypes = this.chartingUtil.chartTypes;
  visualizationTypes = this.chartingUtil.visualizationTypes;
  axisNumbers = this.chartingUtil.axisNumbers;
  mappingKeys: Array<string> = [];
  mapping: any;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.mappingKeys
          .filter(key => this.mapping[key].type === this.type || this.mapping[key].type === 'TIMESERIES')
          .filter(key => key.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  constructor(public activeModal: NgbActiveModal, public dataService: DataServiceService, public chartingUtil: Charting) {
  }

  ngOnInit(): void {
    this.dataService.getMapping().then((mapping) => {
      this.mapping = mapping;
      this.mappingKeys = Object.keys(mapping);
    });

    if (this.templateName !== '') {
      this.dataService.getChartingTemplate(this.templateName).subscribe((res: any) => {
        this.defaultTemplate = res;
        this.type = { key: res.settings.type };
      })
    } else {
      this.defaultTemplate.settings.tabs = this.type;
    }
  }

  closeWindow() {
    this.activeModal.close(this.dataService.putChartingTemplate(this.updateMode, this.defaultTemplate).subscribe(res => {
    }))
  }


  setLanguage(lang: string, tabNum: number) {
    this.defaultTemplate.template.settings.language = lang;
  }

  setCharttype(type: any) {
    this.type = type;
    this.defaultTemplate = this.chartingUtil.defaultTemplate[type.key];
  }

  setVisualizationType(visType: string, index: number) {
    this.defaultTemplate.template.data[index].type = visType;
  }

  setYAxis(axis: string, index: number) {
    this.defaultTemplate.template.data[index]['yaxis'] = axis;
  }

  addRow() {
    this.defaultTemplate.template.data.push(JSON.parse(JSON.stringify(this.defaultTemplate.template.data[0])));
  }

  deleteRow(index: number) {
    this.defaultTemplate.template.data.splice(index, 1);
  }
}
